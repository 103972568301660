import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import API from '../../Services/BaseService';
import Header from "../Header/Header";
import SideBar from "../SideBar/SideBar";
import Pagination from 'reactjs-hooks-pagination';
import Loading from "../../Component/Loading/Loading";
import { Modal } from 'react-bootstrap';
import ls from 'local-storage';
import Countdown, { zeroPad } from "react-countdown";

const pageLimit = 10;
const Folkususers = () => {
  const userData = useSelector(state => state.LoginReducer.payload);
  const [flokusUserInfo, setFlokusUserInfo] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [loadingOne, setLoadingOne] = useState(true);
  const [name, setName] = useState("");
  const [folkusUserViewData, setFlokusUserViewData] = useState("");
  const [flokusUser, setFlokusUser] = useState(false);
  const [disabled, SetDisabled] = useState(false);
  const [flokusUserView, setFlokusUserView] = useState(false);
  const [createdDate, setCreatedDate] = useState("");
  const [userName, setUserName] = useState("");
  const [userNameError, setUserNameError] = useState("");
  const [editSuccFlg, setEditSuccFlg] = useState(true);
  const [editSuccMsg, setEditSuccMsg] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const recallfunction = (data) => {
    setIsOpen(data);
  }
  useEffect(() => {
    getFlokusUserDetails();
  }, []);
  const getFlokusUserDetails = () => {
    let request = {
      admin_id: userData.admin_id,
      token: userData.token,
      language_id: ls.get("languageId") == "all" || ls.get("languageId") == "" ? "all" : ls.get("languageId").language_id

    }
    API.post("userList/condition", request).then(response => {
      // console.log("FlokusUser res", response);
      // setCurrentPage(1)
      setFlokusUserInfo(response.data.data);
      setTotalRecords(response.data.data.length);
      setLoading(false);
    });
  }

  const onSearch = (e) => {
    setName(e.target.value);
    setLoading(true);
  }
  const flokusUserInfoSearch = () => {
    let request = {
      admin_id: userData.admin_id,
      token: userData.token,
      language_id: ls.get("languageId") == "all" || ls.get("languageId") == "" ? "all" : ls.get("languageId").language_id,
      data: name
    }
    API.post("userSearch/condition", request).then(response => {
      // console.log("flokusUser Details response", response.data.data)
      setCurrentPage(1)
      setFlokusUserInfo(response.data.data);
      setTotalRecords(response.data.data.length);
      setLoading(false);
    });
  }
  useEffect(() => {
    if (name != "") {
      const timer = setTimeout(() => {
        flokusUserInfoSearch();
      }, 1000);
      return () => {
        clearTimeout(timer);
      };
    } else {
      getFlokusUserDetails();
    }
  }, [name]);
  const onKeydownAdminSearch = (event) => {
    if (event.key === "Enter") {
      // console.log("Enter is pressed call search function");
      flokusUserInfoSearch();
    }
  };

  const Completionist = () => <span>{""}</span>;
  const renderer = ({ days, completed }) => {
    if (completed) {
      return <Completionist />;
    } else {
      return (
        <div >
          <div className="dbox">
            <span>{zeroPad(days)}</span> days ago
          </div>
        </div>
      );
    }
  };

  // -------------------------Edit popup---------------------------------
  const handleFlokusUserEditShow = (data) => {
    getFlokusUserEditDetails(data);
    setFlokusUser(true);
    setUserName(data.user_name);
    setCreatedDate(data.createdAt.substring(0, 10));
    setLoadingOne(true);
  }
  const handleFlokusUserEditClose = () => {
    setFlokusUser(false);
    setUserNameError("");
    setEditSuccFlg(true);
  }

  const getFlokusUserEditDetails = (data) => {
    let request = {
      admin_id: userData.admin_id,
      token: userData.token,
      user_id: data.user_id
    };
    API.post("userbyId/condition", request).then(response => {
      setFlokusUserViewData(response.data.data[0]);
      setLoadingOne(false);
    });
  }
  const handleFolkusUserEditSubmit = () => {
    setUserNameError("");
    if (!userName) {
      setUserNameError("userName is required")
      return;
    }


    let request = {
      admin_id: userData.admin_id,
      token: userData.token,
      user_id: folkusUserViewData.user_id,
      user_name: userName.replace(/^\s+|\s+$/gm, ''),
      active: 1
    };

    API.post("userEdit/update", request).then((response) => {
      if (response.data.success) {
        getFlokusUserDetails();
        setEditSuccFlg(false);
        setEditSuccMsg(response.data.data.message);
        // console.log("username response=>", response)
      }
    });
  }
  // -------------------------View popup---------------------------------
  const handleFlokusUserViewShow = (data) => {
    getFlokusUserEditDetails(data);
    setFlokusUserView(true);
    setCreatedDate(data.createdAt.substring(0, 10));
    setLoadingOne(true);
  }
  const handleFlokusUserViewClose = () => {
    setFlokusUserView(false);
  }

  {/* ---------------------Membership History Popup--------------------------- */ }
  const [historyShowUser, setHistoryShowUser] = useState(false);
  const [hisInfo, setHisInfo] = useState("");

  const handleHistoryShow = (data) => {
    setHistoryShowUser(true);
    handleHistory(data.subscription_id);
    setLoadingOne(true);
    // console.log("data",data);
  }
  const handleHistoryClose = () => {
    setHistoryShowUser(false);
  }

  const handleHistory = (data) => {
    let request = {
      admin_id: userData.admin_id,
      token: userData.token,
      usersubscription_id: data
    }
    API.post("usersubhistory/condition", request).then(response => {
      setHisInfo(response.data.data);
      setLoadingOne(false);

    });
  }

  {/* ---------------------Edit Disabled Popup--------------------------- */ }

  const disabledEditShow = () => {
    SetDisabled(true);
    // setLoading(true);
    getFlokusUserDetails();
  }
  const disabledEditClose = () => {
    SetDisabled(false);
    // setLoading(false);
  }

  return (
    <div className="wrapper">
      <SideBar sideBarOpen={isOpen} />
      <div id="content" className={!isOpen ? "" : 'active'}>
        <Header recallfunction={recallfunction} />
        <div className="main-content">
          <div className="row">
            <div className="common-heading-sec col-lg-12">
              <h5 className="me-auto">Folkus Users</h5>
              <div className="search-bar">
                <input type="search" className="form-control" placeholder="Search by Name or Id" onKeyDown={onKeydownAdminSearch}
                  onChange={onSearch} />
              </div>
            </div>
            {loading ? <Loading /> :
              <>
                <div className="rounded table-responsive ">
                  <table className="table bg-white pointer">
                    <thead>
                      <tr>
                        <th>#Id</th>
                        <th>Created Date</th>
                        <th>UserName</th>
                        <th>Followers</th>
                        <th>Phone No</th>
                        <th>Email Id</th>
                        <th>Last Seen</th>
                        <th>Portal</th>
                        <th>Membership</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {flokusUserInfo.length > 0 ? flokusUserInfo.sort((a, b) => b.user_id - a.user_id).slice(currentPage == 1 ? 0 : (currentPage - 1) * pageLimit, (currentPage == 1 ? currentPage * pageLimit : currentPage * pageLimit))
                        .map((flokusUserInfo, index) =>
                          <tr key={index}>
                            <td>{flokusUserInfo.user_id}</td>
                            <td>{flokusUserInfo.createdAt.substring(0, 10)}</td>
                            <td onClick={() => handleFlokusUserViewShow(flokusUserInfo)}><a>{(flokusUserInfo.user_name_extn=="1")?flokusUserInfo.user_name.replace(".folkus","")+".folkus":flokusUserInfo.user_name}</a></td>
                            <td>{flokusUserInfo.followers}</td>
                            <td>{flokusUserInfo.phone_no}</td>
                            <td>{flokusUserInfo.email}</td>
                            <td><Countdown date={Date.now() + (flokusUserInfo.last_seen * 60 * 1000)} renderer={renderer} /></td>
                            <td>{flokusUserInfo.language}</td>
                            {flokusUserInfo.subs_name === "premium" || flokusUserInfo.subs_name === "Premium" ?
                              <td onClick={() => handleHistoryShow(flokusUserInfo)}><span className="premium-color">Premium</span></td> :
                              flokusUserInfo.subs_name === "supreme" || flokusUserInfo.subs_name === "Supreme" ?
                                <td onClick={() => handleHistoryShow(flokusUserInfo)}><span className="supreme-color">Supreme</span></td> :
                                <td onClick={() => handleHistoryShow(flokusUserInfo)}><span className="none-color">None</span></td>
                            }
                            <td>
                              {flokusUserInfo.active == 1 ?
                                <span className="active-color">Active</span> :
                                <span className="inactive-color">Inactive</span>
                              }
                            </td>
                            <td>
                              {flokusUserInfo.active == 1 ?
                                <a disabled onClick={() => handleFlokusUserEditShow(flokusUserInfo)} className="edit">Edit</a> :
                                <a disabled onClick={() => disabledEditShow(flokusUserInfo)} className="edit">Edit</a>
                              }
                            </td>

                          </tr>
                        ) : <tr><td colSpan="20" className='text-center'><p className="nodata-found">No Data Found</p> </td></tr>}
                    </tbody>
                  </table>
                </div>
                <ul className="pagination">
                  <Pagination className=""
                    totalRecords={totalRecords}
                    pageLimit={pageLimit}
                    pageRangeDisplayed={1}
                    onChangePage={setCurrentPage}
                  />
                </ul>
              </>
            }


          </div>
        </div>
      </div>
      {/* ---------------------Edit Popup--------------------------- */}
      <Modal size={editSuccFlg ? "wrapper modal-dialog-centered modal-lg" : "wrapper modal-dialog-centered modal-md"} show={flokusUser} onHide={handleFlokusUserEditClose}>
        {editSuccFlg ? <Modal.Header>
          <Modal.Title>
            <h5 className="modal-title" id="exampleModalLabel">Edit Profile</h5>
          </Modal.Title>
        </Modal.Header> : ""}
        <Modal.Body>
          {editSuccFlg ?
            <>
              {loadingOne ? <Loading /> :
                <form>
                  <div className="row">
                    <div className="col-lg-4 mb-3">
                      <label htmlFor="id" className="col-form-label">ID</label>
                      <input type="text" className="form-control" id="date" value={folkusUserViewData.user_id} disabled />
                    </div>
                    <div className="col-lg-4 mb-3">
                      <label htmlFor="name" className="col-form-label">Name</label>
                      <input type="text" className="form-control" id="name" value={folkusUserViewData.name} disabled />
                    </div>
                    <div className="col-lg-4 mb-3 add-language">
                      <label htmlFor="user-name" className="col-form-label">User Name <span>*</span></label>
                      <input type="text" className="form-control" id="user-name" defaultValue={folkusUserViewData.user_name} onChange={(e) => setUserName(e.target.value)} />
                      <p className="form-input-error">{userNameError}</p>
                    </div>
                    <div className="col-lg-4 mb-3">
                      <label htmlFor="email" className="col-form-label">Email</label>
                      <input type="text" className="form-control" id="email" value={folkusUserViewData.email} disabled />
                    </div>
                    <div className="col-lg-4 mb-3">
                      <label htmlFor="phone-number" className="col-form-label">Phone Number</label>
                      <input type="text" className="form-control" id="phone-number" value={folkusUserViewData.phone_no} disabled />
                    </div>
                    <div className="col-lg-4 mb-3">
                      <label htmlFor="dob" className="col-form-label">Date of Birth</label>
                      <input type="date" className="form-control" id="dob" value={folkusUserViewData.dob} disabled />
                    </div>
                    <div className="col-lg-4 mb-3">
                      <label htmlFor="gender" className="col-form-label">Gender</label>
                      <input type="text" className="form-control" id="gender" value={folkusUserViewData.gender} disabled />
                    </div>
                    <div className="col-lg-4 mb-3">
                      <label htmlFor="created-date" className="col-form-label">Created Date</label>
                      <input type="date" className="form-control" id="date" value={createdDate} disabled />
                    </div>
                    <div className="col-lg-4 mb-3">
                      <label htmlFor="portal" className="col-form-label">Portal</label>
                      <input type="text" className="form-control" id="portal" value={folkusUserViewData.language} disabled />
                    </div>
                  </div>
                </form>
              }
            </>
            :
            <div className="alert_box">
              <div className="icon">
                <i className="fa-solid fa-check"></i>
              </div>
              <header>{editSuccMsg}</header>
            </div>
          }
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn form-submit-secbtn" data-bs-dismiss="modal" onClick={handleFlokusUserEditClose}>Close</button>
          {editSuccFlg ? <button type="button" className="btn form-submit-btn" onClick={handleFolkusUserEditSubmit}>Update</button> : ""}
        </Modal.Footer>
      </Modal>

      {/* ---------------------View Popup--------------------------- */}
      <Modal size="wrapper modal-dialog-centered modal-lg" show={flokusUserView} onHide={handleFlokusUserViewClose}>
        <Modal.Header>
          <Modal.Title>
            <h5 className="modal-title" id="exampleModalLabel">View Profile</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loadingOne ? <Loading /> :
            <form>
              <div className="row">
                <div className="col-lg-4 mb-3">
                  <label htmlFor="id" className="col-form-label">ID</label>
                  <input type="text" className="form-control" id="date" value={folkusUserViewData.user_id} disabled />
                </div>
                <div className="col-lg-4 mb-3">
                  <label htmlFor="name" className="col-form-label">Name</label>
                  <input type="text" className="form-control" id="name" value={folkusUserViewData.name} disabled />
                </div>
                <div className="col-lg-4 mb-3">
                  <label htmlFor="user-name" className="col-form-label">User Name</label>
                  <input type="text" className="form-control" id="user-name" value={folkusUserViewData.user_name} disabled />
                </div>
                <div className="col-lg-4 mb-3">
                  <label htmlFor="email" className="col-form-label">Email</label>
                  <input type="text" className="form-control" id="email" value={folkusUserViewData.email} disabled />
                </div>
                <div className="col-lg-4 mb-3">
                  <label htmlFor="phone-number" className="col-form-label">Phone Number</label>
                  <input type="text" className="form-control" id="phone-number" value={folkusUserViewData.phone_no} disabled />
                </div>
                <div className="col-lg-4 mb-3">
                  <label htmlFor="dob" className="col-form-label">Date of Birth</label>
                  <input type="date" className="form-control" id="dob" value={folkusUserViewData.dob} disabled />
                </div>
                <div className="col-lg-4 mb-3">
                  <label htmlFor="gender" className="col-form-label">Gender</label>
                  <input type="text" className="form-control" id="gender" value={folkusUserViewData.gender} disabled />
                </div>
                <div className="col-lg-4 mb-3">
                  <label htmlFor="created-date" className="col-form-label">Created Date</label>
                  <input type="date" className="form-control" id="date" value={createdDate} disabled />
                </div>
                <div className="col-lg-4 mb-3">
                  <label htmlFor="portal" className="col-form-label">Portal</label>
                  <input type="text" className="form-control" id="portal" value={folkusUserViewData.language} disabled />
                </div>
              </div>
            </form>
          }
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn form-submit-secbtn" data-bs-dismiss="modal" onClick={handleFlokusUserViewClose}>Close</button>
        </Modal.Footer>
      </Modal>

      {/* ---------------------Membership History Popup--------------------------- */}
      <Modal size="wrapper modal-dialog-centered modal-lg" show={historyShowUser} onHide={handleHistoryClose}>
        <Modal.Header>
          <Modal.Title>
            <div className="modal-header text-center ">
              <h5 className="modal-title" id="exampleModalLabel">Membership History</h5>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loadingOne ? <Loading /> :
            <div className="modal-body ">
              <table className="table">
                <thead>
                  <tr>
                    <th>User</th>
                    <th>Type</th>
                    {/* <!-- <th>Joined By</th> --> */}
                    <th>Joined Date</th>
                    <th>Expiry Date</th>
                    <th>Current Status</th>
                  </tr>
                </thead>
                <tbody>
                  {hisInfo.length > 0 ? hisInfo.map((hisInfo, index) =>
                    <tr key={index}>
                      <td>{hisInfo.user_name}</td>
                      <td>{hisInfo.sub_type}</td>
                      <td>{hisInfo.start_date.substring(0, 10)}</td>
                      <td>{hisInfo.end_date}</td>
                      {hisInfo.active === 1 ?
                        <td><span className="active-color">Active</span></td> :
                        <td><span className="inactive-color">Inactive</span></td>
                      }
                    </tr>
                  ) : <tr><td colSpan="20" className='text-center'><p className="nodata-found">No Data Found</p> </td></tr>}
                </tbody>
              </table>
            </div>
          }
        </Modal.Body>
        <Modal.Footer>
          <div className="modal-footer">
            <button type="button" className="btn form-submit-secbtn" data-bs-dismiss="modal" onClick={handleHistoryClose}>Close</button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* ---------------------Edit Disabled Popup--------------------------- */}

      <Modal size="wrapper modal-dialog-centered modal-md" show={disabled} onHide={disabledEditClose}>
        <Modal.Header>
          <Modal.Title>
            <div className="modal-header text-center ">
              <h5 className="modal-title" id="exampleModalLabel">Edit Profile</h5>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className=" text-center ">
            <h5 className="modal-title" id="exampleModalLabel">Admin Can&apos;t Edit Profile</h5>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className=" modal-footer">
            <button type="button" className="btn form-submit-secbtn" data-bs-dismiss="modal" onClick={disabledEditClose}>Close</button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>

  );
}
export default Folkususers;