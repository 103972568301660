import React, { useState, useEffect } from 'react';
import API from '../../Services/BaseService';
import Header from '../Header/Header';
import SideBar from '../SideBar/SideBar';
import Pagination from 'reactjs-hooks-pagination';
import Loading from "../../Component/Loading/Loading";
import { useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import ls from 'local-storage';

const pageLimit = 10;
const Usersubscription = () => {
    const userData = useSelector(state => state.LoginReducer.payload);
    const [userSubScriptionInfo, setUserSubScriptionInfo] = useState("");
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [loadingOne, setLoadingOne] = useState(true);
    const [addShowUser, setAddShowUser] = useState(false);
    const [name, setName] = useState("");
    const [addSuccFlg, setAddSuccFlg] = useState(true);
    const [addSuccMsg, setAddSuccMsg] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const recallfunction = (data) => {
        setIsOpen(data);
    };
    useEffect(() => {
        getUserSubScriptionDetails();
    }, []);
    const getUserSubScriptionDetails = () => {
        let request = {
            admin_id: userData.admin_id,
            token: userData.token,
            language_id: ls.get("languageId") == "all" || ls.get("languageId") == "" ? "all" : ls.get("languageId").language_id
        }
        API.post("usersublist/condition", request).then(response => {
            // console.log("SubScriptionInfo res", response);
            setCurrentPage(1)
            setUserSubScriptionInfo(response.data.data);
            setTotalRecords(response.data.data.length);
            setLoading(false);
        });
    }
    const onSearch = (e) => {
        setName(e.target.value)
        setLoading(true);
    }
    const userSubSearch = () => {
        let request = {
            admin_id: userData.admin_id,
            token: userData.token,
            data: name,
            language_id: ls.get("languageId") == "all" || ls.get("languageId") == "" ? "all" : ls.get("languageId").language_id
        }
        API.post("userSubsSearch/condition", request).then(response => {
            // console.log("flokusUser Details response", response.data.data)
            setCurrentPage(1)
            setUserSubScriptionInfo(response.data.data);
            setTotalRecords(response.data.data.length);
            setLoading(false);
        });
    }
    useEffect(() => {
        if (name != "") {
            const timer = setTimeout(() => {
                userSubSearch();
            }, 200);
            return () => {
                clearTimeout(timer);
            };
        } else {
            getUserSubScriptionDetails();
        }
    }, [name]);
    const onKeydownSearch = (event) => {
        if (event.key === "Enter") {
            // console.log("Enter is pressed call search function");
            userSubSearch();
        }
    };
    // -------------------Add popup-------------------------------------------
    const [userName, setUserName] = useState("");
    const [selectedUserName, setSelectedUserName] = useState("");
    const [userNameId, setUserNameId] = useState("");
    const [plan, setPlan] = useState("");
    const [planInfo, setPlanInfo] = useState("");
    const [selectedPlan, setSelectedPlan] = useState("");
    const [noOfYear, setNoOfYear] = useState("");

    const [userNameError, setUserNameError] = useState("");
    const [planError, setPlanError] = useState("");
    const [noOfYearErrorError, setNoOfYearError] = useState("");

    const handleAddUserSubShow = () => {
        setAddShowUser(true);
        handleAddDropDown();
        handlePlanDropDown();
        setLoading(true);
    }
    const handleAddUserClose = () => {
        setAddShowUser(false);
        setUserNameError("");
        setPlanError("");
        setNoOfYearError("");
        setSelectedUserName("");
        setSelectedPlan("");
        setAddSuccFlg(true);
        setUserNameId("");
        setPlan("");
        setNoOfYear("");
    }
    const handleAddDropDown = () => {
        let request = {
            admin_id: userData.admin_id,
            token: userData.token,
        }
        API.post("user/condition", request).then(response => {
            setUserName(response.data.data);
            setLoading(false);

        });
    }
    const handleUserNameId = (e) => {
        setUserNameId(e.target.value);
        const selectedLangDetails = userName.filter(data => data.user_id == e.target.value)[0]
        setSelectedUserName(selectedLangDetails);

    }

    const handlePlanDropDown = () => {
        let request = {
            admin_id: userData.admin_id,
            token: userData.token,
        }
        API.post("subsTypeDropDown/condition", request).then(response => {
            setPlanInfo(response.data.data);
            setLoading(false);

        });
    }
    const handlePlanId = (e) => {
        setPlan(e.target.value);
        const selectedLangDetails = planInfo.filter(data => data.subscription_id == e.target.value)[0]
        setSelectedPlan(selectedLangDetails);
    }
    const getAddUserSubScription = () => {
        setUserNameError("");
        setPlanError("");
        setNoOfYearError("");

        if (!userNameId) {
            setUserNameError("UserName is required");
            return;
        }
        if (!plan) {
            setPlanError("Plan is required");
            return;
        }
        if (!noOfYear) {
            setNoOfYearError("No of years is required");
            return;
        }

        let request = {
            admin_id: userData.admin_id,
            token: userData.token,
            user_id: selectedUserName.user_id,
            subscription_method: "manual",
            subscription_id: selectedPlan.subscription_id,
            no_of_years: noOfYear,
            createdBy: userData.admin_id,
            updatedBy: userData.admin_id
        }
        API.post("userSubscriptionAdd/add", request).then(response => {
            if (response.data.success) {
                getUserSubScriptionDetails();
                setAddSuccFlg(false);
                setAddSuccMsg(response.data.data.message);
            }
            else {
                setUserNameError("UserName Already have Subscription");
            }
        });
    }
    // -------------------History popup-------------------------------------------
    const [historyShowUser, setHistoryShowUser] = useState(false);
    const [hisInfo, setHisInfo] = useState("");

    const handleHistoryShow = (data) => {
        setHistoryShowUser(true);
        handleHistory(data);
        setLoadingOne(true);
    }
    const handleHistoryClose = () => {
        setHistoryShowUser(false);
    }
    const handleHistory = (data) => {
        let request = {
            admin_id: userData.admin_id,
            token: userData.token,
            usersubscription_id: data.usersubscription_id
        }
        API.post("usersubhistory/condition", request).then(response => {
            setHisInfo(response.data.data);
            setLoadingOne(false);

        });
    }
    // -------------------View popup-------------------------------------------
    const [viewShowUser, setViewShowUser] = useState(false);
    const [viewInfo, setViewInfo] = useState("");
    const [createdDateView, setCreatedDateView] = useState("");
    const handleViewUserShow = (data) => {
        setViewShowUser(true);
        handleViewDetails(data);
        setLoadingOne(true);
    }
    const handleViewUserClose = () => {
        setViewShowUser(false);
    }
    const handleViewDetails = (data) => {
        let request = {
            admin_id: userData.admin_id,
            token: userData.token,
            user_id: data.user_id
        }
        API.post("usersubView/condition", request).then(response => {
            setViewInfo(response.data.data[0]);
            setCreatedDateView(response.data.data[0].start_date.substring(0, 10));
            setLoadingOne(false);

        });
    }
    return (
        <div className="wrapper">
            <SideBar sideBarOpen={isOpen} />
            <div id="content" className={!isOpen ? "" : 'active'}>
                <Header recallfunction={recallfunction} />
                <div className="main-content">
                    <div className="row">
                        <div className="common-heading-sec col-lg-12">
                            <h5 className="me-auto">User Subscription</h5>
                            <div className="search-bar">
                                <input type="search" className="form-control" placeholder="Search by Name" onKeyDown={onKeydownSearch}
                                    onChange={onSearch} />
                            </div>
                            <div className="add-btn" onClick={() => handleAddUserSubShow()}><i
                                className="fa-solid fa-plus me-2"></i>Add New User Subscriber</div>
                        </div>
                        {loading ? <Loading /> :
                            <>
                                <div className="rounded table-responsive">
                                    <table className="table bg-white">
                                        <thead>
                                            <tr>
                                                <th>UserName</th>
                                                <th>Subscription plan type</th>
                                                <th>Subscription method</th>
                                                <th>Created Date & Time</th>
                                                <th>Expiry Date</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {userSubScriptionInfo.length > 0 ? userSubScriptionInfo.sort((a, b) => b.usersubscription_id - a.usersubscription_id).slice(currentPage == 1 ? 0 : (currentPage - 1) * pageLimit, (currentPage == 1 ? currentPage * pageLimit : currentPage * pageLimit))
                                                .map((userSubScriptionInfo, index) =>
                                                    <tr key={index}>
                                                        <td><a onClick={() => handleViewUserShow(userSubScriptionInfo)} >{userSubScriptionInfo.user_name}</a></td>
                                                        <td>{userSubScriptionInfo.sub_type}</td>
                                                        <td>{userSubScriptionInfo.subscription_method}</td>
                                                        <td>{userSubScriptionInfo.start_date.substring(0, 10)} & {userSubScriptionInfo.start_date.substring(12, 19)}</td>
                                                        <td>{userSubScriptionInfo.end_date}</td>
                                                        <td><a onClick={() => handleHistoryShow(userSubScriptionInfo)} className="edit">History</a></td>
                                                    </tr>
                                                ) : <tr><td colSpan="20" className='text-center'><p className="nodata-found">No Data Found</p> </td></tr>}
                                        </tbody>
                                    </table>
                                </div>
                                <ul className="pagination">
                                    <Pagination className=""
                                        totalRecords={totalRecords}
                                        pageLimit={pageLimit}
                                        pageRangeDisplayed={1}
                                        onChangePage={setCurrentPage}
                                    />
                                </ul>
                            </>
                        }
                    </div>
                </div>
            </div>
            {/* ---------------------------Add Popup----------------------------- */}
            <Modal size="wrapper modal-dialog-centered modal-md" show={addShowUser} onHide={handleAddUserClose}>
                {addSuccFlg ? <Modal.Header>
                    <Modal.Title>
                        <h5 className="modal-title" id="exampleModalLabel">Add Subscription</h5>
                    </Modal.Title>
                </Modal.Header> : ""}
                <Modal.Body>
                    {addSuccFlg ?
                        <>
                            {loading ? <Loading /> :
                                <form>
                                    <div className="row">
                                        <div className="col-lg-6 mb-2 add-language">
                                            <label htmlFor="UserName" className="col-form-label">UserName <span>*</span></label>
                                            {/* <input type="text" className="form-control" id="UserName" onClick={(e) => setUserName(e.target.value)} /> */}
                                            <select className="form-select" id="UserName" onChange={handleUserNameId} >
                                                <option style={{ display: "none" }}>Select UserName</option>
                                                {userName.length > 0 &&
                                                    <>
                                                        {userName.map((data) => <option key={data.user_id} value={data.user_id} >{data.user_name}</option>)}
                                                    </>
                                                }
                                            </select>
                                            <p className="form-input-error">{userNameError}</p>
                                        </div>
                                        <div className="col-lg-6 mb-2">
                                            <label htmlFor="Phone" className="col-form-label">Email</label>
                                            <input type="text" className="form-control" id="Phone" value={selectedUserName.email} disabled />
                                        </div>
                                        <div className="col-lg-6 mb-2">
                                            <label htmlFor="Phone" className="col-form-label">Phone Number</label>
                                            <input type="text" className="form-control" id="Phone" value={selectedUserName.phone_no} disabled />
                                        </div>
                                        <div className="col-lg-6 mb-2 add-language">
                                            <label htmlFor="LastName" className="col-form-label">Plan <span>*</span></label>
                                            <select className="form-select" id="LastName" onChange={handlePlanId} >
                                                <option style={{ display: "none" }}>Select Plan</option>
                                                {planInfo.length > 0 &&
                                                    <>
                                                        {planInfo.map((data) => <option key={data.subscription_id} value={data.subscription_id} >{data.subs_name}</option>)}
                                                    </>
                                                }
                                            </select>
                                            <p className="form-input-error">{planError}</p>
                                        </div>
                                        <div className="col-lg-6 mb-3 add-language">
                                            <label htmlFor="Recordupto" className="col-form-label">No of year <span>*</span></label>
                                            <select className="form-select" id="Recordupto" onClick={(e) => setNoOfYear(e.target.value)} >
                                                <option style={{ display: "none" }}>Select year</option>
                                                <option value="1 Year">1 Year</option>
                                                <option value="2 Year">2 Year</option>
                                                <option value="3 Year">3 Year</option>
                                                <option value="4 Year">4 Year</option>
                                                <option value="5 Year">5 Year</option>
                                                <option value="6 Year">6 Year</option>
                                                <option value="7 Year">7 Year</option>
                                                <option value="8 Year">8 Year</option>
                                                <option value="9 Year">9 Year</option>
                                                <option value="10 Year">10 Year</option>
                                            </select>
                                            <p className="form-input-error">{noOfYearErrorError}</p>
                                        </div>
                                    </div>
                                </form>
                            }
                        </>
                        :
                        <div className="alert_box">
                            <div className="icon">
                                <i className="fa-solid fa-check"></i>
                            </div>
                            <header>{addSuccMsg}</header>
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn form-submit-secbtn" onClick={handleAddUserClose}>Close</button>
                    {addSuccFlg ? <button type="button" className="btn form-submit-btn" onClick={getAddUserSubScription} >Subscribe</button> : ""}
                </Modal.Footer>
            </Modal>

            {/* ---------------------------History Popup----------------------------- */}
            <Modal size="wrapper modal-dialog-centered modal-lg" show={historyShowUser} onHide={handleHistoryClose}>
                <Modal.Header>
                    <Modal.Title>
                        <h5 className="modal-title" id="exampleModalLabel">Membership History</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loadingOne ? <Loading /> :
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>UserName</th>
                                    <th>Type</th>
                                    <th>Joined Date</th>
                                    <th>Expiry Date</th>
                                    <th>Current Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {hisInfo.length > 0 ? hisInfo.map((hisInfo, index) =>
                                    <tr key={index}>
                                        <td>{hisInfo.user_name}</td>
                                        <td>{hisInfo.sub_type}</td>
                                        <td>{hisInfo.start_date.substring(0, 10)}</td>
                                        <td>{hisInfo.end_date}</td>
                                        {hisInfo.active === 1 ?
                                            <td><span className="active-color">Active</span></td> :
                                            <td><span className="inactive-color">Inactive</span></td>
                                        }
                                    </tr>
                                ) : <tr><td colSpan="20" className='text-center'><p className="nodata-found">No Data Found</p> </td></tr>}
                            </tbody>
                        </table>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn form-submit-secbtn" onClick={handleHistoryClose}>Close</button>
                </Modal.Footer>
            </Modal>

            {/* ---------------------------View Popup----------------------------- */}
            <Modal size="wrapper modal-dialog-centered modal-lg" show={viewShowUser} onHide={handleViewUserClose}>
                <Modal.Header>
                    <Modal.Title>
                        <h5 className="modal-title" id="exampleModalLabel">View Profile</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loadingOne ? <Loading /> :
                        <form>
                            <div className="row">
                                <div className="col-lg-4 mb-2">
                                    <label htmlFor="id" className="col-form-label">ID</label>
                                    <input type="text" className="form-control" id="date" value={viewInfo.user_id} disabled />
                                </div>
                                <div className="col-lg-4 mb-2">
                                    <label htmlFor="user-name" className="col-form-label">UserName</label>
                                    <input type="text" className="form-control" id="user-name" value={viewInfo.user_name} disabled />
                                </div>
                                <div className="col-lg-4 mb-2">
                                    <label htmlFor="email" className="col-form-label">Email</label>
                                    <input type="text" className="form-control" id="email" value={viewInfo.email} disabled />
                                </div>
                                <div className="col-lg-4 mb-2">
                                    <label htmlFor="phone-number" className="col-form-label">Phone Number</label>
                                    <input type="text" className="form-control" id="phone-number" value={viewInfo.phone_no} disabled />
                                </div>

                                <div className="col-lg-4 mb-2">
                                    <label htmlFor="dob" className="col-form-label">Date of Birth</label>
                                    <input type="text" className="form-control" id="dob" value={viewInfo.dob} disabled />
                                </div>
                                <div className="col-lg-4 mb-2">
                                    <label htmlFor="gender" className="col-form-label">Gender</label>
                                    <input type="text" className="form-control" id="gender" value={viewInfo.gender} disabled />
                                </div>
                                <div className="col-lg-4 mb-2">
                                    <label htmlFor="created-date" className="col-form-label">Created Date</label>
                                    <input type="text" className="form-control" id="date" value={createdDateView} disabled />
                                </div>
                                <div className="col-lg-4 mb-2">
                                    <label htmlFor="portal" className="col-form-label">Portal</label>
                                    <input type="text" className="form-control" id="portal" value={viewInfo.language} disabled />
                                </div>
                            </div>
                        </form>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn form-submit-secbtn" onClick={handleViewUserClose}>Close</button>
                </Modal.Footer>
            </Modal>
        </div>


    );
};
export default Usersubscription;