import React, { useState, useEffect } from 'react';
import API from '../../Services/BaseService';
import Header from "../Header/Header";
import SideBar from "../SideBar/SideBar";
import Pagination from 'reactjs-hooks-pagination';
import Loading from "../../Component/Loading/Loading";
import { useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import ls from 'local-storage';
import 'react-multiple-select-dropdown-lite/dist/index.css'
import MultiSelect from 'react-multiple-select-dropdown-lite';

const pageLimit = 10;
const UserNameClaimRequest = () => {
  const userData = useSelector(state => state.LoginReducer.payload);
  const [claimInfo, setClaimInfo] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [loadingOne, setLoadingOne] = useState(true);
  const [editShow, setEditShow] = useState(false);
  const [notificationShow, setNotificationShow] = useState(false);
  const [flagOpen, setFlagOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [popupFlag, setpopupFlag] = useState(false);

  // ------------Search----------------
  const [name, setName] = useState("");

  // =========================StatusDropdown=========================== //
  const [filterName, setFilterName] = useState("");
  const [statusInfo, setStatusInfo] = useState("");


  const recallfunction = (data) => {
    setIsOpen(data);
  }
  const toggleOpen = () => {
    setFlagOpen(!flagOpen);
  }
  useEffect(() => {
    getClaimDetails();
    statusDetails();
  }, []);
  const getClaimDetails = () => {
    let request = {
      admin_id: userData.admin_id,
      token: userData.token,
      language_id: ls.get("languageId") == "all" || ls.get("languageId") == "" ? "all" : ls.get("languageId").language_id
    }
    API.post("claimusernamelist/condition", request).then(response => {
      // console.log("Claim res", response);
      // setCurrentPage(1)
      setClaimInfo(response.data.data);
      setClaimViewInfo(response.data.data.length);
      setTotalRecords(response.data.data.length);
      setLoading(false);
    });
  }

  // ------------Search----------------

  const onSearch = (e) => {
    setName(e.target.value)
    setLoading(true);
  }

  const claimInfoSearch = () => {

    let request = {
      admin_id: userData.admin_id,
      token: userData.token,
      data: name,
      status: filterName,
      language_id: ls.get("languageId") == "all" || ls.get("languageId") == "" ? "all" : ls.get("languageId").language_id
    }

    API.post("claimusernamefilter/condition", request).then(response => {
      // console.log("admin Details response", response.data.data)
      setCurrentPage(1)
      setClaimInfo(response.data.data);
      setTotalRecords(response.data.data.length);
      setLoading(false);
    });
  }

  useEffect(() => {
    if (name != "") {
      const timer = setTimeout(() => {
        claimInfoSearch();
      }, 200);
      return () => {
        clearTimeout(timer);
      };
    } else {
      claimStatusDropdown(filterName);
    }
  }, [name]);

  const onKeydownClaimSearch = (event) => {
    if (event.key === "Enter") {
      // console.log("Enter is pressed call search function");
      claimInfoSearch();
    }
  };

  // =========================StatusDropdown=========================== //
  const statusDetails = () => {
    let request = {
      admin_id: userData.admin_id,
      token: userData.token
    }
    API.post("userclaimDropdown/condition", request).then(response => {
      // console.log("Reason res", response);
      setStatusInfo(response.data.data.map((data) => { return { "label": data.status, "value": data.status_id.toString() } }));
      setLoading(false);
    });
  }

  const handleOnchange = (val) => {
    var numberArray = val;
    setFilterName(numberArray);
    claimStatusDropdown(numberArray)
    setLoading(true);
  }

  const claimStatusDropdown = (data) => {

    let request = {
      admin_id: userData.admin_id,
      token: userData.token,
      data: name,
      status: data,
      language_id: ls.get("languageId") == "all" || ls.get("languageId") == "" ? "all" : ls.get("languageId").language_id
    }

    API.post("claimusernamefilter/condition", request).then(response => {
      // console.log("admin List response", response.data.data)
      setCurrentPage(1)
      setClaimInfo(response.data.data);
      setTotalRecords(response.data.data.length);
      setLoading(false);
    });
  }

  // -----------------View popup---------------
  const [claimUserView, setClaimUserView] = useState(false);
  const [createdDate, setCreatedDate] = useState("");
  const [claimViewData, setClaimViewData] = useState("");

  const handleClaimViewShow = (data) => {
    getClaimUserViewDetails(data);
    setClaimUserView(true);
    setCreatedDate(data.createdAt.substring(0, 10));
    setLoadingOne(true);
  }
  const handleClaimViewClose = () => {
    setClaimUserView(false);
  }
  const getClaimUserViewDetails = (data) => {
    let request = {
      admin_id: userData.admin_id,
      token: userData.token,
      user_id: data.user_id
    };
    API.post("userbyId/condition", request).then(response => {
      setClaimViewData(response.data.data[0]);
      setLoadingOne(false);
    });
  }

  {/* ---------------------Edit View--------------------------- */ }


  const getClaimEditDetails = (data) => {
    let request = {
      admin_id: userData.admin_id,
      token: userData.token,
      claim_id: data.claim_id
    };
    API.post("claimusernameview/condition", request).then(response => {
      // console.log('dashboard res', response);
      setClaimViewInfo(response.data.data[0]);
      seteditStatus(response.data.data[0].status);
      setUserName(response.data.data[0].user_name);
      setClaimedUsername(response.data.data[0].claimed_username);
      setReferenceLink(response.data.data[0].reference_link);
      setComments(response.data.data[0].comments);
      setCliamRequestDate(response.data.data[0].cliam_request_date);
      setLoadingOne(false);


    });
  };
  {/* ---------------------Edit Popup--------------------------- */ }

  const [claimViewInfo, setClaimViewInfo] = useState("");
  const [editStatus, seteditStatus] = useState("");
  const [userName, setUserName] = useState("");
  const [claimedUsername, setClaimedUsername] = useState("");
  const [referenceLink, setReferenceLink] = useState("");
  const [comments, setComments] = useState("");
  const [cliamRequestDate, setCliamRequestDate] = useState("");

  const handleEditClose = () => {
    setEditShow(false);
    setClaimViewInfo("");
    seteditStatus("");

  }
  const handleEditShow = (data) => {
    setEditShow(true);
    getClaimEditDetails(data);
    setLoadingOne(true);
    setpopupFlag(false)

  }

  const handleEditSubmit = () => {
    let request = {
      admin_id: userData.admin_id,
      token: userData.token,
      user_name: userName,
      claim_id: claimViewInfo.claim_id,
      status: editStatus,
      createdBy: userData.admin_id,
      updatedBy: userData.admin_id
    };
    API.post("claimUserName/update", request).then(response => {
      setClaimViewInfo(response.data.data[0]);
      getClaimDetails();
      setNotificationShow(false);
    });
  }

  {/* ---------------------Notification Popup--------------------------- */ }
  const handleNotificationClose = () => {
    setNotificationShow(false);
  }
  const handleNotificationShow = () => {
    setNotificationShow(true);
    setpopupFlag(true)
  }

  return (
    <div className="wrapper">
      <SideBar sideBarOpen={isOpen} />
      <div id="content" className={!isOpen ? "" : 'active'}>
        <Header recallfunction={recallfunction} />
        <div className="main-content" onClick={flagOpen === false ? "" : toggleOpen} >
          <div className="row">
            <div className="common-heading-sec col-lg-12">
              <h5 className="me-auto">Username claim requests</h5>
              <div className="search-bar">
                <input type="search" className="form-control" placeholder="Search by Username" onKeyDown={onKeydownClaimSearch}
                  onChange={onSearch} />
              </div>
              <div className="dropdown-sec me-2 drop_clim">
                <MultiSelect
                  onChange={handleOnchange}
                  options={statusInfo}
                  singleSelect={true}
                  defaultValue={filterName}
                />
                {/* <select className="form-select" onChange={(e) => { claimStatusDropdown(e.target.value); setFilterName(e.target.value) }}>
                  <option style={{ display: "none" }}>Select Status</option>
                  <option value="">All</option>
                  <option value="approve username">Approve Username</option>
                  <option value="approve with blue tick">Approve with blue tick</option>
                  <option value="reject">Reject</option>
                  <option value="review">Review</option>
                </select> */}
              </div>
            </div>
            {loading ? <Loading /> :
              <>
                <div className="rounded table-responsive">
                  <table className="table bg-white">
                    <thead>
                      <tr>
                        <th>Username</th>
                        <th>Followers</th>
                        <th>Claimed Username</th>
                        <th>Referece Link</th>
                        <th>Comments</th>
                        <th>Claimes</th>
                        <th>Requested Date</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>

                      {claimInfo.length > 0 ? claimInfo.sort((a, b) => b.claim_id - a.claim_id).slice(currentPage == 1 ? 0 : (currentPage - 1) * pageLimit, (currentPage == 1 ? currentPage * pageLimit : currentPage * pageLimit))
                        .map((claimInfo, index) =>
                          <tr key={index}>
                            <td><a onClick={() => handleClaimViewShow(claimInfo)}>{claimInfo.user_name}</a></td>
                            <td>{claimInfo.followers}</td>
                            <td>{claimInfo.claimed_username}</td>
                            <td>{claimInfo.reference_link}</td>
                            <td>{claimInfo.comments}</td>
                            <td>{claimInfo.claim}</td>
                            <td>{claimInfo.createdAt.substring(0, 10)}</td>
                            {claimInfo.active === 1 ?
                              <td><span className="premium-color">Approve user name</span></td> :
                              claimInfo.active === 2 ?
                                <td><span className="block-color">Approve with blue tick</span></td> :
                                claimInfo.active === 4 ?
                                  <td><span className="none-color">Reject</span></td> :
                                  <td><span className="review-color">Review</span></td>
                            }
                            <td><a className="edit" onClick={() => handleEditShow(claimInfo)}>Edit</a></td>
                          </tr>
                        ) : <tr><td colSpan="20" className='text-center'><p className="nodata-found">No Data Found</p> </td></tr>}


                    </tbody>
                  </table>
                </div>
                <ul className="pagination">
                  <Pagination className=""
                    totalRecords={totalRecords}
                    pageLimit={pageLimit}
                    pageRangeDisplayed={1}
                    onChangePage={setCurrentPage}
                  />
                </ul>
              </>
            }
          </div>
        </div>
      </div>

      {/* ---------------------View Popup--------------------------- */}
      <Modal size="wrapper modal-dialog-centered modal-lg" show={claimUserView} onHide={handleClaimViewClose}>
        <Modal.Header>
          <Modal.Title>
            <h5 className="modal-title" id="exampleModalLabel">View Profile</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loadingOne ? <Loading /> :
            <form>
              <div className="row">
                <div className="col-lg-4 mb-3">
                  <label htmlFor="id" className="col-form-label">ID</label>
                  <input type="text" className="form-control" id="date" value={claimViewData.user_id} disabled />
                </div>
                <div className="col-lg-4 mb-3">
                  <label htmlFor="name" className="col-form-label">Name</label>
                  <input type="text" className="form-control" id="name" value={claimViewData.name} disabled />
                </div>
                <div className="col-lg-4 mb-3">
                  <label htmlFor="user-name" className="col-form-label">User Name</label>
                  <input type="text" className="form-control" id="user-name" value={claimViewData.user_name} disabled />
                </div>
                <div className="col-lg-4 mb-3">
                  <label htmlFor="email" className="col-form-label">Email</label>
                  <input type="text" className="form-control" id="email" value={claimViewData.email} disabled />
                </div>
                <div className="col-lg-4 mb-3">
                  <label htmlFor="phone-number" className="col-form-label">Phone Number</label>
                  <input type="text" className="form-control" id="phone-number" value={claimViewData.phone_no} disabled />
                </div>
                <div className="col-lg-4 mb-3">
                  <label htmlFor="dob" className="col-form-label">Date of Birth</label>
                  <input type="date" className="form-control" id="dob" value={claimViewData.dob} disabled />
                </div>
                <div className="col-lg-4 mb-3">
                  <label htmlFor="gender" className="col-form-label">Gender</label>
                  <input type="text" className="form-control" id="gender" value={claimViewData.gender} disabled />
                </div>
                <div className="col-lg-4 mb-3">
                  <label htmlFor="created-date" className="col-form-label">Created Date</label>
                  <input type="date" className="form-control" id="date" value={createdDate} disabled />
                </div>
                <div className="col-lg-4 mb-3">
                  <label htmlFor="portal" className="col-form-label">Portal</label>
                  <input type="text" className="form-control" id="portal" value={claimViewData.language} disabled />
                </div>
              </div>
            </form>
          }
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn form-submit-secbtn" onClick={handleClaimViewClose}>Close</button>
        </Modal.Footer>
      </Modal>

      {/* ---------------------Edit Popup--------------------------- */}
      {popupFlag == false ?
        <>
          <Modal size="wrapper modal-dialog-centered modal-lg" show={editShow} onHide={handleEditClose}>
            <Modal.Header>
              <Modal.Title>
                <h5 className="modal-title" id="exampleModalLabel">Edit Status</h5>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {loadingOne ? <Loading /> :

                <form>
                  <div className="row">
                    <div className="col-lg-4 mb-3">
                      <label htmlFor="id" className="col-form-label">Username</label>
                      <input type="text" className="form-control" id="date" value={userName} disabled />
                    </div>
                    <div className="col-lg-4 mb-3">
                      <label htmlFor="id" className="col-form-label">Claimed Username</label>
                      <input type="text" className="form-control" id="date" value={claimedUsername} disabled />
                    </div>
                    <div className="col-lg-4 mb-3">
                      <label htmlFor="id" className="col-form-label">Referece Link</label>
                      <input type="text" className="form-control" id="date" value={referenceLink} disabled />
                    </div>
                    <div className="col-lg-4 mb-3">
                      <label htmlFor="id" className="col-form-label">Comments</label>
                      <input type="text" className="form-control" id="date" value={comments} disabled />
                    </div>
                    <div className="col-lg-4 mb-3">
                      <label htmlFor="id" className="col-form-label">Requested Date</label>
                      <input type="text" className="form-control" id="date" value={cliamRequestDate} disabled />
                    </div>

                    <div className="col-lg-4 mb-3">
                      <label htmlFor="id" className="col-form-label">Status</label>
                      <select className="form-select" onChange={(e) => seteditStatus(e.target.value)} >
                        <option style={{ display: "none" }}>{editStatus}</option>
                        <option value="approve username" >Approve user name</option>
                        <option value="approve with blue tick" >Approve with blue tick</option>
                        <option value="reject" >Reject</option>
                        <option value="review" >Review</option>
                      </select>
                    </div>
                  </div>
                </form>
              }
            </Modal.Body>
            <Modal.Footer>
              <button type="button" className="btn form-submit-secbtn" onClick={handleEditClose} >Close</button>
              <button type="button" className="btn form-submit-btn" onClick={handleNotificationShow}>Submit</button>
            </Modal.Footer>
          </Modal>

        </>


        :
        <>
          <Modal size="wrapper modal-dialog-centered modal-md" show={notificationShow} onHide={handleNotificationClose}>
            <Modal.Header>
              <Modal.Title>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="alert_box">
                <div className="icon">
                  <i className="fa-solid fa-bell"></i>
                </div>
                <header>Notification Confirmation</header>
                {editStatus == "approve username" ?
                  <p>Are you sure you want to approve Username?</p> :
                  editStatus == "approve with blue tick" ?
                    <p>Are you sure you want to approve with  blue tick?</p> :
                    editStatus == "reject" ?
                      <p>Are you sure you want to reject?</p> :
                      <p>Are you sure you want to review?</p>}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="modal-footer">
                <button type="button" className="btn form-submit-secbtn" data-bs-dismiss="modal" onClick={handleNotificationClose}>Cancel</button>
                <button type="button" className="btn form-submit-btn" onClick={handleEditSubmit} >Yes</button>
              </div>
            </Modal.Footer>
          </Modal>
        </>
      }

    </div>

  );
}

export default UserNameClaimRequest;