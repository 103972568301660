import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import API from '../../Services/BaseService';
import Header from '../Header/Header';
import SideBar from '../SideBar/SideBar';
import Loading from '../../Component/Loading/Loading';
import { Modal } from 'react-bootstrap';
import ls from 'local-storage';
import Countdown, { zeroPad } from "react-countdown";

const DashBoard = () => {
    const userData = useSelector(state => state.LoginReducer.payload);
    // console.log("userData", userData);
    const [dashBoardInfo, setDashBoardInfo] = useState("");
    const [userView, setUserView] = useState("");
    // const [userEdit,setUserEdit] = useState("");
    // const [userEditError,setUserEditError] = useState("");
    const [loading, setLoading] = useState(true);
    const [createdDate, setCreatedDate] = useState("");
    const [viewProfile, setViewProfile] = useState(false);
    const [usersViewInfo, setUsersViewInfo] = useState("");

    // const [langId, setLangId] = useState("all");

    const [isOpen, setIsOpen] = useState(false);
    const recallfunction = (data) => {
        // console.log('reCalling==', data);
        setIsOpen(data);
    };

    useEffect(() => {
        getDashBoardDetails();
    }, []);
    const getDashBoardDetails = () => {
        let request = {
            admin_id: userData.admin_id,
            token: userData.token,
            language_id: ls.get("languageId") == "all" || ls.get("languageId") == "" ? "all" : ls.get("languageId").language_id
        };
        API.post('userList/condition', request).then(response => {
            // console.log('DashBoard res', response);
            setDashBoardInfo(response.data.data);
            setLoading(false);
        });
    };

    const handleViewProfileClose = () => {
        setViewProfile(false);
    }
    const handleViewProfileShow = (data) => {
        setViewProfile(true);
        getDashBoardEditDetails(data);
        setCreatedDate(data.createdAt.substring(0, 10));
        setLoading(true);
    }

    const getDashBoardEditDetails = (data) => {
        let request = {
            admin_id: userData.admin_id,
            token: userData.token,
            user_id: data.user_id

        };
        API.post("userbyId/condition", request).then(response => {
            // console.log('dashboard res', response);
            setUserView(response.data.data[0])
            setLoading(false);
        });
    };

    const Completionist = () => <span>{""}</span>;
    const renderer = ({ days, completed }) => {
        if (completed) {
            return <Completionist />;
        } else {
            return (
                <div >
                    <div className="dbox">
                        <span>{zeroPad(days)}</span> days ago
                    </div>
                </div>
            );
        }
    };

    // {-----------------}/
    useEffect(() => {
        getUsersView();
    }, []);
    const getUsersView = () => {
        let request = {
            admin_id: userData.admin_id,
            token: userData.token,
        };
        API.post('dashboardCalc/condition', request).then(response => {
            // console.log('DashBoardView res', response);
            setUsersViewInfo(response.data.data[0]);
        });

    };
    return (
        <>
            <div className="wrapper">
                <SideBar sideBarOpen={isOpen} />
                <div id="content" className={!isOpen ? '' : 'active'}>
                    <Header recallfunction={recallfunction} />
                    <div className="main-content">
                        <section className="dashboard-sec">
                            <div className="row">
                                <h4 className="common-title">dashboard</h4>
                                <div className="custom-column">
                                    <div className="dash-outer-box">
                                        <h3>Realtime users</h3>
                                        <div className="box-content">
                                            <div className="dast-title">
                                                <h6>{usersViewInfo?.real_users}</h6>
                                                <p>Since last one hour</p>
                                            </div>
                                            <div className="dash-icon dash-icon-color1">
                                                <i className="fa-solid fa-user"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="custom-column">
                                    <div className="dash-outer-box">
                                        <h3>Total users acc</h3>
                                        <div className="box-content">
                                            <div className="dast-title">
                                                <h6>{usersViewInfo?.total_user_acc}</h6>
                                                {/* <!-- <p>Since last one hour</p> --> */}
                                            </div>
                                            <div className="dash-icon dash-icon-color2">
                                                <i className="fa-solid fa-users"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="custom-column">
                                    <div className="dash-outer-box">
                                        <h3>Total Stories</h3>
                                        <div className="box-content">
                                            <div className="dast-title">
                                                <h6>{usersViewInfo?.stories}</h6>
                                                {/* <!-- <p>Since last one hour</p> --> */}
                                            </div>
                                            <div className="dash-icon dash-icon-color3">
                                                <i className="fa-solid fa-circle-play"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="custom-column">
                                    <div className="dash-outer-box">
                                        <h3>Total premium users</h3>
                                        <div className="box-content">
                                            <div className="dast-title">
                                                <h6>{usersViewInfo?.premium_users}</h6>
                                                {/* <!-- <p>Since last one hour</p> --> */}
                                            </div>
                                            <div className="dash-icon dash-icon-color4">
                                                <i className="fa-solid fa-crown"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="custom-column">
                                    <div className="dash-outer-box">
                                        <h3>Total Supreme users</h3>
                                        <div className="box-content">
                                            <div className="dast-title">
                                                <h6>{usersViewInfo?.supreme_users}</h6>
                                                {/* <!-- <p>Since last one hour</p> --> */}
                                            </div>
                                            <div className="dash-icon dash-icon-color5">
                                                <i className="fa-solid fa-award"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="custom-column">
                                    <div className="dash-outer-box">
                                        <h3>Realtime users</h3>
                                        <div className="box-content">
                                            <div className="dast-title">
                                                <h6>{usersViewInfo?.last_week_user}</h6>
                                                <p>Since last one week</p>
                                            </div>
                                            <div className="dash-icon dash-icon-color1">
                                                <i className="fa-solid fa-user"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div> <div className="custom-column">
                                    <div className="dash-outer-box">
                                        <h3>Total users acc</h3>
                                        <div className="box-content">
                                            <div className="dast-title">
                                                <h6>{usersViewInfo?.last_week_totalusers}</h6>
                                                <p>Since last one week</p>
                                            </div>
                                            <div className="dash-icon dash-icon-color2">
                                                <i className="fa-solid fa-users"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="custom-column">
                                    <div className="dash-outer-box">
                                        <h3>Total Stories</h3>
                                        <div className="box-content">
                                            <div className="dast-title">
                                                <h6>{usersViewInfo?.last_total_stories}</h6>
                                                <p>Since last one week</p>
                                            </div>
                                            <div className="dash-icon dash-icon-color3">
                                                <i className="fa-solid fa-circle-play"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="custom-column">
                                    <div className="dash-outer-box">
                                        <h3>Total premium users</h3>
                                        <div className="box-content">
                                            <div className="dast-title">
                                                <h6>{usersViewInfo?.last_premium_users}</h6>
                                                <p>Since last one week</p>
                                            </div>
                                            <div className="dash-icon dash-icon-color4">
                                                <i className="fa-solid fa-crown"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="custom-column">
                                    <div className="dash-outer-box">
                                        <h3>Total Supreme users</h3>
                                        <div className="box-content">
                                            <div className="dast-title">
                                                <h6>{usersViewInfo?.last_supreme_users}</h6>
                                                <p>Since last one week</p>
                                            </div>
                                            <div className="dash-icon dash-icon-color5">
                                                <i className="fa-solid fa-award"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="custom-column">
                                    <div className="dash-outer-box">
                                        <h3>Total Play hours</h3>
                                        <div className="box-content">
                                            <div className="dast-title">
                                                <h6>10</h6>
                                                <p>Since last week</p>
                                            </div>
                                            <div className="dash-icon dash-icon-color6">
                                                <i className="fa-solid fa-clock"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="custom-column">
                                    <div className="dash-outer-box">
                                        <h3>Total Comments</h3>
                                        <div className="box-content">
                                            <div className="dast-title">
                                                <h6>{usersViewInfo?.comments}</h6>
                                                <p>Since last week</p>
                                            </div>
                                            <div className="dash-icon dash-icon-color3">
                                                <i className="fa-solid fa-message"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="custom-column">
                                    <div className="dash-outer-box">
                                        <h3>Total Shares</h3>
                                        <div className="box-content">
                                            <div className="dast-title">
                                                <h6>{usersViewInfo?.total_shares}</h6>
                                                <p>Since last week</p>
                                            </div>
                                            <div className="dash-icon dash-icon-color5">
                                                <i className="fa-solid fa-share"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="custom-column">
                                    <div className="dash-outer-box">
                                        <h3>Total Likes</h3>
                                        <div className="box-content">
                                            <div className="dast-title">
                                                <h6>{usersViewInfo?.likes}</h6>
                                                <p>Since last week</p>
                                            </div>
                                            <div className="dash-icon dash-icon-color1">
                                                <i className="fa-solid fa-heart"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="custom-column">
                                    <div className="dash-outer-box">
                                        <h3>Total Plays</h3>
                                        <div className="box-content">
                                            <div className="dast-title">
                                                <h6>{usersViewInfo?.total_plays}</h6>
                                                <p>Since last week</p>
                                            </div>
                                            <div className="dash-icon dash-icon-color2">
                                                <i className="fa-solid fa-play"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </section>
                        <div className="row">
                            <div className="common-heading-sec col-lg-12">
                                <h5 className="me-auto">Recent Users</h5>
                            </div>
                            {loading ? <Loading /> :
                                <div className="rounded table-responsive">
                                    <table className="table bg-white">
                                        <thead>
                                            <tr>
                                                <th>UserName</th>
                                                <th>Followers</th>
                                                <th>Phone No</th>
                                                <th>Date</th>
                                                <th>Email Id</th>
                                                <th>Last Seen</th>
                                                <th>Membership</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {dashBoardInfo.length > 0 ? dashBoardInfo.slice(0, 5).map((dashBoardInfo, index) =>
                                                <tr key={index}>
                                                    <td><a onClick={() => handleViewProfileShow(dashBoardInfo)}>{dashBoardInfo.user_name}</a></td>
                                                    <td>{dashBoardInfo.followers}</td>
                                                    <td>{dashBoardInfo.phone_no}</td>
                                                    <td>{dashBoardInfo.createdAt.substring(0, 10)}</td>
                                                    <td>{dashBoardInfo.email}</td>
                                                    <td><Countdown date={Date.now() + (dashBoardInfo.last_seen * 60 * 1000)} renderer={renderer} /></td>
                                                    {dashBoardInfo.subs_name === "premium" || dashBoardInfo.subs_name === "Premium" ?
                                                        <td><span className="premium-color">Premium</span></td> :
                                                        dashBoardInfo.subs_name === "supreme" || dashBoardInfo.subs_name === "Supreme" ?
                                                            <td><span className="supreme-color">Supreme</span></td> :
                                                            <td><span className="none-color">None</span></td>
                                                    }
                                                </tr>
                                            ) : <tr><td colSpan="20" className='text-center'><p className="nodata-found">No Data Found</p> </td></tr>}
                                        </tbody>
                                    </table>
                                </div>
                            }

                        </div>
                    </div>
                </div>
            </div>

            {/* ---------------------------View Popup----------------------------- */}
            <Modal size="wrapper modal-dialog-centered modal-lg" show={viewProfile} onHide={handleViewProfileClose}>
                <Modal.Header>
                    <Modal.Title>
                        <h5 className="modal-title" id="exampleModalLabel">View Profile</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loading ? <Loading /> :
                        <form>
                            <div className="row">
                                <div className="col-lg-4 mb-3">
                                    <label htmlFor="id" className="col-form-label">ID</label>
                                    <input type="text" className="form-control" id="id" value={userView?.user_id} disabled />
                                </div>
                                <div className="col-lg-4 mb-3">
                                    <label htmlFor="name" className="col-form-label">Name</label>
                                    <input type="text" className="form-control" id="name" Value={userView?.name} disabled />
                                </div>
                                <div className="col-lg-4 mb-3">
                                    <label htmlFor="user-name" className="col-form-label">User Name</label>
                                    <input type="text" className="form-control" id="user-name" value={userView?.user_name} disabled />
                                </div>
                                <div className="col-lg-4 mb-3">
                                    <label htmlFor="email" className="col-form-label">Email</label>
                                    <input type="text" className="form-control" id="email" value={userView.email} disabled />
                                </div>
                                <div className="col-lg-4 mb-3">
                                    <label htmlFor="phone-number" className="col-form-label">Phone Number</label>
                                    <input type="text" className="form-control" id="phone-number" value={userView?.phone_no} disabled />
                                </div>
                                <div className="col-lg-4 mb-3">
                                    <label htmlFor="gender" className="col-form-label">Gender</label>
                                    <input type="text" className="form-control" id="gender" value={userView?.gender} disabled />
                                </div>
                                <div className="col-lg-4 mb-3">
                                    <label htmlFor="created-date" className="col-form-label">Created Date</label>
                                    <input type="date" className="form-control"
                                        min="2018-01-01" max="2018-12-31" id="date" value={createdDate} disabled />
                                </div>
                                <div className="col-lg-4 mb-3">
                                    <label htmlFor="portal" className="col-form-label">Portal</label>
                                    <input type="text" className="form-control" id="portal" value={userView.language} disabled />
                                </div>
                            </div>
                        </form>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn form-submit-secbtn" onClick={handleViewProfileClose}>Close</button>
                </Modal.Footer>
            </Modal>

        </>
    );
};
export default DashBoard;