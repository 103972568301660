import React, { useState, useEffect } from 'react';
import Header from '../Header/Header';
import SideBar from '../SideBar/SideBar';
// import cateimg from '../../assets/img/cate-img.png';
import API from '../../Services/BaseService';
import { useSelector } from 'react-redux';
import Loading from "../../Component/Loading/Loading";
import { Modal } from 'react-bootstrap';
import 'react-multiple-select-dropdown-lite/dist/index.css';
import FileBase64 from 'react-file-base64';
import adduser from '../../assets/img/adduser.jpg';
import 'react-multiple-select-dropdown-lite/dist/index.css'
import MultiSelect from 'react-multiple-select-dropdown-lite';

const Categories = () => {
    const userData = useSelector(state => state.LoginReducer.payload);
    const [mainCategory, setMainCategory] = useState(true);
    const [subCategory, setSubCategory] = useState(false);
    const [pendingEnable, setPendingEnable] = useState(false);
    const [mainCatInfo, setMainCatInfo] = useState("");
    const [subCatInfo, setSubCatInfo] = useState("");
    const [pendingInfo, setPendingInfo] = useState("");
    const [loading, setLoading] = useState(true);
    const [showAdd, setShowAdd] = useState(false);
    const [mainCatFields, setMainCatFields] = useState(true);
    const [subCatFields, setSubCatFields] = useState(false);
    const [dropDownListInfo, setDropDownListInfo] = useState([]);
    const [addSuccFlg, setAddSuccFlg] = useState(true);
    const [addSuccMsg, setAddSuccMsg] = useState("");
    const [editSuccFlg, setEditSuccFlg] = useState(true);
    const [editSuccMsg, setEditSuccMsg] = useState("");
    const [PenEditSuccFlg, setPenEditSuccFlg] = useState(true);
    const [PenEditSuccMsg, setPenEditSuccMsg] = useState("");
    const [doc, setDoc] = useState("");
    const [type, setType] = useState("");
    const [mainName, setMainName] = useState("");
    const [subName, setSubName] = useState("");
    const [pendingName, setPendingName] = useState("");
    // const [topicActive, setTopicActive] = useState("no");

    // -------------Pending Popup----------------
    const [showPending, setShowPending] = useState(false);
    const [viewPending, setViewPending] = useState("");
    const [viewCreatedDate, setViewCreatedDate] = useState("");
    const [pendingStatus, setPendingStatus] = useState("no");
    const [portalEditvalue, setPortalEditvalue] = useState([]);
    const [editPenViewInfo, setEditPenViewInfo] = useState([]);

    const [isOpen, setIsOpen] = useState(false);
    const recallfunction = (data) => {
        setIsOpen(data);
    };

    useEffect(() => {
        getMainCatDetails();
        // getSubCatDetails();
        getPendingDetails();


    }, []);
    const getMainCatDetails = () => {
        let request = {
            admin_id: userData.admin_id,
            token: userData.token
        }
        API.post("categoryList/condition", request).then(response => {
            // console.log("categoryList res", response);
            setMainCatInfo(response.data.data);
            setLoading(false);
        });
    }
    const getSubCatDetails = () => {
        let request = {
            admin_id: userData.admin_id,
            token: userData.token
        }
        API.post("subcategoryList/condition", request).then(response => {
            // console.log("categoryList res", response);
            setSubCatInfo(response.data.data);

        });
    }
    const getPendingDetails = () => {
        let request = {
            admin_id: userData.admin_id,
            token: userData.token
        }
        API.post("pendingList/condition", request).then(response => {
            // console.log("categoryList res", response);
            setPendingInfo(response.data.data);
        });
    }
    const getFiles = (file) => {
        // console.log("================>", file.type)
        setType("")
        if (file.type.includes("jpg") || file.type.includes("jpeg") || file.type.includes("png")) {
            setDoc(file);
        } else {
            setType("0");
        }
    }
    // -------Active OnAir------------
    const getOnAirActive = (data, activeId) => {
        const topicActive = document.getElementById(activeId)
        let request = {
            admin_id: userData.admin_id,
            token: userData.token,
            sub_category_id: data.sub_category_id,
            status: topicActive.checked === true ? "Active" : "inActive"
        }
        API.post("categoryStatus/update", request).then(response => {
            if (response.data.success) {
                getSubCatDetails();
            }
        });
    }
    // ---------------Cat Search------------------
    const onMainSearch = (e) => {
        setMainName(e.target.value)
    }
    const getMainCatSearch = () => {
        let request = {
            admin_id: userData.admin_id,
            token: userData.token,
            data: mainName
        }
        API.post("categorySearch/condition", request).then(response => {
            // console.log("categoryList res", response);
            setMainCatInfo(response.data.data);
        });
    }
    useEffect(() => {
        if (mainName != "") {
            const timer = setTimeout(() => {
                getMainCatSearch();
            }, 1000);
            return () => {
                clearTimeout(timer);
            };
        } else {
            getMainCatDetails();
        }
    }, [mainName]);
    const onKeydownMainSearch = (event) => {
        if (event.key === "Enter") {
            // console.log("Enter is pressed call search function");
            getMainCatSearch();
        }
    };
    // ---------------Topic Search------------------
    const onSubSearch = (e) => {
        setSubName(e.target.value)
    }
    const getSubCatSearch = () => {
        let request = {
            admin_id: userData.admin_id,
            token: userData.token,
            data: subName
        }
        API.post("subCategorySearch/condition", request).then(response => {
            // console.log("categoryList res", response);
            setSubCatInfo(response.data.data);
        });
    }
    useEffect(() => {
        if (subName != "") {
            const timer = setTimeout(() => {
                getSubCatSearch();
            }, 1000);
            return () => {
                clearTimeout(timer);
            };
        } else {
            getSubCatDetails();

        }
    }, [subName]);
    const onKeydownSubSearch = (event) => {
        if (event.key === "Enter") {
            // console.log("Enter is pressed call search function");
            getSubCatSearch();
        }
    };
    // ---------------Pending Search------------------
    const onPendingSearch = (e) => {
        setPendingName(e.target.value)
    }
    const getPendingCatSearch = () => {
        let request = {
            admin_id: userData.admin_id,
            token: userData.token,
            data: pendingName
        }
        API.post("pendingSearch/condition", request).then(response => {
            // console.log("categoryList res", response);
            setPendingInfo(response.data.data);
        });
    }
    useEffect(() => {
        if (pendingName != "") {
            const timer = setTimeout(() => {
                getPendingCatSearch();
            }, 1000);
            return () => {
                clearTimeout(timer);
            };
        } else {
            getPendingDetails();
        }
    }, [pendingName]);
    const onKeydownPendingSearch = (event) => {
        if (event.key === "Enter") {
            // console.log("Enter is pressed call search function");
            getPendingCatSearch();
        }
    };

    // ----------Add Cat popup-----------
    const [mainCategoryTitle, setMainCategoryTitle] = useState("");
    // const [mainSubCategoryTag, setMainSubCategoryTag] = useState("");
    const [mainCategoryTitleErr, setMainCategoryTitleErr] = useState("");
    // const [mainSubCategoryTagErr, setMainSubCategoryTagErr] = useState("");
    const [portalvalue, setPortalvalue] = useState([]);

    const handleAddShow = () => {
        setShowAdd(true);
    }
    const handleAddClose = () => {
        setMainCategoryTitleErr("");
        setMainCategoryTitle("");
        setSubCategoryTitleErr("");
        setSubCategoryTitle("");
        setSubCategoryTitle("");
        setDoc("");
        setType("");
        setShowAdd(false);
        setAddSuccFlg(true);
        setPortalvalueErr("");
    }
    const getCatContent = () => {
        setMainCatFields(true);
        setSubCatFields(false);
        setMainCategoryTitleErr("");
        setMainCategoryTitle("");
        setSubCategoryTitleErr("");
        setSubCategoryTitle("");
    }
    const handleOnchange = (val) => {
        // console.log("val", val);
        var numberArray = val.split(',').map(i => +i);
        setPortalvalue(numberArray);
    }
    // console.log("portalvalue", portalvalue);

    const handleAddMainCat = () => {

        setMainCategoryTitleErr("");
        // setMainSubCategoryTagErr("");

        if (!mainCategoryTitle) {
            setMainCategoryTitleErr("Category Title is required");
            return;
        }
        // if (!mainSubCategoryTag) {
        //     setMainSubCategoryTagErr("Sub Category Tag is required");
        //     return;
        // }
        let request = {
            admin_id: userData.admin_id,
            token: userData.token,
            title: mainCategoryTitle.replace(/\s/g, ""),
            tag: "#" + mainCategoryTitle.replace(/\s/g, ""),
            active: 1,
            createdBy: userData.admin_id,
            updatedBy: userData.admin_id,
            image: doc === "" ? "" : doc.length > 0 ? doc : [doc]
        }
        API.post("categoryAdd/add", request).then(response => {
            if (response.data.success) {
                getMainCatDetails();
                setAddSuccFlg(false);
                setAddSuccMsg(response.data.data.message);
            }
        });
    }

    // ----------Add Topic popup-----------
    const [subCategoryTitle, setSubCategoryTitle] = useState("");
    // const [subCategoryTag, setSubCategoryTag] = useState("");
    const [subCategoryTitleErr, setSubCategoryTitleErr] = useState("");
    // const [subCategoryTagErr, setSubCategoryTagErr] = useState("");
    const [portalvalueErr, setPortalvalueErr] = useState("");

    const getDropDown = () => {
        let request = {
            admin_id: userData.admin_id,
            token: userData.token
        }
        API.post("subcatdropDownList/condition", request).then(response => {
            setDropDownListInfo(response.data.data.map((data) => { return { "label": data.title, "value": data.category_id.toString() } }));
            setLoading(false);
        });
    }

    const handleAddSubCat = () => {

        setSubCategoryTitleErr("");
        // setSubCategoryTagErr("");

        if (!subCategoryTitle) {
            setSubCategoryTitleErr("Topic Title is required");
            return;
        }
        // if (!subCategoryTag) {
        //     setSubCategoryTagErr("Sub Category Tag is required");
        //     return;
        // }
        if (portalvalue.length === 0) {
            setPortalvalueErr("Linked Main Category is required");
            return;
        }
        let request = {
            admin_id: userData.admin_id,
            token: userData.token,
            title: subCategoryTitle.replace(/\s/g, ""),
            tag: "#" + subCategoryTitle.replace(/\s/g, ""),
            category_id: portalvalue,
            active: 1,
            createdBy: userData.admin_id,
            updatedBy: userData.admin_id,
            image: doc === "" ? "" : doc.length > 0 ? doc : [doc]
        }
        API.post("subCategoryAdd/add", request).then(response => {
            if (response.data.success) {
                getSubCatDetails();
                setAddSuccFlg(false);
                setAddSuccMsg(response.data.data.message);
            }
        });
    }
    const getTopicContent = () => {
        getDropDown();
        setSubCatFields(true);
        setMainCatFields(false);
        setMainCategoryTitleErr("");
        setMainCategoryTitle("");
        setSubCategoryTitleErr("");
        setSubCategoryTitle("");
    }
    // ----------Edit popup-----------
    const [showMainEdit, setShowMainEdit] = useState(false);
    const [createdDate, setCreatedDate] = useState("");
    const [mainCatTitleEdit, setMainCatTitleEdit] = useState("");
    const [mainCatView, setMainCatView] = useState("");
    const [linkedCatView, setLinkedCatView] = useState("");
    const [mainCatTitleEditErr, setMainCatTitleEditErr] = useState("");
    const [showSubEdit, setShowSubEdit] = useState(false);
    const [mainCatList, setMainCatList] = useState("");

    const handleEditMainShow = (data) => {
        // console.log("catgryy view", data);
        setCreatedDate(data.createdAt.substring(0, 10));
        setMainCatTitleEdit(data.title);
        if (showSubEdit === true) {
            setLinkedCatView(data.categoryList.map(data => data.title).join(", "));
        }
        else {
            handleEditCatList(data);
        }
        setMainCatView(data);
        setShowMainEdit(true);
    }
    const handleEditMainClose = () => {
        setMainCatTitleEditErr("");
        setDoc("");
        setType("");
        setMainName("");
        setSubName("");
        setShowMainEdit(false);
        setEditSuccFlg(true);
    }
    // -------Edit Cat------------
    const handleEditMainCat = () => {

        setMainCatTitleEditErr("");

        if (!mainCatTitleEdit) {
            setMainCatTitleEditErr("Main Category Title is required");
            return;
        }

        let request = {
            admin_id: userData.admin_id,
            token: userData.token,
            category_id: mainCatView.category_id,
            title: mainCatTitleEdit,
            tag: "#" + mainCatTitleEdit,
            active: 1,
            createdBy: userData.admin_id,
            updatedBy: userData.admin_id,
            image: doc === "" ? "" : doc.length > 0 ? doc : [doc]
        }
        API.post("categoryEdit/update", request).then(response => {
            if (response.data.success) {
                getMainCatDetails();
                setEditSuccFlg(false);
                setEditSuccMsg(response.data.data.message);
            }
        });
    }
    // -------Edit Topic------------
    const handleEditSubCat = () => {

        setMainCatTitleEditErr("");

        if (!mainCatTitleEdit) {
            setMainCatTitleEditErr("Sub Category Title is required");
            return;
        }

        let request = {
            admin_id: userData.admin_id,
            token: userData.token,
            sub_category_id: mainCatView.sub_category_id,
            title: mainCatTitleEdit,
            tag: "#" + mainCatTitleEdit,
            active: 1,
            createdBy: userData.admin_id,
            updatedBy: userData.admin_id,
            image: doc === "" ? "" : doc.length > 0 ? doc : [doc]
        }
        API.post("subCategoryEdit/update", request).then(response => {
            if (response.data.success) {
                getSubCatDetails();
                setEditSuccFlg(false);
                setEditSuccMsg(response.data.data.message);
            }
        });
    }
    const handleEditCatList = (data) => {


        let request = {
            admin_id: userData.admin_id,
            token: userData.token,
            category_id: data.category_id,

        }
        API.post("topicDetails/condition", request).then(response => {
            setMainCatList(response.data.data);
        });
    }
    // -------Delete Cat------------
    const handleDeleteMain = () => {

        let request = {
            admin_id: userData.admin_id,
            token: userData.token,
            category_id: mainCatView.category_id,
        }
        API.post("categoryDelete/update", request).then(response => {
            if (response.data.success) {
                getMainCatDetails();
                setEditSuccFlg(false);
                setEditSuccMsg(response.data.data.message);
            }
        });
    }
    const handleDeleteMainList = (data) => {

        let request = {
            admin_id: userData.admin_id,
            token: userData.token,
            sub_category_id: data.sub_category_id,
        }
        API.post("subCategoryDelete/update", request).then(response => {
            if (response.data.success === true) {
                handleEditCatList(mainCatView);
            }
        });
    }
    // -------Delete Topic------------
    const handleDeleteSub = () => {

        let request = {
            admin_id: userData.admin_id,
            token: userData.token,
            sub_category_id: mainCatView.sub_category_id,
        }
        API.post("subCategoryDelete/update", request).then(response => {
            if (response.data.success) {
                getSubCatDetails();
                setEditSuccFlg(false);
                setEditSuccMsg(response.data.data.message);
            }
        });
    }
    // -------------Pending Popup----------------
    const handlePendingShow = (data) => {
        setViewPending(data);
        getAdminEditView(data);
        setViewCreatedDate(data.createdAt.substring(0, 10));
        setShowPending(true);
        getDropDown();
        setLoading(true);
    }
    const handlePendingClose = () => {
        setPendingStatus("no");
        setPendingName("");
        setShowPending(false);
        setPenEditSuccFlg(true);
    }
    // -------------Pending Update----------------
    const getUpdatePending = () => {

        let request = {
            admin_id: userData.admin_id,
            token: userData.token,
            sub_category_id: viewPending.sub_category_id,
            status: pendingStatus === "yes" ? "Active" : "InActive",
            category_id: portalEditvalue,
            createdBy: userData.admin_id,
            updatedBy: userData.admin_id
        }
        API.post("pendingTopicEdit/update", request).then(response => {
            if (response.data.success) {
                getPendingDetails();
                setPenEditSuccFlg(false);
                setPenEditSuccMsg(response.data.data.message);
            }
        });
    }
    const getAdminEditView = (data) => {
        let request = {
            admin_id: data.admin_id,
            token: userData.token,
            sub_category_id: data.sub_category_id
        }
        API.post("pendingView/condition", request).then(response => {
            // console.log("data=======", response.data.data[0]?.categoryList.map((data) => { return { "label": data.title, "value": data.category_id.toString() } }));
            setEditPenViewInfo(response.data.data[0]?.categoryList.map((data) => { return { "label": data.title, "value": data.category_id.toString() } }));
            setTimeout(() => {
                setLoading(false);
                // console.log("useeffect")
              }, 500);
        });
    }

    const getPendingDelete = () => {

        let request = {
            admin_id: userData.admin_id,
            token: userData.token,
            sub_category_id: viewPending.sub_category_id,
        }
        API.post("pendingDelete/update", request).then(response => {
            if (response.data.success) {
                getPendingDetails();
                setPenEditSuccFlg(false);
                setPenEditSuccMsg(response.data.data.message);
            }
        });
    }
    const handleOnchangePenEdit = (val) => {
        // console.log("val", val);
        // setPortalEditvalue(val.map(data => data.value));
        var numberArray = val.split(',').map(i => +i);
        // setAdminEditPortal(val.map(data => data.value));
        setPortalEditvalue(numberArray)
    }

    return (
        <div className="wrapper">
            <SideBar sideBarOpen={isOpen} />
            <div id="content" className={!isOpen ? "" : 'active'}>
                <Header recallfunction={recallfunction} />
                <div className="main-content">
                    <div className="row">
                        <div className="common-heading-sec col-lg-12">
                            <h5 className="me-auto">Categories</h5>
                            <div className="searchAdd-fields">
                                <div className="search-bar me-2">
                                    {mainCategory === true &&
                                        <input type="search" className="form-control" placeholder="Search by Title" onKeyDown={onKeydownMainSearch}
                                            onChange={onMainSearch} />
                                    }
                                    {subCategory === true &&
                                        <input type="search" className="form-control" placeholder="Search by Title" onKeyDown={onKeydownSubSearch}
                                            onChange={onSubSearch} />
                                    }
                                    {pendingEnable === true &&
                                        <input type="search" className="form-control" placeholder="Search by Tag" onKeyDown={onKeydownPendingSearch}
                                            onChange={onPendingSearch} />
                                    }
                                </div>
                                <div className="add-btn" onClick={() => handleAddShow()}>
                                    <i className="fa-solid fa-plus me-2" />Add New Categories
                                </div>
                            </div>
                        </div>
                        <div className="category-tabs col-lg-12">
                            <ul className="nav nav-pills mb-2" id="pills-tab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className={mainCategory === true ? "nav-link active" : "nav-link"} id="pills-home-tab1" type="button"
                                        onClick={() => { setMainCategory(true); setSubCategory(false); setPendingEnable(false); setMainCatFields(true); setSubCatFields(false); setShowSubEdit(false) }} >Categories</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className={subCategory === true ? "nav-link active" : "nav-link"} id="pills-profile-tab2" type="button"
                                        onClick={() => { getSubCatDetails(); setMainCategory(false); setSubCategory(true); setPendingEnable(false); setSubCatFields(true); setMainCatFields(false); setShowSubEdit(true); getDropDown(); }} >Topics</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className={pendingEnable === true ? "nav-link active" : "nav-link"} id="pills-profile-tab3" type="button"
                                        onClick={() => { setMainCategory(false); setSubCategory(false); setPendingEnable(true) }} >Pending Topics</button>
                                </li>
                            </ul>
                        </div>
                        {loading ? <Loading /> :
                            <div className="tab-content" id="pills-tabContent">
                                {mainCategory &&
                                    <div className="col-lg-12">
                                        <div className="bg-white">
                                            <div className="categeries-box">
                                                {mainCatInfo.length > 0 ? mainCatInfo.map((mainCatInfo, index) =>
                                                    <div className="cate-outer-box clo-md-8" key={index} >
                                                        <p>{mainCatInfo.stories} Stories . {mainCatInfo.topics} Topics</p>
                                                        <div className="cate-inner-box">
                                                            <img src={mainCatInfo.image || adduser} alt="" />
                                                            <p title={mainCatInfo.title}>{mainCatInfo.title}</p>
                                                            <div className="icon-box" onClick={() => { handleEditMainShow(mainCatInfo); setShowSubEdit(false) }} >
                                                                <i className="fa-solid fa-pen"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : <p className="nodata-found categoryCenter">No Data Found</p>}
                                            </div>
                                        </div>
                                    </div>
                                }
                                {subCategory &&
                                    <div className="col-lg-12">
                                        <div className="bg-white">
                                            <div className="categeries-box" >
                                                {subCatInfo.length > 0 ? subCatInfo.map((subCatInfo, index) =>
                                                    <div className="cate-outer-box clo-md-8" key={index} >
                                                        <p>{subCatInfo.stories} Stories</p>
                                                        <div className="cate-inner-box">
                                                            <img src={subCatInfo.image || adduser} alt="" />
                                                            <p title={subCatInfo.title}>{subCatInfo.title}</p>
                                                            <div className="icon-box" onClick={() => { handleEditMainShow(subCatInfo); setShowSubEdit(true) }} >
                                                                <i className="fa-solid fa-pen"></i>
                                                            </div>
                                                        </div>
                                                        <div className="custom-toggle">
                                                            <label htmlFor={`onAir${index}`}>OnAir </label>
                                                            <div className="form-check form-switch">
                                                                <input className="form-check-input" type="checkbox" defaultChecked={subCatInfo.toggleenable === 1 ? true : false} id={`onAir${index}`} onChange={() => getOnAirActive(subCatInfo, `onAir${index}`)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : <p className="nodata-found categoryCenter">No Data Found</p>}
                                            </div>
                                        </div>
                                    </div>
                                }
                                {pendingEnable &&
                                    <div className="col-lg-12">
                                        <div className="bg-white">
                                            <div className="categeries-box">
                                                {pendingInfo.length > 0 ? pendingInfo.map((pendingInfo, index) =>
                                                    <div className="cate-outer-box clo-md-8" key={index} >
                                                        <p >{pendingInfo.stories} Stories</p>
                                                        <div className="cate-inner-box">
                                                            <img src={pendingInfo.image || adduser} alt="" />
                                                            <p title={pendingInfo.title}>{pendingInfo.title}</p>
                                                            <div className="icon-box" onClick={() => handlePendingShow(pendingInfo)} >
                                                                <i className="fa-solid fa-pen"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : <p className="nodata-found categoryCenter">No Data Found</p>}
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>

            {/* ----------------Add main and Sub popup----------------- */}
            <Modal size="wrapper modal-dialog-centered" show={showAdd} onHide={handleAddClose}>
                {addSuccFlg ? <Modal.Header>
                    <Modal.Title>
                        <h5 className="modal-title" id="exampleModalLabel">Add New Categories</h5>
                    </Modal.Title>
                </Modal.Header> : ""}
                <Modal.Body>
                    {addSuccFlg ?
                        <>
                            {loading ? <Loading /> :
                                <div className="row">
                                    <div className="col-lg-12 mb-2">
                                        <label htmlFor="id" className="mb-3">Categories Type</label>
                                        <div className="d-flex">
                                            <div className="form-check me-5">
                                                <input className="form-check-input" type="radio" id="MainCaty" checked={mainCatFields ? true : false} onChange={() => { getCatContent() }} />
                                                <label className="form-check-label" htmlFor="MainCategory">
                                                    Category
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" id="subCatey" checked={subCatFields ? true : false} onChange={() => { getTopicContent() }} />
                                                <label className="form-check-label" htmlFor="subCategory">
                                                    Topic
                                                </label>
                                            </div>

                                        </div>
                                    </div>
                                    {mainCatFields &&
                                        <>
                                            <div className="col-lg-6 mb-3 add-language">
                                                <label htmlFor="id" className="col-form-label">Category Title <span>*</span></label>
                                                <input type="text" className="form-control" placeholder="" id="date" onChange={(e) => setMainCategoryTitle(e.target.value)} />
                                                <p className="form-input-error">{mainCategoryTitleErr}</p>
                                            </div>
                                            <div className="col-lg-6 mb-3">
                                                <label htmlFor="catTag" className="col-form-label">Category Tag</label>
                                                <input type="text" className="form-control" value={mainCategoryTitle ? "#" + mainCategoryTitle : ""} id="catTag" disabled />
                                            </div>
                                        </>
                                    }
                                    {subCatFields &&
                                        <>
                                            <div className="col-lg-6 mb-3 add-language">
                                                <label htmlFor="SubCategoryTitle" className="col-form-label">Topic Title <span>*</span></label>
                                                <input type="text" className="form-control" placeholder="" id="SubCategoryTitle" onChange={(e) => setSubCategoryTitle(e.target.value)} />
                                                <p className="form-input-error">{subCategoryTitleErr}</p>
                                            </div>
                                            <div className="col-lg-6 mb-3">
                                                <label htmlFor="SubCategorTags" className="col-form-label">Topic Tags</label>
                                                <input type="text" className="form-control" value={subCategoryTitle ? "#" + subCategoryTitle : ""} id="SubCategorTags" disabled />
                                                {/* <p className="form-input-error">{subCategoryTagErr}</p> */}
                                            </div>
                                            <div className="col-lg-12 mb-3 add-language">
                                                <label htmlFor="LinkedmainCategory" className="col-form-label">Linked Category <span>*</span></label>
                                                <MultiSelect
                                                    onChange={handleOnchange}
                                                    options={dropDownListInfo}
                                                />
                                                <p className="form-input-error">{portalvalueErr}</p>
                                            </div>

                                        </>
                                    }
                                    <div className="col-lg-12 mb-3">
                                        {doc === "" ?
                                            <div className="drag-area" >
                                                <div className="icon" >{doc === "" ? <i className="fas fa-cloud-upload-alt"></i> : <img alt="" src={doc.base64} />}</div>
                                                <header>Drag & Drop to Upload File</header>
                                                <span >OR</span>
                                                <FileBase64 onDone={getFiles} name="Add" type="hidden" />
                                                <button>Browse File</button>
                                                {type === "0" ? <p className="form-input-error">Upload only Image Format </p> : ""}
                                            </div>
                                            :
                                            <div className="drag-area" >
                                                <div className="icon-edit" ><img alt="" src={doc.base64} /></div>
                                                <header>{doc.name}</header>
                                                <FileBase64 onDone={getFiles} name="Add" type="hidden" />
                                                <button className="mt-2" >Edit Browse File</button>
                                                {type === "0" ? <p className="form-input-error">Upload only Image Format </p> : ""}
                                            </div>
                                        }
                                    </div>
                                </div>
                            }
                        </>
                        :
                        <div className="alert_box">
                            <div className="icon">
                                <i className="fa-solid fa-check"></i>
                            </div>
                            <header>{addSuccMsg}</header>
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn form-submit-secbtn" onClick={handleAddClose} >Close</button>
                    {addSuccFlg ? <button type="button" className="btn form-submit-btn" onClick={mainCatFields === true ? handleAddMainCat : handleAddSubCat} >Save</button> : ""}
                </Modal.Footer>
            </Modal>

            {/* ----------------Edit main and Sub popup----------------- */}
            <Modal size={editSuccFlg ? "wrapper modal-dialog-centered modal-lg" : "wrapper modal-dialog-centered modal-md"} show={showMainEdit} onHide={handleEditMainClose}>
                {editSuccFlg ? <Modal.Header>
                    <Modal.Title>
                        <h5 className="modal-title" id="exampleModalLabel">Edit Categories</h5>
                    </Modal.Title>
                </Modal.Header> : ""}
                <Modal.Body>
                    {editSuccFlg ?
                        <div className="row">
                            <div className="col-lg-6 mb-3">
                                <label htmlFor="editId" className="col-form-label">Id</label>
                                <input type="text" className="form-control" value={showSubEdit === false ? mainCatView.category_id : mainCatView.sub_category_id} id="editId" disabled />
                            </div>
                            <div className="col-lg-6 mb-3">
                                <label htmlFor="date1" className="col-form-label">Created Date</label>
                                <input type="text" className="form-control" value={createdDate} id="date1" disabled />
                            </div>
                            <div className="col-lg-6 mb-3 add-language">
                                <label htmlFor="maintitl1" className="col-form-label">{showSubEdit === false ? "Category" : "Topic"}  Title <span>*</span></label>
                                <input type="text" defaultValue={mainCatTitleEdit} className="form-control" id="maintitl1" onChange={(e) => setMainCatTitleEdit(e.target.value)} />
                                <p className="form-input-error">{mainCatTitleEditErr}</p>
                            </div>
                            <div className="col-lg-6 mb-3">
                                <label htmlFor="tag1" className="col-form-label">Tag</label>
                                <input type="text" value={mainCatTitleEdit ? "#" + mainCatTitleEdit : mainCatTitleEdit} className="form-control" id="tag1" disabled />
                            </div>
                            {showSubEdit === true &&
                                <div className="col-lg-6 mb-3">
                                    <label htmlFor="tag4" className="col-form-label">Linked Category</label>
                                    <textarea className="form-control" value={linkedCatView} id="tag4" disabled />
                                </div>
                            }
                            <div className=" row justify-content-center">
                                <div className="col-lg-6 mb-3 ">
                                    {doc === "" && mainCatView.image === "" ?
                                        <div className="drag-area justify-content-md-center" >
                                            <div className="icon" >{doc === "" ? <i className="fas fa-cloud-upload-alt"></i> : <img alt="" src={doc.base64} />}</div>
                                            <header>Drag & Drop to Upload File</header>
                                            <span >OR</span>
                                            <FileBase64 onDone={getFiles} name="Add" type="hidden" />
                                            <button>Browse File</button>
                                            {type === "0" ? <p className="form-input-error">Upload only Image Format </p> : ""}
                                        </div>
                                        :
                                        <div className="drag-area" >
                                            <div className="icon-edit" ><img alt="" src={doc === "" ? mainCatView.image : doc.base64} /></div>
                                            <header>{doc.name}</header>
                                            <FileBase64 onDone={getFiles} name="Add" type="hidden" />
                                            <button className="mt-2" >Edit Browse File</button>
                                            {type === "0" ? <p className="form-input-error">Upload only Image Format </p> : ""}
                                        </div>
                                    }
                                </div>
                            </div>
                            <Modal.Footer>
                                <button type="button" className="btn form-submit-secbtn" onClick={handleEditMainClose} >Close</button>
                                <button type="button" className="btn form-delete-btn" onClick={showSubEdit === false ? handleDeleteMain : handleDeleteSub} >Delete</button>
                                <button type="button" className="btn form-submit-btn" onClick={showSubEdit === false ? handleEditMainCat : handleEditSubCat}>Save</button>
                            </Modal.Footer>
                            {showSubEdit === false &&
                                <>
                                    <Modal.Header>
                                        <Modal.Title>
                                            <h5 className="modal-title">Topics Details</h5>
                                        </Modal.Title>
                                    </Modal.Header>
                                    <div className="rounded table-responsive mt-3">
                                        <table className="table bg-white">
                                            <thead>
                                                <tr>
                                                    <th>Topic Id</th>
                                                    <th>Topic Title</th>
                                                    <th>Topic Tag</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {mainCatList.length > 0 ? mainCatList.map((mainCatList, index) =>
                                                    <tr key={index}>
                                                        <td>{mainCatList.sub_category_id}</td>
                                                        <td>{mainCatList.title}</td>
                                                        <td>{mainCatList.tag}</td>
                                                        <td onClick={() => handleDeleteMainList(mainCatList)}><a className="delete">Delete</a></td>
                                                    </tr>
                                                ) : <tr><td colSpan="20" className='text-center'  ><p className="nodata-found">No Data Found</p> </td></tr>}
                                            </tbody>
                                        </table>
                                    </div>
                                </>
                            }
                        </div>
                        :
                        <div className="alert_box">
                            <div className="icon">
                                <i className="fa-solid fa-check"></i>
                            </div>
                            <header>{editSuccMsg}</header>
                        </div>
                    }
                </Modal.Body>
                {!editSuccFlg ?
                    <Modal.Footer>
                        <button type="button" className="btn form-submit-secbtn" onClick={handleEditMainClose} >Close</button>
                    </Modal.Footer>
                    : ""}
            </Modal>

            {/* ----------------Pending popup----------------- */}
            <Modal size={PenEditSuccFlg ? "wrapper modal-dialog-centered modal-lg" : "wrapper modal-dialog-centered modal-md"} show={showPending} onHide={handlePendingClose}>
                {PenEditSuccFlg ? <Modal.Header>
                    <Modal.Title>
                        <h5 className="modal-title" id="exampleModalLabel">Edit Topic</h5>
                    </Modal.Title>
                </Modal.Header> : ""}
                <Modal.Body>
                    {loading ? <Loading /> :
                        <>
                            {PenEditSuccFlg ?
                                <div className="row">
                                    <div className="col-lg-6 mb-3">
                                        <label htmlFor="editId" className="col-form-label">Id</label>
                                        <input type="text" className="form-control" value={viewPending.sub_category_id} id="editId" disabled />
                                    </div>
                                    <div className="col-lg-6 mb-3">
                                        <label htmlFor="CreatedDate" className="col-form-label">Created Date</label>
                                        <input type="text" className="form-control" value={viewCreatedDate} id="CreatedDate" disabled />
                                    </div>

                                    <div className="col-lg-6 mb-3">
                                        <label htmlFor="MainCategoryTitle" className="col-form-label">Topic Title</label>
                                        <input type="text" className="form-control" value={viewPending.title} id="MainCategoryTitle" disabled />
                                    </div>

                                    <div className="col-lg-6 mb-3">
                                        <label htmlFor="Tag2" className="col-form-label">Tag</label>
                                        <input type="text" className="form-control" value={"#" + viewPending.title} id="Tag2" disabled />
                                    </div>

                                    <div className="col-lg-6 mb-3">
                                        <label htmlFor="LinkedmainCategory" className="col-form-label">Linked Category</label>
                                        <MultiSelect
                                            defaultValue={editPenViewInfo}
                                            options={dropDownListInfo}
                                            onChange={handleOnchangePenEdit}
                                        />
                                    </div>
                                    <div className="col-lg-4 mb-3">
                                        <label htmlFor="email" className="col-form-label">Status</label>
                                        <div className="form-check form-switch">
                                            <input className="form-check-input me-2" type="checkbox" id="flexSwitch" value={pendingStatus === "no" ? "yes" : "no"} checked={pendingStatus === "yes" ? true : false} onChange={(e) => setPendingStatus(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="alert_box">
                                    <div className="icon">
                                        <i className="fa-solid fa-check"></i>
                                    </div>
                                    <header>{PenEditSuccMsg}</header>
                                </div>
                            }
                        </>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn form-submit-secbtn" onClick={handlePendingClose} >Cancel</button>
                    {PenEditSuccFlg ?
                        <>
                            <button type="button" className="btn form-delete-btn" onClick={getPendingDelete} >Delete</button>
                            <button type="button" className="btn form-submit-btn" onClick={getUpdatePending} >Save</button>
                        </>
                        : ""}
                </Modal.Footer>
            </Modal>

        </div>

    );
};
export default Categories;