import React, { useState, useEffect } from 'react';
import API from '../../Services/BaseService';
import Header from "../Header/Header";
import SideBar from "../SideBar/SideBar";
import Pagination from 'reactjs-hooks-pagination';
import Loading from "../../Component/Loading/Loading";
import { useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import ls from 'local-storage';
import 'react-multiple-select-dropdown-lite/dist/index.css'
import MultiSelect from 'react-multiple-select-dropdown-lite';
// import Select from 'react-select';
// import ls from 'local-storage';


const pageLimit = 10;
const Deactivatedaccounts = () => {
  const userData = useSelector(state => state.LoginReducer.payload);
  const [accountInfo, setAccountInfo] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [loadingOne, setLoadingOne] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const recallfunction = (data) => {
    setIsOpen(data);
  }
  {/* ---------------------------View Popup----------------------------- */ }

  const [viewProfile, setViewProfile] = useState(false);
  const [userView, setUserView] = useState("");
  const [createdDate, setCreatedDate] = useState("");
  const [dateAndTime, setDateAndTime] = useState("");

  /* -------------Search ---------- */

  const [searchUserName, setSearchUserName] = useState("");

  /* -------------Filter ---------- */

  const [filterName, setFilterName] = useState("");

  /* -------------Reason Filter ---------- */

  // const [reasonFilterName, setReasonFilterName] = useState("");
  const [reasonInfo, setReasonInfo] = useState("");
  const [reason, setReason] = useState("");

  /* -------------List ---------- */


  useEffect(() => {
    getAccountDetails();
    ReasonDeactivated();
  }, []);
  const getAccountDetails = () => {
    let request = {
      admin_id: userData.admin_id,
      token: userData.token,
      language_id: ls.get("languageId") == "all" || ls.get("languageId") == "" ? "all" : ls.get("languageId").language_id
    }
    API.post("deactivatelist/condition", request).then(response => {
      // console.log("Account res", response);
      setCurrentPage(1)
      setAccountInfo(response.data.data);
      setTotalRecords(response.data.data.length);
      setLoading(false);
    });
  }

  /* -------------Search ---------- */

  const onSearch = (e) => {
    setSearchUserName(e.target.value);
    setLoading(true);
  }

  const accountSearch = () => {
    let request = {
      admin_id: userData.admin_id,
      token: userData.token,
      data: filterName,
      data1: searchUserName,
      status: reason,
      language_id: ls.get("languageId") == "all" || ls.get("languageId") == "" ? "all" : ls.get("languageId").language_id
    }

    API.post("deactivereasonFilter/condition", request).then(response => {
      // console.log("==========>", response)
      setCurrentPage(1)
      setAccountInfo(response.data.data);
      setTotalRecords(response.data.data.length);
      setLoading(false);
    });
  }
  useEffect(() => {
    if (searchUserName != "") {
      const timer = setTimeout(() => {
        accountSearch();
      }, 200);
      return () => {
        clearTimeout(timer);
      };
    } else {
      accountDropdown(filterName);
      // deactiveReasonDropdown(reason);
    }
  }, [searchUserName]);

  const onKeydownAccountSearch = (event) => {
    if (event.key === "Enter") {
      accountSearch();
    }
  };

  /* -------------Filter ---------- */

  const onDropdown = (e) => {
    setFilterName(e.target.value);
    accountDropdown(e.target.value);
    setLoading(true);
  }

  const accountDropdown = (data) => {

    let request = {
      admin_id: userData.admin_id,
      token: userData.token,
      data: data,
      data1: searchUserName,
      status: reason,
      language_id: ls.get("languageId") == "all" || ls.get("languageId") == "" ? "all" : ls.get("languageId").language_id
    }

    API.post("deactivereasonFilter/condition", request).then(response => {
      // console.log("==========>", response)
      setCurrentPage(1)
      setAccountInfo(response.data.data);
      setTotalRecords(response.data.data.length);
      setLoading(false);
    });
  }

  /* -------------Reason Filter ---------- */
  const ReasonDeactivated = () => {
    let request = {
      admin_id: userData.admin_id,
      token: userData.token
    }
    API.post("reasonList/condition", request).then(response => {
      // console.log("Reason res", response);
      setReasonInfo(response.data.data.map((data) => { return { "label": data.reason, "value": data.reason_id.toString() } }));
      setLoading(false);
    });
  }

  const handleOnchange = (val) => {
    var numberArray = val;
    setReason(numberArray);
    deactiveReasonDropdown(numberArray)
    setLoading(true);
  }

  const deactiveReasonDropdown = (data) => {

    let request = {
      admin_id: userData.admin_id,
      token: userData.token,
      data: filterName,
      data1: searchUserName,
      status: data,
      language_id: ls.get("languageId") == "all" || ls.get("languageId") == "" ? "all" : ls.get("languageId").language_id
    }

    API.post("deactivereasonFilter/condition", request).then(response => {
      // console.log("==========>", response)
      setCurrentPage(1)
      // setReasonInfo(response.data.data.map((data) => { return { "label": data.reason, "value": data.reason_id.toString() } }));
      setAccountInfo(response.data.data);
      setTotalRecords(response.data.data.length);
      setLoading(false);
      // setReasonFilterName();
    });
  }
  {/* ---------------------------View Popup----------------------------- */ }

  const handleViewProfileClose = () => {
    setViewProfile(false);
    getAccountDetails("");
  }
  const handleViewProfileShow = (data) => {
    // console.log("datechec", data);
    setViewProfile(true);
    getViewDetails(data);
    setCreatedDate(data.createdAt.substring(0, 10));
    setDateAndTime(data.createdAt.substring(12, 19));
    setLoadingOne(true);
  }


  const getViewDetails = (data) => {
    let request = {
      admin_id: userData.admin_id,
      token: userData.token,
      user_id: data.user_id
    };
    API.post("deactiveUser/condition", request).then(response => {
      // console.log('dashboard res', response);
      setUserView(response.data.data[0])
      setLoadingOne(false);
    });
  };


  return (
    <div className="wrapper">
      <SideBar sideBarOpen={isOpen} />
      <div id="content" className={!isOpen ? "" : 'active'}>
        <Header recallfunction={recallfunction} />
        <div className="main-content">
          <div className="row">
            <div className="common-heading-sec col-lg-12">
              <h5 className="me-auto">Deactivated accounts</h5>
              <div className="search-bar">
                <input type="search" className="form-control" placeholder="Search by Username" onKeyDown={onKeydownAccountSearch} onChange={onSearch} />
              </div>
              <div className="dropdown-sec">
                <select className="form-select" onChange={onDropdown} >
                  <option style={{ display: "none" }}>Select Deactivated Type</option>
                  <option value="">All</option>
                  <option value="temporary">Temporary</option>
                  <option value="permanent">Permanent</option>
                </select>
              </div>
              <div className="dropdown-sec me-2 drop_clim">
                <MultiSelect
                  onChange={handleOnchange}
                  options={reasonInfo}
                  singleSelect={true}
                  defaultValue={reason}
                />
                {/* <select className="form-select" onChange={(e) => { deactiveReasonDropdown(e.target.value); setReason(e.target.value) }}>
                  <option style={{ display: "none" }}>Select Reason</option>
                  <option value="">All</option>
                  <option value="i don't feel safe on folkus">I don&apos;t feel safe on folkus</option>
                  <option value="i don't find folkus useful">I don&apos;t find folkus useful</option>
                  <option value="i don't understand how to use folkus & I have a privacy concern">I don&apos;t understand how to use folkus & I have a privacy concern</option>
                  <option value="i have a privacy concern">I have a privacy concern</option>
                  <option value="my account was hacked">My account was hacked</option>
                </select>  */}
              </div>
            </div>

            {loading ? <Loading /> :
              <>
                <div className="rounded table-responsive">
                  <table className="table bg-white">
                    <thead>
                      <tr>
                        <th>Username</th>
                        <th>Followers</th>
                        <th>Stories</th>
                        <th>Deactivated Type</th>
                        <th>Reason</th>
                        <th>Comments</th>
                        <th>Date&Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      {accountInfo.length > 0 ? accountInfo.sort((a, b) => b.user_id - a.user_id).slice(currentPage == 1 ? 0 : (currentPage - 1) * pageLimit, (currentPage == 1 ? currentPage * pageLimit : currentPage * pageLimit))
                        .map((accountInfo, index) =>
                          <tr key={index}>
                            <td><a onClick={() => handleViewProfileShow(accountInfo)}>{accountInfo.user_name}</a></td>
                            <td>{accountInfo.followers}</td>
                            <td>{accountInfo.story_count}</td>
                            <td>{accountInfo.active === 3 ? "Temporary" : "Permanent"}</td>
                            <td>{accountInfo.reason}</td>
                            <td>{accountInfo.comments}</td>
                            <td>{accountInfo.createdAt.substring(0, 10)} & {accountInfo.createdAt.substring(12, 19)}</td>
                          </tr>
                        ) : <tr><td colSpan="8" className='text-center'><p className="nodata-found">No Data Found</p> </td></tr>}

                    </tbody>
                  </table>
                </div>
                <ul className="pagination">
                  <Pagination className=""
                    totalRecords={totalRecords}
                    pageLimit={pageLimit}
                    pageRangeDisplayed={1}
                    onChangePage={setCurrentPage}
                  />
                </ul>
              </>
            }
          </div>
        </div>
      </div>

      {/* ---------------------------View Popup----------------------------- */}


      <Modal size="wrapper modal-dialog-centered modal-lg" show={viewProfile} onHide={handleViewProfileClose}>
        <Modal.Header>
          <Modal.Title>
            <div className="modal-header text-center">
              <h5 className="modal-title" id="exampleModalLabel">View Profile</h5>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loadingOne ? <Loading /> :
            <form>
              <div className="row">
                <div className="col-lg-4 mb-2">
                  <label htmlFor="id" className="col-form-label">ID</label>
                  <input type="text" className="form-control" id="date" value={userView?.user_id}
                    disabled />
                </div>
                <div className="col-lg-4 mb-2">
                  <label htmlFor="created-date" className="col-form-label">Created Date</label>
                  <input type="date" className="form-control" id="date" value={createdDate} disabled />
                </div>
                <div className="col-lg-4 mb-2">
                  <label htmlFor="phone-number" className="col-form-label">Phone Number</label>
                  <input type="text" className="form-control" id="phone-number" value={userView?.phone_no}
                    disabled />
                </div>
                <div className="col-lg-4 mb-2">
                  <label htmlFor="name" className="col-form-label">Name</label>
                  <input type="text" className="form-control" id="name" value={userView?.name}
                    disabled />
                </div>
                <div className="col-lg-4 mb-2">
                  <label htmlFor="user-name" className="col-form-label">User Name</label>
                  <input type="text" className="form-control" id="user-name" value={userView?.user_name} disabled />
                </div>
                <div className="col-lg-4 mb-2">
                  <label htmlFor="dob" className="col-form-label">Date of Birth</label>
                  <input type="date" className="form-control" id="dob" value={userView?.dob}
                    disabled />
                </div>
                <div className="col-lg-4 mb-2">
                  <label htmlFor="gender" className="col-form-label">Gender</label>
                  <select className="form-select" id="Gender" value={userView?.gender}
                    disabled >
                    <option selected>Male</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </select>
                </div>
                <div className="col-lg-4 mb-2">
                  <label htmlFor="email" className="col-form-label">Email</label>
                  <input type="text" className="form-control" id="email" value={userView?.email}
                    disabled />
                </div>
                <div className="col-lg-4 mb-2">
                  <label htmlFor="portal" className="col-form-label">Portal</label>
                  <select className="form-select" id="portal" value={userView?.portal}
                    disabled>
                    <option selected>Tamil</option>
                    <option value="male">Tamil</option>
                    <option value="female">English</option>
                  </select>
                </div>
                <div className="col-lg-4 mb-2">
                  <label htmlFor="Followers" className="col-form-label">Followers</label>
                  <input type="text" className="form-control" id="Followers" value={userView?.followers_count}
                    disabled />
                </div>
                <div className="col-lg-4 mb-2">
                  <label htmlFor="Followed" className="col-form-label">Followed</label>
                  <input type="text" className="form-control" id="Followed" value={userView?.followed}
                    disabled />
                </div>
                <div className="col-lg-4 mb-2">
                  <label htmlFor="Stories" className="col-form-label">Stories</label>
                  <input type="text" className="form-control" id="Stories" value={userView?.story_count}
                    disabled />
                </div>
                <div className="col-lg-4 mb-2">
                  <label htmlFor="Followed" className="col-form-label">Likes</label>
                  <input type="text" className="form-control" id="Followed" value={userView?.likes}
                    disabled />
                </div>
                <div className="col-lg-4 mb-2">
                  <label htmlFor="Stories" className="col-form-label">Deactivated Date&Time</label>
                  <input type="text" className="form-control" id="Stories" value={createdDate + " & " + dateAndTime}
                    disabled />
                </div>
              </div>
            </form>
          }
        </Modal.Body>
        <Modal.Footer>
          <div className="modal-footer">
            <button type="button" className="btn form-submit-secbtn" data-bs-dismiss="modal" onClick={handleViewProfileClose}>Close</button>
          </div>
        </Modal.Footer>
      </Modal>

      <div className="modal fade" id="membership" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1"
        aria-labelledby="membershipLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg ">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="exampleModalLabel">Membership History</h5>
            </div>
            <div className="modal-body">
              <table className="table">
                <thead>
                  <tr>
                    <th>User</th>
                    <th>Type</th>
                    {/* <!-- <th>Joined By</th> --> */}
                    <th>Joined Date</th>
                    <th>Expiry Date</th>
                    <th>Current Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>@marisraw123</td>
                    <td>Premium</td>
                    <td>March 1, 2022</td>
                    <td>March 1, 2022</td>
                    <td><span className="active-color">Active</span></td>
                  </tr>
                  <tr>
                    <td>@marisraw123</td>
                    <td>Premium</td>
                    <td>March 1, 2022</td>
                    <td>March 1, 2022</td>
                    <td><span className="inactive-color">Inactive</span></td>
                  </tr>
                  <tr>
                    <td>@marisraw123</td>
                    <td>Premium</td>
                    <td>March 1, 2022</td>
                    <td>March 1, 2022</td>
                    <td><span className="inactive-color">Inactive</span></td>
                  </tr>
                  <tr>
                    <td>@marisraw123</td>
                    <td>Premium</td>
                    <td>March 1, 2022</td>
                    <td>March 1, 2022</td>
                    <td><span className="inactive-color">Inactive</span></td>
                  </tr>
                  <tr>
                    <td>@marisraw123</td>
                    <td>Premium</td>
                    <td>March 1, 2022</td>
                    <td>March 1, 2022</td>
                    <td><span className="inactive-color">Inactive</span></td>
                  </tr>
                  <tr>
                    <td>@marisraw123</td>
                    <td>Premium</td>
                    <td>March 1, 2022</td>
                    <td>March 1, 2022</td>
                    <td><span className="inactive-color">Inactive</span></td>
                  </tr>
                  <tr>
                    <td>@marisraw123</td>
                    <td>Premium</td>
                    <td>March 1, 2022</td>
                    <td>March 1, 2022</td>
                    <td><span className="inactive-color">Inactive</span></td>
                  </tr>
                  <tr>
                    <td>@marisraw123</td>
                    <td>Premium</td>
                    <td>March 1, 2022</td>
                    <td>March 1, 2022</td>
                    <td><span className="inactive-color">Inactive</span></td>
                  </tr>
                  <tr>
                    <td>@marisraw123</td>
                    <td>Premium</td>
                    <td>March 1, 2022</td>
                    <td>March 1, 2022</td>
                    <td><span className="inactive-color">Inactive</span></td>
                  </tr>
                  <tr>
                    <td>@marisraw123</td>
                    <td>Premium</td>
                    <td>March 1, 2022</td>
                    <td>March 1, 2022</td>
                    <td><span className="inactive-color">Inactive</span></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn form-submit-secbtn" data-bs-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>


  );
}
export default Deactivatedaccounts;