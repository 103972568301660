import React, { useState, } from 'react';
import API from '../../Services/BaseService';
import { useDispatch} from 'react-redux';
import LoginAction from './LoginAction';
import ls from 'local-storage';
import { useNavigate } from 'react-router-dom';
import LoginpageLogo from '../../assets/img/loginpage_logo.png';
import loginimg from '../../assets/img/login_img.jpg';
import loginlogo from '../../assets/img/login_logo.png';

const Login = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [UserName, setUserName] = useState('');
    const [Password, setPassword] = useState('');
    const [errors, setErrors] = useState({ UserName: '', Password: '' });
    const [errorMessage, setErrorMessage] = useState('');

    const handleLoginSubmit = () => {
        setErrorMessage('');
        setErrors({ UserName: '', Password: '' });
        if (!UserName) {
            setErrors({ UserName: 'Username is required', Password: '' });
            return;
        }
        else if (UserName && !new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i).test(UserName)) {
            setErrors({ UserName: 'Must match the Username format', Password: '' });
            return;
        }
        else if (!Password) {
            setErrors({ UserName: '', Password: 'Password is required' });
            return;
        }

        let request = {
            email: UserName,
            password: Password
        };
        API.post('login/login', request).then((response) => {
            // navigate("/dashboard");

            if (response.data.success == true) {
                ls.set('userDetails', response.data.data[0]);
                dispatch(LoginAction.success(response.data.data[0]));
                ls.set("languageId", "all");
                navigate('/dashboard');
            }
            else {
                // setTimeout(() => {
                setErrorMessage(response.data.error.err);
                //   }, 100);
            }
        });
    };

    return (
        <div className="container-fluid login-page">
            <div className="row">
                <div className="login-Left-sec col-lg-6">
                    <div className="row">
                        <div className="loginpage-logo">
                            <img src={LoginpageLogo} alt="logo" />
                        </div>
                        <h1>Login</h1>
                        <div className="col-lg-12">
                            <div className="mb-3">
                                <label htmlFor="Username" className="form-label">UserName</label>
                                <input type="text" className="form-control" id="Username" placeholder="Enter UserName" onChange={(e) => setUserName(e.target.value)} />
                                <p className="form-input-error">{errors.UserName}</p>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="mb-5">
                                <label htmlFor="Password" className="form-label">Password</label>
                                <input type="text" className="form-control" id="Password" placeholder="Enter Password" onChange={(e) => setPassword(e.target.value)} />
                                <p className="form-input-error">{errors.Password}</p>
                            </div>
                        </div>
                        <p className="form-input-error">{errorMessage}</p>
                        <div className="login-button">
                            <a onClick={handleLoginSubmit} type="button" className="btn btn-login">Login</a>
                        </div>
                    </div>

                </div>
                <div className="login-right-sec col-lg-6 p-0">
                    <img src={loginimg} alt="login images" className="rigt-side-img" />
                    <img src={loginlogo} alt="logo" className="rigt-side-logo" />

                </div>

            </div>

        </div>
    );
};
export default Login;