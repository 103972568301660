import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import API from '../../Services/BaseService';
import Header from "../Header/Header";
import SideBar from "../SideBar/SideBar";
import Pagination from 'reactjs-hooks-pagination';
import Loading from "../../Component/Loading/Loading";
import { Modal } from 'react-bootstrap';
import 'react-multiple-select-dropdown-lite/dist/index.css'
import MultiSelect from 'react-multiple-select-dropdown-lite';
// import ls from 'local-storage';
// import Select from 'react-select';

const pageLimit = 10;
const Admin = () => {
  const userData = useSelector(state => state.LoginReducer.payload);
  const [adminInfo, setAdminInfo] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [loadingOne, setLoadingOne] = useState(true);
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showView, setShowView] = useState(false);
  const [name, setName] = useState("");
  const [filterName, setFilterName] = useState("");
  const [addSuccFlg, setAddSuccFlg] = useState(true);
  const [addSuccMsg, setAddSuccMsg] = useState("");
  const [editSuccFlg, setEditSuccFlg] = useState(true);
  const [editSuccMsg, setEditSuccMsg] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const recallfunction = (data) => {
    setIsOpen(data);
  }
  useEffect(() => {
    getAdminDetails();
  }, []);
  const getAdminDetails = () => {
    let request = {
      admin_id: userData.admin_id,
      token: userData.token
      // language_id: ls.get("languageId") == "all" || ls.get("languageId") == "" ? "all" : ls.get("languageId").language_id
    }
    API.post("adminList/condition", request).then(response => {
      // console.log("Admin res", response);
      // console.log("===========>", response.data.data[0], response.data.data[1], response.data.data[2], response.data.data[3])
      // setCurrentPage(1)
      setAdminInfo(response.data.data);
      setTotalRecords(response.data.data.length);
      setLoading(false);
    });
  }
  // ----------Add-----------
  const handleAddClose = () => {
    setShowAdd(false);
    setUserNameError("");
    setPasswordError("");
    setPhoneNumberError("");
    setEmailError("");
    setPortalError("");
    setPortalvalue("");
    setUserName("");
    setPassword("");
    setPhoneNumber("");
    setEmail("");
    setAddSuccFlg(true);
  }
  const handleAddShow = () => {
    setShowAdd(true);
    setLoading(true);
    langAdminDetails();
  }
  // ----------Edit----------- 
  const handleEditClose = () => {
    setShowEdit(false);
    setEditSuccFlg(true);
    setAdminEditPasswordError("");
    setAdminEditPhoneNumberError("");
    setAdminEditPortalError("");
    setAdminEditPortalValue("");
    setAdminEditPortal("");
    setAdminEditPhoneNumber("");
    setAdminEditPassword("");

  }
  const handleEditShow = (data) => {
    setShowEdit(true);
    getAdminEditView(data);
    setLoadingOne(true);
    langAdminDetails();
  }
  // ----------View-----------
  const handleViewClose = () => {
    setShowView(false);
  }
  const handleViewShow = (data) => {
    setShowView(true);
    handleAdminView(data);
    setLoadingOne(true);
  }
  // ------------Search and filter----------------
  const onSearch = (e) => {
    setName(e.target.value)
    setLoading(true);
  }

  const adminInfoSearch = () => {

    let request = {
      admin_id: userData.admin_id,
      token: userData.token,
      data1: name,
      status: filterName
    }

    API.post("adminFilter/condition", request).then(response => {
      setCurrentPage(1)
      setAdminInfo(response.data.data);
      setTotalRecords(response.data.data.length);
      setLoading(false);
    });
  }

  useEffect(() => {
    // console.log("name", name);
    if (name != "") {
      // console.log("name1", name);
      const timer = setTimeout(() => {
        adminInfoSearch();
      }, 200);
      return () => {
        clearTimeout(timer);
      };
    } else {
      // console.log("use effect======>adminStatusDropdown")
      adminStatusDropdown(filterName);
    }
  }, [name]);

  const onKeydownAdminSearch = (event) => {
    if (event.key === "Enter") {
      // console.log("Enter is pressed call search function");
      adminInfoSearch();
    }
  };

  const onDropdown = (e) => {
    setFilterName(e.target.value);
    adminStatusDropdown(e.target.value);
    setLoading(true);
  }

  const adminStatusDropdown = (data) => {

    let request = {
      admin_id: userData.admin_id,
      token: userData.token,
      data1: name,
      status: data
    }

    API.post("adminFilter/condition", request).then(response => {
      // console.log("==========>", response)
      setCurrentPage(1)
      setAdminInfo(response.data.data);
      setTotalRecords(response.data.data.length);
      setLoading(false);
    });
  }

  // ================================add popup=======================================
  const [langAdminInfo, setLangAdminInfo] = useState([]);
  const [portalvalue, setPortalvalue] = useState([]);
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [userNameError, setUserNameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [portalError, setPortalError] = useState("");

  const addPhoneNumber = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setPhoneNumber(value);
  };

  const langAdminDetails = () => {
    let request = {
      admin_id: userData.admin_id,
      token: userData.token,
    }
    API.post("langaugeList/condition", request).then(response => {
      setLangAdminInfo(response.data.data.map((data) => { return { "label": data.language, "value": data.language_id.toString() } }));
      setLoading(false);

    });
  }

  const handleOnchange = (val) => {
    if (val === "") {
      // console.log("if");
      setPortalvalue([]);
    } else {
      // console.log("else");
      var numberArray = val.split(',').map(i => +i);
      setPortalvalue(numberArray);
    }
  }

  const getAddAdmin = () => {
    setUserNameError("");
    setPasswordError("");
    setPhoneNumberError("");
    setEmailError("");
    setPortalError("");

    if (!userName) {
      setUserNameError("Admin Name is required");
      return;
    }
    if (!email) {
      setEmailError("Email is required");
      return;
    }
    else if (email && !new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i).test(email)) {
      setEmailError("Email Must match the format")
      return;
    }
    if (!password) {
      setPasswordError("Password is required");
      return;
    }
    else if (password.length < 8) {
      setPasswordError("Password must have 8 characters");
      return;
    }
    if (!phoneNumber) {
      setPhoneNumberError("PhoneNumber is required");
      return;
    }
    else if (phoneNumber.length < 10) {
      setPhoneNumberError("PhoneNumber must have 10 digits");
      return;
    }
    else if (phoneNumber.length > 10) {
      setPhoneNumberError("PhoneNumber accept only 10 digits");
      return;
    }
    if (!portalvalue.length) {
      setPortalError("Portal is required");
      return;
    }

    let request = {
      admin_name: userName.replace(/^\s+|\s+$/gm, ''),
      email: email,
      password: password.replace(/^\s+|\s+$/gm, ''),
      phone_no: phoneNumber,
      admin_type_id: 1,
      active: 1,
      createdBy: userData.createdBy,
      updatedBy: userData.updatedBy,
      language_id: portalvalue,
      token: userData.token
    }
    API.post("adminAdd/add", request).then(response => {
      if (response.data.success) {
        // console.log("response", response);
        getAdminDetails();
        setAddSuccFlg(false);
        setAddSuccMsg(response.data.data.message);
      }
      else {
        setEmailError("email Already Exists")
      }
    });
  }
  // ================================Edit popup=======================================
  const [adminEditId, setAdminEditId] = useState("");
  const [adminEditCreatedDate, setAdminEditCreatedDate] = useState("");
  const [adminEditUserName, setAdminEditUserName] = useState("");
  const [adminEditPassword, setAdminEditPassword] = useState("");
  const [adminEditPhoneNumber, setAdminEditPhoneNumber] = useState("");
  const [adminEditEmail, setAdminEditEmail] = useState("");
  const [adminEditPortal, setAdminEditPortal] = useState([]);
  const [adminEditStatus, setAdminEditStatus] = useState("");
  const [adminEditPortalValue, setAdminEditPortalValue] = useState([]);

  const [adminEditPasswordError, setAdminEditPasswordError] = useState("");
  const [adminEditPhoneNumberError, setAdminEditPhoneNumberError] = useState("");
  const [adminEditPortalError, setAdminEditPortalError] = useState("");
  // const [langId, setLangId] = useState("");
  // useEffect(() => {

  // }, [adminEditPortalValue]);

  const editPhoneNumber = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setAdminEditPhoneNumber(value);
  };

  const getAdminEditView = (data) => {
    let request = {
      admin_id: data.admin_id,
      token: userData.token
    }
    API.post("adminView/condition", request).then(response => {
      setAdminEditId(response.data.data[0].admin_id);
      setAdminEditCreatedDate(response.data.data[0].createdAt.substring(0, 10));
      setAdminEditUserName(response.data.data[0].admin_name);
      setAdminEditPassword(response.data.data[0].password);
      setAdminEditPhoneNumber(response.data.data[0].phone_no);
      setAdminEditEmail(response.data.data[0].email);
      setAdminEditStatus(response.data.data[0].active == 1 ? "yes" : "no");
      setAdminEditPortalValue(response.data.data[0]?.langueList.map((data) => { return { "label": data.language, "value": data.language_id.toString() } }));
      // console.log("=======portal", response.data.data[0].langueList.map((data) => { return { "label": data.language, "value": data.language_id } }))
      // setLangId(response.data.data[0].langueList.map(data => data.language_id).join(", "));
      setAdminEditPortal(response.data.data[0]?.langueList.map((data) => data.language_id.toString()))
      setTimeout(() => {
        setLoadingOne(false);
        // console.log("useeffect")
      }, 500);

    });
  }
  // console.log("adminEditPortal", adminEditPortal)

  const handleEditSubmit = () => {
    setAdminEditPasswordError("");
    setAdminEditPhoneNumberError("");
    setAdminEditPortalError("");

    if (!adminEditPassword) {
      setAdminEditPasswordError("Password is required");
      return;
    }
    else if (adminEditPassword.length < 8) {
      setAdminEditPasswordError("Password must have 8 characters");
      return;
    }
    if (!adminEditPhoneNumber) {
      setAdminEditPhoneNumberError("PhoneNumber is required");
      return;
    }
    else if (adminEditPhoneNumber.length < 10) {
      setAdminEditPhoneNumberError("PhoneNumber must have 10 digits");
      return;
    }
    else if (adminEditPhoneNumber.length > 10) {
      setAdminEditPhoneNumberError("PhoneNumber accept only 10 digits");
      return;
    }
    if (adminEditPortal.length === 0) {
      setAdminEditPortalError("Portal is required");
      return;
    }

    // console.log("adminEditId".adminEditId);
    let request = {
      admin_id: adminEditId,
      token: userData.token,
      phone_no: adminEditPhoneNumber,
      password: adminEditPassword,
      languageList: adminEditPortal,
      status: adminEditStatus === "yes" ? "Active" : "inActive"
    };
    // console.log("adminEditPortalValue", langId);
    // return
    API.post("adminEdit/update", request).then((response) => {
      if (response.data.success) {
        getAdminDetails();
        setEditSuccFlg(false);
        setEditSuccMsg(response.data.data.message);
        setAdminEditPortalError("");
      }
    });
  }
  const handleOnchangeEdit = (val) => {
    // console.log("lang", val);
    if (val === "") {
      // console.log("if");
      setAdminEditPortal([]);
    } else {
      // console.log("else");
      var numberArray = val.split(',').map(i => +i);
      // setAdminEditPortal(val.map(data => data.value));
      setAdminEditPortal(numberArray);
    }

    //  console.log(numberArray, "numberArray");
  }

  // ================================View popup=======================================
  const [adminViewId, setAdminViewId] = useState("");
  const [adminViewUserName, setAdminViewUserName] = useState("");
  const [adminViewEmail, setAdminViewEmail] = useState("");
  const [adminViewPassword, setAdminViewPassword] = useState("");
  const [adminViewPhoneNumber, setAdminViewPhoneNumber] = useState("");
  const [adminViewCreatedDate, setAdminViewCreatedDate] = useState("");
  const [adminViewPortal, setAdminViewPortal] = useState("");
  const [adminViewStatus, setAdminViewStatus] = useState("no");
  const handleAdminView = (data) => {

    let request = {
      admin_id: data.admin_id,
      token: userData.token
    }
    API.post("adminView/condition", request).then(response => {
      setAdminViewId(response.data.data[0].admin_id);
      setAdminViewCreatedDate(response.data.data[0].createdAt.substring(0, 10));
      setAdminViewUserName(response.data.data[0].admin_name);
      setAdminViewPassword(response.data.data[0].password);
      setAdminViewPhoneNumber(response.data.data[0].phone_no);
      setAdminViewEmail(response.data.data[0].email);
      setAdminViewStatus(response.data.data[0].active == 1 ? "yes" : "no");
      setAdminViewPortal(response.data.data[0].langueList.map(data => data.language).join(", "));
      // console.log("langId response",response.data.data[0].langueList.map(data => data.language_id));

      // setTimeout(() => {
      setLoadingOne(false);
      // }, 100);
    });
  }
  return (
    <div className="wrapper">
      <SideBar sideBarOpen={isOpen} />
      <div id="content" className={!isOpen ? "" : 'active'}>
        <Header recallfunction={recallfunction} />
        <div className="main-content">
          <div className="row">
            <div className="common-heading-sec col-lg-12">
              <h5 className="me-auto">Admin</h5>
              <div className="search-bar">
                <input type="search" className="form-control" placeholder="Search by Name or Id" onKeyDown={onKeydownAdminSearch}
                  onChange={onSearch} />
              </div>
              <div className="dropdown-sec me-2">
                <select className="form-select" onChange={onDropdown}>
                  <option style={{ display: "none" }}>Select Status</option>
                  <option value="">All</option>
                  <option value="Active">Active</option>
                  <option value="inActive">InActive</option>
                </select>
              </div>
              <div onClick={() => handleAddShow()} className="add-btn"><i className="fa-solid fa-plus me-2" />Add New Admin</div>
            </div>
            {loading ? <Loading /> :
              <>
                <div className="rounded table-responsive">
                  <table className="table bg-white">
                    <thead>
                      <tr>
                        <th>#Id</th>
                        <th>UserName</th>
                        <th>Password</th>
                        <th>Phone No</th>
                        <th>Email Id</th>
                        <th>Portal</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {adminInfo.length > 0 ? adminInfo.sort((a, b) => b.admin_id - a.admin_id).slice(currentPage == 1 ? 0 : (currentPage - 1) * pageLimit, (currentPage == 1 ? currentPage * pageLimit : currentPage * pageLimit))
                        .map((adminInfo, index) =>
                          <tr key={index}>
                            <td>{adminInfo.admin_id}</td>
                            <td onClick={() => handleViewShow(adminInfo)}><a>{adminInfo.admin_name}</a></td>
                            <td>{adminInfo.password}</td>
                            <td>{adminInfo.phone_no}</td>
                            <td>{adminInfo.email}</td>
                            <td>{adminInfo.langueList.length > 0 ? adminInfo.langueList.map(data => data.language).join(", ") : ""}</td>
                            {adminInfo.active === 1 ?
                              <td><span className="active-color">Active</span></td> :
                              <td><span className="inactive-color">Inactive</span></td>
                            }
                            <td><a onClick={() => handleEditShow(adminInfo)} className="edit">Edit</a></td>
                          </tr>
                        ) : <tr><td colSpan="20" className='text-center'  ><p className="nodata-found">No Data Found</p> </td></tr>}
                    </tbody>
                  </table>
                </div>
                <ul className="pagination">
                  <Pagination className=""
                    totalRecords={totalRecords}
                    pageLimit={pageLimit}
                    pageRangeDisplayed={1}
                    onChangePage={setCurrentPage}
                  />
                </ul>
              </>
            }
          </div>
        </div>
      </div>
      {/* ----------------Add popup----------------- */}
      <Modal size={addSuccFlg ? "wrapper modal-dialog-centered modal-lg" : "wrapper modal-dialog-centered modal-md"} show={showAdd} onHide={handleAddClose}>
        {addSuccFlg ? <Modal.Header>
          <Modal.Title>
            <h5 className="modal-title" id="exampleModalLabel">Add New Admin</h5>
          </Modal.Title>
        </Modal.Header> : ""}
        <Modal.Body>
          {addSuccFlg ?
            <>
              {loading ? <Loading /> :
                <form>
                  <div className="row">
                    <div className="col-lg-4 mb-3 add-language">
                      <label htmlFor="user-name" className="col-form-label">Admin Name <span>*</span></label>
                      <input type="text" className="form-control" id="user-name" onChange={(e) => setUserName(e.target.value)} />
                      <p className="form-input-error">{userNameError}</p>
                    </div>
                    <div className="col-lg-4 mb-3 add-language">
                      <label htmlFor="email" className="col-form-label">User Name <span>*</span></label>
                      <input type="text" className="form-control" id="email" onChange={(e) => setEmail(e.target.value)} />
                      <p className="form-input-error">{emailError}</p>
                    </div>
                    <div className="col-lg-4 mb-3 add-language">
                      <label htmlFor="password" className="col-form-label">Password <span>*</span></label>
                      <input type="text" className="form-control" id="password" onChange={(e) => setPassword(e.target.value)} />
                      <p className="form-input-error">{passwordError}</p>
                    </div>
                    <div className="col-lg-4 mb-3 add-language">
                      <label htmlFor="phone-number" className="col-form-label">Phone Number <span>*</span></label>
                      <input type="text" className="form-control" id="phone-number" value={phoneNumber} onChange={addPhoneNumber} />
                      <p className="form-input-error">{phoneNumberError}</p>
                    </div>
                    <div className="col-lg-8 mb-3 add-language">
                      <label htmlFor="portal" multiple className="col-form-label">Portal <span>*</span></label>
                      <MultiSelect
                        onChange={handleOnchange}
                        options={langAdminInfo}
                      />
                      <p className="form-input-error">{portalError}</p>
                    </div>
                  </div>
                </form>
              }
            </>
            :
            <div className="alert_box">
              <div className="icon">
                <i className="fa-solid fa-check"></i>
              </div>
              <header>{addSuccMsg}</header>
            </div>
          }
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn form-submit-secbtn" onClick={handleAddClose} >Close</button>
          {addSuccFlg ? <button type="button" className="btn form-submit-btn" onClick={getAddAdmin} >Submit</button> : ""}
        </Modal.Footer>
      </Modal>
      {/* ------------------Edit popup-------------------- */}
      <Modal size={editSuccFlg ? "wrapper modal-dialog-centered modal-md" : "wrapper modal-dialog-centered modal-md"} show={showEdit} onHide={handleEditClose}>
        {editSuccFlg ? <Modal.Header>
          <Modal.Title>
            <h5 className="modal-title" id="exampleModalLabel">Edit Admin</h5>
          </Modal.Title>
        </Modal.Header> : ""}
        <Modal.Body>
          {editSuccFlg ?
            <>
              {loadingOne ? <Loading /> :
                <form>
                  <div className="row">
                    <div className="col-lg-4 mb-3">
                      <label htmlFor="#id" className="col-form-label">ID</label>
                      <input type="text" className="form-control" value={adminEditId} id="id" disabled />
                    </div>
                    <div className="col-lg-4 mb-3">
                      <label htmlFor="user-name" className="col-form-label">Admin Name</label>
                      <input type="text" className="form-control" value={adminEditUserName} id="user-name" disabled />
                    </div>
                    <div className="col-lg-4 mb-3">
                      <label htmlFor="email" className="col-form-label">User Name</label>
                      <input type="text" value={adminEditEmail} className="form-control" id="email" disabled />
                    </div>
                    <div className="col-lg-4 mb-3  add-language">
                      <label htmlFor="password" className="col-form-label">Password <span>*</span></label>
                      <input type="text" defaultValue={adminEditPassword} className="form-control" id="text" onChange={(e) => setAdminEditPassword(e.target.value)} />
                      <p className="form-input-error">{adminEditPasswordError}</p>
                    </div>
                    <div className="col-lg-4 mb-3  add-language">
                      <label htmlFor="phone-number" className="col-form-label">Phone Number <span>*</span></label>
                      <input type="text" defaultValue={adminEditPhoneNumber} className="form-control" id="phone-number" onChange={editPhoneNumber} />
                      <p className="form-input-error">{adminEditPhoneNumberError}</p>
                    </div>
                    <div className="col-lg-4 mb-3">
                      <label className="col-form-label">Created Date</label>
                      <input type="date" value={adminEditCreatedDate} className="form-control" id="date" disabled />
                    </div>
                    <div className="col-lg-8 mb-3  add-language">
                      <label htmlFor="portal" className="col-form-label">Portal <span>*</span></label>
                      {/* <textarea value={adminEditPortalValue} className="form-control" id="email" /> */}
                      <MultiSelect
                        onChange={handleOnchangeEdit}
                        options={langAdminInfo}
                        defaultValue={adminEditPortalValue}
                      />
                      <p className="form-input-error">{adminEditPortalError}</p>
                      {/* <Select
                        placeholder="Select"
                        closeMenuOnSelect={false}
                        defaultValue={adminEditPortalValue}
                        isMulti={true}
                        options={langAdminInfo}
                        onChange={handleOnchangeEdit}
                      /> */}
                      {/* <p>{adminEditPortalValue[0]?.label}</p> */}

                      {/* <Select isMulti
                  closeMenuOnSelect={false}
                    options={langAdminInfo}
                    defaultValue={adminEditPortal}
                  /> */}
                    </div>
                    <div className="col-lg-4 mb-3">
                      <label htmlFor="email" className="col-form-label">Status</label>
                      <div className="form-check form-switch">
                        <input className="form-check-input me-2" type="checkbox" id="flexSwitch" value={adminEditStatus === "no" ? "yes" : "no"} checked={adminEditStatus === "yes" ? true : false} onChange={(e) => setAdminEditStatus(e.target.value)} />
                      </div>
                    </div>
                  </div>
                </form>
              }
            </>
            :
            <div className="alert_box">
              <div className="icon">
                <i className="fa-solid fa-check"></i>
              </div>
              <header>{editSuccMsg}</header>
            </div>
          }
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn form-submit-secbtn" onClick={handleEditClose} >Close</button>
          {editSuccFlg ? <button type="button" className="btn form-submit-btn" onClick={handleEditSubmit} >Submit</button> : ""}
        </Modal.Footer>
      </Modal>
      {/* ------------------View popup-------------------- */}
      <Modal size="wrapper modal-dialog-centered modal-lg" show={showView} onHide={handleViewClose}>
        <Modal.Header>
          <Modal.Title>
            <h5 className="modal-title" id="exampleModalLabel">View Admin</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loadingOne ? <Loading /> :
            <form>
              <div className="row">
                <div className="col-lg-4 mb-3">
                  <label htmlFor="#id" className="col-form-label">ID</label>
                  <input type="text" className="form-control" value={adminViewId} id="id" disabled />
                </div>
                <div className="col-lg-4 mb-3">
                  <label className="col-form-label">Created Date</label>
                  <input type="date" value={adminViewCreatedDate} className="form-control" id="date" disabled />
                </div>
                {/* <div className="col-lg-4 mb-3">
                    <label htmlFor="name" className="col-form-label">Name</label>
                    <input type="text" className="form-control" value="Marisraw" id="name" disabled />
                </div> */}
                <div className="col-lg-4 mb-3">
                  <label htmlFor="user-name" className="col-form-label">User Name</label>
                  <input type="text" className="form-control" value={adminViewUserName} id="user-name" disabled />
                </div>
                <div className="col-lg-4 mb-3">
                  <label htmlFor="password" className="col-form-label">Password</label>
                  <input type="text" value={adminViewPassword} className="form-control" id="text" disabled />
                </div>
                <div className="col-lg-4 mb-3">
                  <label htmlFor="phone-number" className="col-form-label">Phone Number</label>
                  <input type="number" value={adminViewPhoneNumber} className="form-control" id="phone-number" disabled />
                </div>
                <div className="col-lg-4 mb-3">
                  <label htmlFor="email" className="col-form-label">Email</label>
                  <input type="text" value={adminViewEmail} className="form-control" id="email" disabled />
                </div>
                <div className="col-lg-4 mb-3">
                  <label htmlFor="portal" className="col-form-label">Portal</label>
                  <textarea value={adminViewPortal} className="form-control" id="email" disabled />


                  {/* <select className="form-select" id="portal" aria-label="Default select example" disabled>
                  <option>{adminViewPortal}</option>
                </select> */}
                </div>
                <div className="col-lg-4 mb-3">
                  <label htmlFor="email" className="col-form-label">Status</label>
                  <div className="form-check form-switch">
                    <input className="form-check-input me-2" type="checkbox" id="flexSwitchCheckChecked" value={adminViewStatus === "no" ? "yes" : "no"} checked={adminViewStatus === "yes" ? true : false} />
                  </div>
                </div>

              </div>
            </form>
          }
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn form-submit-secbtn" onClick={handleViewClose} >Close</button>
        </Modal.Footer>
      </Modal>
    </div>
  );

}
export default Admin;