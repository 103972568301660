import ls from 'local-storage';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Pagination from 'reactjs-hooks-pagination';
import adduser from '../../assets/img/adduser.jpg';
import Loading from "../../Component/Loading/Loading";
import API from '../../Services/BaseService';
import Header from "../Header/Header";
import SideBar from "../SideBar/SideBar";
// import AudioControls from "./AudioControls";
import 'react-multiple-select-dropdown-lite/dist/index.css';

const pageLimit = 10;
const Stories = () => {
  const userData = useSelector(state => state.LoginReducer.payload);
  const [storyInfo, setStoryInfo] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [editSuccFlg, setEditSuccFlg] = useState(true);
  const [editSuccMsg, setEditSuccMsg] = useState("");
  const [loading, setLoading] = useState(true);
  const [loadingOne, setLoadingOne] = useState(true);
  const [isOpen, setIsOpen] = useState(false);


  //============================StatusDropdown===================================//

  const recallfunction = (data) => {
    setIsOpen(data);
  }
  useEffect(() => {
    getStoryDetails();
  }, []);
  const getStoryDetails = () => {
    let request = {
      admin_id: userData.admin_id,
      language_id: ls.get("languageId") == "all" || ls.get("languageId") == "" ? "all" : ls.get("languageId").language_id,
      token: userData.token
    }
    API.post("storiesList/condition", request).then(response => {
      // console.log("Story res", response);
      // setCurrentPage(1)
      setStoryInfo(response.data.data);
      setTotalRecords(response.data.data.length);
      setLoading(false);
    });
  }

  // ------------Search----------------
  const [name, setName] = useState("");
  const [statusName] = useState("");

  const onSearch = (e) => {
    setName(e.target.value)
    setLoading(true);
  }

  const storyInfoSearch = () => {

    let request = {
      admin_id: userData.admin_id,
      token: userData.token,
      data: name,
      status2: statusName,
      language_id: ls.get("languageId") == "all" || ls.get("languageId") == "" ? "all" : ls.get("languageId").language_id
    }

    API.post("storiesFilter/condition", request).then(response => {
      // console.log("admin Details response", response.data.data)
      setCurrentPage(1)
      setStoryInfo(response.data.data);
      setTotalRecords(response.data.data.length);
      setLoading(false);
    });
  }

  useEffect(() => {
    if (name != "") {
      const timer = setTimeout(() => {
        storyInfoSearch();
      }, 200);
      return () => {
        clearTimeout(timer);
      };
    } else {
      getStatusDropdown(statusName);
    }
  }, [name]);

  const onKeydownSearch = (event) => {
    if (event.key === "Enter") {
      // console.log("Enter is pressed call search function");
      setCurrentPage(1)
      storyInfoSearch();
    }
  };


  // const onDropdown = (e) => {
  //   setStatusName(e.target.value);
  //   getStatusDropdown(e.target.value);
  //   setLoading(true);
  // }

  const getStatusDropdown = (data) => {

    let request = {
      admin_id: userData.admin_id,
      token: userData.token,
      data: name,
      status2: data,
      language_id: ls.get("languageId") == "all" || ls.get("languageId") == "" ? "all" : ls.get("languageId").language_id
    }

    API.post("storiesFilter/condition", request).then(response => {
      // console.log("admin List response", response.data.data)
      setCurrentPage(1)
      setStoryInfo(response.data.data);
      setTotalRecords(response.data.data.length);
      setLoading(false);
    });
  }

 
  // ---------------Edit Popup------------
  const [storyViewInfo, setStoryViewInfo] = useState("");
  const [storyEdit, setStoryEdit] = useState("");
  const [songs, setSongs] = useState("");
  const [images, setImages] = useState([]);
  const [captions, setCaptions] = useState([]);
  const [storyStatus, setStoryStatus] = useState("");
  const [reating, setReating] = useState("");
  const [editorChoice, setEditorChoice] = useState("");
  const [notification, setNotification] = useState("no");
  const [playFlag, setPlayFlag] = useState(false);


  useEffect(() => {
    if (playFlag == true) {
      const timerId = setInterval(() => {
      }, 30000);

      return () => clearInterval(timerId);
    }


  }, [playFlag]);
  // console.log(playFlag,"play");

  const storyEditShow = (data) => {
    getViewStory(data);
    setStoryEdit(true);
    setLoadingOne(true);
    setSeekValue(0);
    setShowPlayPause(false);
    audioPlayer.current = ""
    setCurrentTime(0);
    setCurrentRunMintTime(0);
    setCurrentRunSecTime(0);
  }
  const storyEditClose = () => {
    setStoryEdit(false);
    setEditSuccFlg(true);
    setSeekValue(0);
    setShowPlayPause(false);
    audioPlayer.current = ""
    setCurrentTime(0);
    setCurrentRunMintTime(0);
    setCurrentRunSecTime(0);
    setNotification("no");
  }
  const getViewStory = (data) => {

    let request = {
      admin_id: userData.admin_id,
      token: userData.token,
      story_id: data.story_id,
    }

    API.post("storybyId/condition", request).then(response => {
      // console.log("storybyId response", response.data.data)
      setStoryViewInfo(response.data.data[0]);
      setStoryStatus(response.data.data[0].status);
      setSongs(response.data.data[0].stories);
      setReating(response.data.data[0].quality ||'');
      setEditorChoice(response.data.data[0].editor_choice ||'');
      setNotification(response.data.data[0].notification);
      setImages(response.data.data[0]?.story_image);
      setCaptions(response.data.data[0]?.caption);
      // setImages(response.data.data[0]?.imageList.map((data) => data.image_id.toString()));0   
      // console.log("song", response.data.data[0].stories.duration);
      setLoadingOne(false);
    });
  }
  console.log("images", images);
  const getUpdateStory = () => {

    let request = {
      admin_id: userData.admin_id,
      token: userData.token,
      status: storyStatus,
      story_id: storyViewInfo.story_id,
      quality: reating,
      editor_choice: editorChoice,
      notification: notification === "yes" ? "yes" : "no"
    }

    API.post("storiesEdit/update", request).then(response => {
      if (response.data.success) {
        setTimeout(function(){
          getStoryDetails();
          setEditSuccFlg(false);
          setEditSuccMsg(response.data.data.message);
        },100)
      }
    });
  }
  // const getAudio = () => {
  //   var myAudio = document.getElementById("audioval");
  //   if (myAudio.paused) {
  //     myAudio.play();
  //   }
  //   else {
  //     myAudio.pause();
  //   }
  // }
  // ==============================================

  const audioPlayer = React.useRef();
  const [currentTime, setCurrentTime] = useState(0);
  const [currentRunMintTime, setCurrentRunMintTime] = useState(0);
  const [seekValue, setSeekValue] = useState(0);
  const [showPlayPause, setShowPlayPause] = useState(false);
  const [currentRunSecTime, setCurrentRunSecTime] = useState(0);

  const fwrd = () => {
    const seekto = audioPlayer.current.currentTime + 10;
    // console.log("seekto fwrd", seekto);
    audioPlayer.current.currentTime = seekto;
    // setSeekValue(seekto);
  };
  const bwrd = () => {
    const seekto = audioPlayer.current.currentTime - 10;
    // console.log("seekto bbbwrd", seekto);
    audioPlayer.current.currentTime = seekto;
    // setSeekValue(seekto);
  };
  const play = () => {
    audioPlayer.current.play();
    setPlayFlag(true)
  };

  const pause = () => {
    audioPlayer.current.pause();
    setPlayFlag(false)
  };

  const onPlaying = () => {
    setCurrentTime(audioPlayer.current.duration);
    const runTime = audioPlayer.current.currentTime;
    const mints = Math.floor(runTime % 3600 / 60);
    const sec = Math.floor(runTime % 3600 % 60);
    setCurrentRunMintTime(mints);
    setCurrentRunSecTime(sec);
    setSeekValue(
      (audioPlayer.current.currentTime / audioPlayer.current.duration) * 100 || 0
    );
  };
  // useEffect(() => {
  //   onPlaying()
  //   pause()

  // }, []);

  const testingmints = Math.floor(currentTime % 3600 / 60);
  const testingSec = Math.floor(currentTime % 3600 % 60);

  return (
    <div className="wrapper">
      <SideBar sideBarOpen={isOpen} />
      <div id="content" className={!isOpen ? "" : 'active'}>
        <Header recallfunction={recallfunction} />
        <div className="main-content">
          <div className="row">
            <div className="common-heading-sec col-lg-12">
              <h5 className="me-auto">Story</h5>
              <div className="search-bar">
                <input type="search" className="form-control" placeholder="Search by Title" onKeyDown={onKeydownSearch}
                  onChange={onSearch} />
              </div>
              {/* <div className="dropdown-sec me-2">
                <select className="form-select" onChange={onDropdown}>
                  <option style={{ display: "none" }}>Select Status</option>
                  <option value="">All</option>
                  <option value="approve">Approve</option>
                  <option value="reject">Reject</option>
                  <option value="pending">Pending</option>
                </select>
              </div>
              */}
            </div>
            {loading ? <Loading /> :
              <>
                <div className="rounded table-responsive">
                  <table className="table bg-white">
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Categories</th>
                        <th>Topics</th>
                        <th>Editor Choice</th>
                        <th>Notify</th>
                        {/* <th>Type</th> */}
                        <th>Play</th>
                        {/* <th>Status </th> */}
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {storyInfo.length > 0 ? storyInfo.sort((a, b) => b.active ===1?1:-1).slice(currentPage == 1 ? 0 : (currentPage - 1) * pageLimit, (currentPage == 1 ? currentPage * pageLimit : currentPage * pageLimit))
                        .map((storyInfo, index) =>
                          <tr key={index}>
                            <td className="story-title">{storyInfo.caption}</td>
                            {/* <td className="story-title">{storyInfo.title}  {storyInfo.story_id}</td> */}
                            <td>{storyInfo.tag}</td>
                            <td>{storyInfo.sub_tag}</td>
                            <td>{storyInfo.editor_choice}</td>
                            <td>{storyInfo.notification}</td>
                            {/* <td>{storyInfo.type}</td> */}
                            <td>{storyInfo.plays}</td>
                            {/* {storyInfo.active === 1 ?
                              <td><span className="active-color">Suggested</span></td> :
                              storyInfo.active === 4 ?
                                <td><span className="inactive-color">Reject</span></td> :
                                <td><span className="pending-color">Pending</span></td>
                            } */}
                            <td><a className="edit" onClick={() => storyEditShow(storyInfo)}>Edit</a></td>
                          </tr>
                        ) : <tr><td colSpan="20" className='text-center'><p className="nodata-found">No Data Found</p> </td></tr>}
                    </tbody>
                  </table>
                </div>
                <ul className="pagination">
                  <Pagination className=""
                    totalRecords={totalRecords}
                    pageLimit={pageLimit}
                    pageRangeDisplayed={1}
                    onChangePage={setCurrentPage}
                  />
                </ul>
              </>
            }

          </div>
        </div>
      </div>
      {/* ---------------------------Edit Popup----------------------------------- */}
      <Modal size={editSuccFlg ? "wrapper modal-dialog-centered modal-lg" : "wrapper modal-dialog-centered modal-md"} show={storyEdit} onHide={storyEditClose}>
        {editSuccFlg ? <Modal.Header>
          <Modal.Title>
            <h5 className="modal-title" id="exampleModalLabel">Story</h5>
          </Modal.Title>
        </Modal.Header> : ""}
        <Modal.Body>
          {editSuccFlg ?
            <>
              {loadingOne ? <Loading /> :
                <>
                  <div className="user-upload-btn-wrapper">
                    <img
                      src={images ? images : adduser}
                    />
                  </div>
                  <div className="modal-body">
                    <p className="caption-center">{captions}</p>
                    {/* <audio controls id="audioval" onClick={getAudio}>
                      <source src={storyViewInfo.stories} type="audio/mp3" />
                    </audio> */}

                    <div className="story-edit">
                      <audio
                        src={songs}
                        ref={audioPlayer}
                        onTimeUpdate={onPlaying}
                      >
                      </audio>
                      <div className="audio-time">
                        <div className="audio-str-time">
                          {currentRunMintTime}:{currentRunSecTime}
                        </div>
                        <div className="audio-end-time">
                          {testingmints}:{testingSec}
                        </div>
                      </div>
                      <div className="range-slider">
                        <div className="slider-container">
                          <span className="bar"><span className="fill"></span></span>
                          <input
                            className="slider"
                            type="range"
                            min="0"
                            max="100"
                            step="0.5"
                            value={seekValue}
                            onChange={(e) => {
                              const seekto = audioPlayer.current.duration * (+e.target.value / 100);
                              // console.log("seekto==",(+seekValue / 100));
                              audioPlayer.current.currentTime = seekto;
                              setSeekValue(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div >
                        <div className="controlls_btns">
                          <button id="backward_btn" onClick={bwrd} ><i className="fa-solid fa-backward" ></i></button>
                          {showPlayPause === false ?
                            <button id="play_pause_btn" onClick={() => { play(); setShowPlayPause(true); }} ><i className="fa-solid fa-play" ></i></button> :
                            <button id="play_pause_btn" onClick={() => { pause(); setShowPlayPause(false); }} ><i className="fa-solid fa-pause" ></i></button>
                          }
                          <button id="forward_btn" onClick={fwrd} ><i className="fa-solid fa-forward" ></i></button>
                        </div>
                      </div>
                    </div>

                    <form>
                      <div className="row">
                        <div className="col-lg-4 mb-3">
                          <label htmlFor="main-category" className="col-form-label">Category</label>
                          <input className="form-control" type="text" id="main-category" value={storyViewInfo.tag} disabled />
                        </div>
                        <div className="col-lg-4 mb-3">
                          <label htmlFor="sub-category" className="col-form-label">Topic</label>
                          <input className="form-control" type="text" id="sub-category" value={storyViewInfo.sub_tag} disabled />
                        </div>
                        <div className="col-lg-4 mb-3">
                          <label htmlFor="content-type" className="col-form-label">Editor Choice</label>
                          <select className="form-select" value={editorChoice} id="content-type"  onChange={(e) => setEditorChoice(e.target.value)}>
                            <option value="">Select</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                          </select>
                        </div>
                        <div className="col-lg-4 mb-3">
                          <label htmlFor="portal" className="col-form-label">Portal</label>
                          <input className="form-control" type="text" id="portal" value={storyViewInfo.language} disabled />
                        </div>
                        {/* <div className="col-lg-4 mb-3">
                          <label htmlFor="status" className="col-form-label">Status</label>
                          <select className="form-select" id="content-type" onClick={(e) => setStoryStatus(e.target.value)}  >
                            <option style={{ display: "none" }}>{storyStatus}</option>
                            <option value="Approve">Suggested</option>
                            <option value="Reject">Reject</option>
                            <option value="Pending">Pending</option>
                          </select>
                        </div> */}
                      </div>
                      <div className="form-check ">
                        <input className="form-check-input" type="checkbox" value={notification === "no" || notification === null ? "yes" : "no"} checked={notification == "yes" ? true : false} onChange={(e) => setNotification(e.target.value)} />
                        <label className="form-check-label" htmlFor="flexCheckDefault">
                          Recommended for Notification
                        </label>
                      </div>
                    </form>
                  </div>
                </>
              }
            </>
            :
            <div className="alert_box">
              <div className="icon">
                <i className="fa-solid fa-check"></i>
              </div>
              <header>{editSuccMsg}</header>
            </div>
          }
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn form-submit-secbtn" onClick={storyEditClose} >Close</button>
          {editSuccFlg ? <button type="button" className="btn form-submit-btn" onClick={getUpdateStory} >Save</button> : ""}
        </Modal.Footer>
      </Modal>
    </div>
  );
}
export default Stories;