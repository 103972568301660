import ls from 'local-storage';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import MultiSelect from 'react-multiple-select-dropdown-lite';
import 'react-multiple-select-dropdown-lite/dist/index.css';
import { useSelector } from 'react-redux';
import Pagination from 'reactjs-hooks-pagination';
import Loading from "../../Component/Loading/Loading";
import API from '../../Services/BaseService';
import Header from "../Header/Header";
import SideBar from "../SideBar/SideBar";

const pageLimit = 10;
const ReportedProfile = () => {
  const userData = useSelector(state => {
    return state.LoginReducer.payload;
  });
  const [editSuccMsg, setEditSuccMsg] = useState("");
  const [claimInfo, setClaimInfo] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [loadingOne, setLoadingOne] = useState(true);
  const [editShow, setEditShow] = useState(false);
  const [flagOpen, setFlagOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [popupFlag, setpopupFlag] = useState(false);
  const [showConfirmation,setShowConfirmation]=useState(false);

  // ------------Search----------------
  const [name, setName] = useState("");

  // =========================StatusDropdown=========================== //
  const [filterName, setFilterName] = useState("");
  const [statusList, setStatusList] = useState([]);


  const recallfunction = (data) => {
    setIsOpen(data);
  }
  const toggleOpen = () => {
    setFlagOpen(!flagOpen);
  }
  useEffect(() => {
    getClaimDetails();
    statusDetails();
  }, []);
  const getClaimDetails = () => {
    let request = {
      admin_id: userData.admin_id,
      token: userData.token,
      language_id: ls.get("languageId") == "all" || ls.get("languageId") == "" ? "all" : ls.get("languageId").language_id
    }
    API.post("adminApp/reported/profile", request).then(response => {
      setClaimInfo(response.data.data);
      setTotalRecords(response.data.data.length);
      setLoading(false);
    });
  }

  // ------------Search----------------

  const onSearch = (e) => {
    setName(e.target.value)
    setLoading(true);
  }

  const claimInfoSearch = () => {
    let request = {
      admin_id: userData.admin_id,
      token: userData.token,
      data: name,
      status: filterName,
      language_id: ls.get("languageId") == "all" || ls.get("languageId") == "" ? "all" : ls.get("languageId").language_id
    }

    API.post("adminApp/reported/profile", request).then(response => {
      // console.log("admin Details response", response.data.data)
      setCurrentPage(1)
      setClaimInfo(response.data.data);
      setTotalRecords(response.data.data.length);
      setLoading(false);
    });
  }

  useEffect(() => {
    if (name != "") {
      const timer = setTimeout(() => {
        claimInfoSearch();
      }, 200);
      return () => {
        clearTimeout(timer);
      };
    } else {
      claimStatusDropdown(filterName);
    }
  }, [name]);

  const onKeydownClaimSearch = (event) => {
    if (event.key === "Enter") {
      // console.log("Enter is pressed call search function");
      claimInfoSearch();
    }
  };

  // =========================StatusDropdown=========================== //
  const statusDetails = () => {
    let request = {
      admin_id: userData.admin_id,
      token: userData.token
    }
    API.post("adminApp/reported/status/", request).then(response => {
      // console.log("Reason res", response);
      setStatusList(response.data.data.map((data) => { return { "label": data.name, "value": data.code } }));
      setLoading(false);
    });
  }

  const handleOnchange = (val) => {
    var numberArray = val;
    setFilterName(numberArray);
    claimStatusDropdown(numberArray)
    setLoading(true);
  }

  const updateProfileData=(event)=>{
      setReportedProfileData({
        ...reportedProfileData,
        status:event.target.value
      })
  }

  const claimStatusDropdown = (data) => {

    let request = {
      admin_id: userData.admin_id,
      token: userData.token,
      data: name,
      status: data,
      language_id: ls.get("languageId") == "all" || ls.get("languageId") == "" ? "all" : ls.get("languageId").language_id
    }

    API.post("adminApp/reported/profile", request).then(response => {
      // console.log("admin List response", response.data.data)
      setCurrentPage(1)
      setClaimInfo(response.data.data);
      setTotalRecords(response.data.data.length);
      setLoading(false);
    });
  }

  // -----------------View popup---------------
  const [claimUserView, setClaimUserView] = useState(false);
  const [claimViewData, setClaimViewData] = useState("");

  const handleClaimViewShow = (data) => {
    getClaimUserViewDetails(data);
    setClaimUserView(true);
    setLoadingOne(true);
  }
  const handleClaimViewClose = () => {
    setClaimUserView(false);
  }
  const getClaimUserViewDetails = (data) => {
    let request = {
      admin_id: userData.admin_id,
      token: userData.token,
      user_id: data.user_id
    };
    API.post("userbyId/condition", request).then(response => {
      setClaimViewData(response.data.data[0] || {});
      setLoadingOne(false);
    });
  }

  {/* ---------------------Edit View--------------------------- */ }


  const getClaimEditDetails = (data) => {
    let request = {
      admin_id: userData.admin_id,
      token: userData.token,
      id: data.id
    };
    API.post("adminApp/reported/profile/" + request.id, request).then(response => {
      // console.log('dashboard res', response);
      setReportedProfileData(response.data.data[0] || {});
      setLoadingOne(false);
    });
  };
  {/* ---------------------Edit Popup--------------------------- */ }

  const [reportedProfileData, setReportedProfileData] = useState({});

  const handleEditClose = () => {
    setEditShow(false);
    setShowConfirmation(false);
    setReportedProfileData({});
  }

  const handleEditShow = (data) => {
    setEditShow(true);
    getClaimEditDetails(data);
    setLoadingOne(true);
    setpopupFlag(false)
  }


  const handleEditSubmit = () => {
    let request = {
      admin_id: userData.admin_id,
      token: userData.token,
      id:reportedProfileData.id,
      status:reportedProfileData.status
    };
    API.post("adminApp/reported/profile/update", request).then(response => {
      handleEditClose(true);
      setShowConfirmation(true);
      getClaimDetails();
      setEditSuccMsg(response.data.message);
    });
  }


  return (
    <div className="wrapper">
      <SideBar sideBarOpen={isOpen} />
      <div id="content" className={!isOpen ? "" : 'active'}>
        <Header recallfunction={recallfunction} />
        <div className="main-content" onClick={flagOpen === false ? () => { } : toggleOpen} >
          <div className="row">
            <div className="common-heading-sec col-lg-12">
              <h5 className="me-auto">Reported Profile</h5>
              <div className="search-bar">
                <input type="search" className="form-control" placeholder="Search by Username" onKeyDown={onKeydownClaimSearch}
                  onChange={onSearch} />
              </div>
              <div className="dropdown-sec me-2 drop_clim">
                <MultiSelect
                  onChange={handleOnchange}
                  options={statusList}
                  singleSelect={true}
                  defaultValue={filterName}
                />
              </div>
            </div>
            {loading ? <Loading /> :
              <>
                <div className="rounded table-responsive">
                  <table className="table bg-white">
                    <thead>
                      <tr>
                        <th>Reported By</th>
                        <th>Reported Profile</th>
                        <th>Reason</th>
                        <th>Message</th>
                        <th>+ Reported</th>
                        <th>+ Blocked</th>
                        <th>Punished</th>
                        <th>Apeal</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>

                      {claimInfo.length > 0 ? claimInfo.sort((a, b) => b.claim_id - a.claim_id).slice(currentPage == 1 ? 0 : (currentPage - 1) * pageLimit, (currentPage == 1 ? currentPage * pageLimit : currentPage * pageLimit))
                        .map((profileInfo, index) =>
                          <tr key={index}>
                            <td><a onClick={() => handleClaimViewShow({ user_id: profileInfo.createdBy })}>{profileInfo.reported_name}</a></td>
                            <td><a onClick={() => handleClaimViewShow({ user_id: profileInfo.profile_id })}>{profileInfo.profile_name}</a></td>
                            <td>{profileInfo.reason_name}</td>
                            <td>{profileInfo.message}</td>
                            <td>{profileInfo.reported_count}</td>
                            <td>{profileInfo.blocked_count}</td>
                            <td>{profileInfo.punished}</td>
                            <td>{profileInfo.apeal}</td>
                            <td><span className="none-color">{profileInfo.status_name}</span></td>
                            <td><a className="edit" onClick={() => handleEditShow(profileInfo)}>Edit</a></td>
                          </tr>
                        ) : <tr><td colSpan="20" className='text-center'><p className="nodata-found">No Data Found</p> </td></tr>}


                    </tbody>
                  </table>
                </div>
                <ul className="pagination">
                  <Pagination className=""
                    totalRecords={totalRecords}
                    pageLimit={pageLimit}
                    pageRangeDisplayed={1}
                    onChangePage={setCurrentPage}
                  />
                </ul>
              </>
            }
          </div>
        </div>
      </div>

      {/* ---------------------View Popup--------------------------- */}
      <Modal size="wrapper modal-dialog-centered modal-lg" show={claimUserView} onHide={handleClaimViewClose}>
        <Modal.Header>
          <Modal.Title>
            <h5 className="modal-title" id="exampleModalLabel">View Profile</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loadingOne ? <Loading /> :
            <form>
              <div className="row">
                <div className="col-lg-4 mb-3">
                  <label htmlFor="id" className="col-form-label">ID</label>
                  <input type="text" className="form-control" id="date" value={claimViewData.user_id} disabled />
                </div>
                <div className="col-lg-4 mb-3">
                  <label htmlFor="name" className="col-form-label">Name</label>
                  <input type="text" className="form-control" id="name" value={claimViewData.name} disabled />
                </div>
                <div className="col-lg-4 mb-3">
                  <label htmlFor="user-name" className="col-form-label">User Name</label>
                  <input type="text" className="form-control" id="user-name" value={claimViewData.user_name} disabled />
                </div>
                <div className="col-lg-4 mb-3">
                  <label htmlFor="email" className="col-form-label">Email</label>
                  <input type="text" className="form-control" id="email" value={claimViewData.email} disabled />
                </div>
                <div className="col-lg-4 mb-3">
                  <label htmlFor="phone-number" className="col-form-label">Phone Number</label>
                  <input type="text" className="form-control" id="phone-number" value={claimViewData.phone_no} disabled />
                </div>
                <div className="col-lg-4 mb-3">
                  <label htmlFor="dob" className="col-form-label">Date of Birth</label>
                  <input type="date" className="form-control" id="dob" value={claimViewData.dob} disabled />
                </div>
                <div className="col-lg-4 mb-3">
                  <label htmlFor="gender" className="col-form-label">Gender</label>
                  <input type="text" className="form-control" id="gender" value={claimViewData.gender} disabled />
                </div>
                <div className="col-lg-4 mb-3">
                  <label htmlFor="portal" className="col-form-label">Portal</label>
                  <input type="text" className="form-control" id="portal" value={claimViewData.language} disabled />
                </div>
              </div>
            </form>
          }
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn form-submit-secbtn" onClick={handleClaimViewClose}>Close</button>
        </Modal.Footer>
      </Modal>

      {/* ---------------------Edit Popup--------------------------- */}
      {popupFlag == false ?
        <>
          <Modal size="wrapper modal-dialog-centered modal-lg" show={editShow} onHide={handleEditClose}>
            <Modal.Header>
              <Modal.Title>
                <h5 className="modal-title" id="exampleModalLabel">Edit Status</h5>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {loadingOne ? <Loading /> :

                <form>
                  <div className="row">
                    <div className="col-lg-4 mb-3">
                      <label htmlFor="id" className="col-form-label">Reported By</label>
                      <input type="text" className="form-control" id="date" value={reportedProfileData.reported_name} disabled />
                    </div>
                    <div className="col-lg-4 mb-3">
                      <label htmlFor="id" className="col-form-label">Reported Profile</label>
                      <input type="text" className="form-control" id="date" value={reportedProfileData.profile_name} disabled />
                    </div>
                    <div className="col-lg-4 mb-3">
                      <label htmlFor="id" className="col-form-label">Reason</label>
                      <input type="text" className="form-control" id="date" value={reportedProfileData.reason_name} disabled />
                    </div>
                    <div className="col-lg-4 mb-3">
                      <label htmlFor="id" className="col-form-label">Message</label>
                      <input type="text" className="form-control" id="date" value={reportedProfileData.message || 'ZZ'} disabled />
                    </div>
                    <div className="col-lg-4 mb-3">
                      <label htmlFor="id" className="col-form-label">+ Reported</label>
                      <input type="text" className="form-control" id="date" value={reportedProfileData.reported_count || ''} disabled />
                    </div>

                    <div className="col-lg-4 mb-3">
                      <label htmlFor="id" className="col-form-label">+ Blocked</label>
                      <input type="text" className="form-control" id="date" value={reportedProfileData.blocked_count || ''} disabled />
                    </div>
                    <div className="col-lg-4 mb-3">
                      <label htmlFor="id" className="col-form-label">Punished</label>
                      <input type="text" className="form-control" id="date" value={reportedProfileData.punished || ''} disabled />
                    </div>

                    <div className="col-lg-4 mb-3">
                      <label htmlFor="id" className="col-form-label">Apeal</label>
                      <input type="text" className="form-control" id="date" value={reportedProfileData.apeal || ''} disabled />
                    </div>

                    <div className="col-lg-4 mb-3">
                      <label htmlFor="id" className="col-form-label">Status</label>
                      <select className="form-select" defaultValue={reportedProfileData.status} onChange={updateProfileData}>{
                        statusList?.map(({ value, label }) => {
                          return <option key={value} value={value}>{label}</option>
                        })
                      }
                      </select>
                    </div>
                  </div>
                </form>
              }
            </Modal.Body>
            <Modal.Footer>
              <button type="button" className="btn form-submit-secbtn" onClick={handleEditClose} >Close</button>
              <button type="button" className="btn form-submit-btn" onClick={handleEditSubmit}>Submit</button>
            </Modal.Footer>
          </Modal>

        </>
        :
        <>
        </>
      }


      <Modal size="wrapper modal-dialog-centered modal-md" show={showConfirmation}>
         <Modal.Header>
           <Modal.Title>
           </Modal.Title>
         </Modal.Header>
         <Modal.Body>
         <div className="alert_box">
           <div className="icon">
             <i className="fa-solid fa-check"></i>
           </div>
           <header>{editSuccMsg}</header>
         </div>
         </Modal.Body>
         <Modal.Footer>
         <button type="button" className="btn form-submit-secbtn" onClick={handleEditClose} >Close</button>
          </Modal.Footer>
       </Modal>

    </div>

  );
}

export default ReportedProfile;