import React, { useState, useEffect } from 'react';
import API from '../../Services/BaseService';
import Header from "../Header/Header";
import SideBar from "../SideBar/SideBar";
import Pagination from 'reactjs-hooks-pagination';
import Loading from "../../Component/Loading/Loading";
import { useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import ls from 'local-storage';


const pageLimit = 10;
const Subscription = () => {
  const userData = useSelector(state => state.LoginReducer.payload);
  const [subScriptionInfo, setSubScriptionInfo] = useState("");
  const [subScription, setSubScription] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [loadingOne, setLoadingOne] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [add, setAdd] = useState(false);
  const [edit, setEdit] = useState(false);
  const [subName, setSubName] = useState("");
  const [addSuccFlg, setAddSuccFlg] = useState(true);
  const [addSuccMsg, setAddSuccMsg] = useState("");
  const [editSuccFlg, setEditSuccFlg] = useState(true);
  const [editSuccMsg, setEditSuccMsg] = useState("");

  const [publish, setPublish] = useState("");
  const [enjoy, setEnjoy] = useState("");
  // const [upload, setUpload] = useState("");
  const [uninterrupted, setUninterrupted] = useState("");

  const recallfunction = (data) => {
    setIsOpen(data);
  }
  useEffect(() => {
    getSubScriptionDetails();
  }, []);
  const getSubScriptionDetails = () => {
    let request = {
      admin_id: userData.admin_id,
      token: userData.token,
      language_id: ls.get("languageId") == "all" || ls.get("languageId") == "" ? "all" : ls.get("languageId").language_id
    }
    API.post("subscriptionList/condition", request).then(response => {
      // console.log("SubScriptionInfo res", response);
      // setCurrentPage(1)
      setSubScriptionInfo(response.data.data);
      setTotalRecords(response.data.data.length);
      setLoading(false);
    });
  }

  // --------add popup---------
  const handleAddClose = () => {
    setAddNameError("");
    // setAddTagLineError("");
    setAddRecordUptoError("");
    setAddPriceError("");
    setAddDescriptionError("");
    setAddName("");
    // setAddTagLine("");
    setAddRecordUpto("");
    setAddPrice("");
    setAddDescription("");
    setAddPublishaudioAnonymously("no");
    setAddEnjoyBackgroundAudioPlay("no");
    // setAddUploadAudioFileFromLibrary("no");
    setAddUninterruptedAudioOrPopAds("no");
    setAdd(false);
    setAddSuccFlg(true);
  }

  const handleAddShow = () => {
    setAdd(true);
  }

  const [addName, setAddName] = useState("");
  // const [addTagLine, setAddTagLine] = useState("");
  const [addRecordUpto, setAddRecordUpto] = useState("");
  const [addPrice, setAddPrice] = useState("");
  const [addDescription, setAddDescription] = useState("");
  const [addPublishAudioAnonymously, setAddPublishaudioAnonymously] = useState("no");
  const [addEnjoyBackgroundAudioAlay, setAddEnjoyBackgroundAudioPlay] = useState("no")
  // const [addUploadAudioFileFromLibrary, setAddUploadAudioFileFromLibrary] = useState("no");
  const [addUninterruptedAudioOrPopAds, setAddUninterruptedAudioOrPopAds] = useState("no");
  const [addNameError, setAddNameError] = useState("");
  // const [addTagLineError, setAddTagLineError] = useState("");
  const [addRecordUptoError, setAddRecordUptoError] = useState("");
  const [addPriceError, setAddPriceError] = useState("");
  const [addDescriptionError, setAddDescriptionError] = useState("");

  const addPrise = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setAddPrice(value);
  };

  const getAddSubscription = () => {
    setAddNameError("");
    // setAddTagLineError("");
    setAddRecordUptoError("");
    setAddPriceError("");
    setAddDescriptionError("");

    if (!addName) {
      setAddNameError("Name is required");
      return;
    }
    if (!addRecordUpto) {
      setAddRecordUptoError("Record Upto is required");
      return;
    }
    if (!addPrice) {
      setAddPriceError("Price is required");
      return;
    }
    if (!addDescription) {
      setAddDescriptionError("Description is required");
      return;

    }

    let request = {
      admin_id: userData.admin_id,
      token: userData.token,
      subs_name: addName.replace(/^\s+|\s+$/gm, ''),
      // tag_line: addTagLine.replace(/^\s+|\s+$/gm, ''),
      record_upto: addRecordUpto,
      price: addPrice,
      description: addDescription,
      publish_audio_anonymously: addPublishAudioAnonymously === "yes" ? "yes" : "no",
      enjoy_background_audio_play: addEnjoyBackgroundAudioAlay === "yes" ? "yes" : "no",
      // upload_audio_file_from_libraray: addUploadAudioFileFromLibrary === "yes" ? "yes" : "no",
      uninterrupted_audio_or_popads: addUninterruptedAudioOrPopAds === "yes" ? "yes" : "no",
      createdBy: userData.admin_id,
      updatedBy: userData.admin_id
    }
    API.post("subscriptionAdd/add", request).then(response => {
      if (response.data.success) {
        getSubScriptionDetails();
        setAddSuccFlg(false);
        setAddSuccMsg(response.data.data.message);
      }
      else {
        setAddNameError("Name Already Exists");

      }
    });
  }
  // --------view ---------
  const getSubscriptionEditDetails = (data) => {
    let request = {
      admin_id: userData.admin_id,
      token: userData.token,
      subscription_id: data.subscription_id
    };
    API.post("subscriptionView/condition", request).then(response => {
      setLoading(false);
      // console.log('dashboard res', response);
      setSubScription(response.data.data[0]);
      setPublish(response.data.data[0].publish_audio_anonymously);
      setEnjoy(response.data.data[0].enjoy_background_audio_play);
      // setUpload(response.data.data[0].upload_audio_file_from_libraray);
      setUninterrupted(response.data.data[0].uninterrupted_audio_or_popads);
      setName(response.data.data[0].subs_name);
      // setTagLine(response.data.data[0].tag_line);
      setRecordUpto(response.data.data[0].record_upto);
      setPrice(response.data.data[0].price);
      setDescription(response.data.data[0].description);
      setLoadingOne(false);

    });
  };

  // --------edit popup---------
  const handleEditClose = () => {
    setEditNameError("");
    // setEditTagLineError("");
    setEditRecordUptoError("");
    seteditPriceError("");
    setEditDescriptionError("");
    setEdit(false);
    setEditSuccFlg(true);
  }

  const handleEditShow = (data) => {
    setEdit(true);
    setSubScription(data);
    getSubscriptionEditDetails(data);
    setLoadingOne(true);
  }
  const [name, setName] = useState("");
  // const [tagLine, setTagLine] = useState("");
  const [recordUpto, setRecordUpto] = useState("");
  const [price, setPrice] = useState("");
  const [description, setDescription] = useState("");
  const [editNameError, setEditNameError] = useState("");
  // const [editTagLineError, setEditTagLineError] = useState("");
  const [editRecordUptoError, setEditRecordUptoError] = useState("");
  const [editPriceError, seteditPriceError] = useState("");
  const [editDescriptionError, setEditDescriptionError] = useState("");

  const editPrise = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setPrice(value);
  };

  const getEditSubscription = () => {
    setEditNameError("");
    // setEditTagLineError("");
    setEditRecordUptoError("");
    seteditPriceError("");
    setEditDescriptionError("");

    if (!name) {
      setEditNameError("Name is required");
      return;
    }
    if (!recordUpto) {
      setEditRecordUptoError("Record Upto is required");
      return;
    }
    if (!price) {
      seteditPriceError("Price is required");
      return;
    }
    if (!description) {
      setEditDescriptionError("Description is required");
      return;
    }

    let request = {
      admin_id: userData.admin_id,
      token: userData.token,
      subscription_id: subScription.subscription_id,
      subs_name: name.replace(/^\s+|\s+$/gm, ''),
      // tag_line: tagLine.replace(/^\s+|\s+$/gm, ''),
      record_upto: recordUpto,
      price: price,
      description: description,
      publish_audio_anonymously: publish === "yes" ? "yes" : "no",
      enjoy_background_audio_play: enjoy === "yes" ? "yes" : "no",
      // upload_audio_file_from_libraray: upload === "yes" ? "yes" : "no",
      uninterrupted_audio_or_popads: uninterrupted === "yes" ? "yes" : "no",
      active: 1,
      createdBy: userData.admin_id,
      updatedBy: userData.admin_id
    }
    API.post("subscriptionEdit/update", request).then(response => {
      if (response.data.success) {
        getSubScriptionDetails();
        setEditSuccFlg(false);
        setEditSuccMsg(response.data.data.message);

      }
    });
  }

  // --------search---------
  const onSearch = (e) => {
    // console.log("====on search ====", e.target.value)
    setSubName(e.target.value);
    setLoading(true);
  }

  const subscriptionSearch = () => {
    let request = {
      admin_id: userData.admin_id,
      token: userData.token,
      data: subName
    }

    API.post("subscriptionSearch/condition", request).then(response => {
      setCurrentPage(1)
      setSubScriptionInfo(response.data.data);
      setTotalRecords(response.data.data.length);
      setLoading(false);
    });
  }
  useEffect(() => {
    if (subName != "") {
      const timer = setTimeout(() => {
        subscriptionSearch();
      }, 200);
      return () => {
        clearTimeout(timer);
      };
    } else {
      getSubScriptionDetails();
    }
  }, [subName]);
  // }, [subName]);

  const onKeydownAdminSearch = (event) => {
    if (event.key === "Enter") {
      subscriptionSearch();

    }
  };
  // --------Delete---------
  const SubscriptionDelete = () => {

    let request = {
      admin_id: userData.admin_id,
      token: userData.token,
      subscription_id: subScription.subscription_id
    }

    API.post("subscriptionDelete/update", request).then(response => {
      if (response.data.success) {
        setSubScription(response.data.data[0]);
        getSubScriptionDetails();
        setEditSuccFlg(false);
        setEditSuccMsg(response.data.data.message);
      }
    });
  }

  // ===========================text to  number============================




  return (
    <div className="wrapper">
      <SideBar sideBarOpen={isOpen} />
      <div id="content" className={!isOpen ? "" : 'active'}>
        <Header recallfunction={recallfunction} />
        <div className="main-content">
          {/* <!-- table start--> */}
          <div className="row">
            <div className="common-heading-sec col-lg-12">
              <h5 className="me-auto">Subscription</h5>
              <div className="search-bar">
                <input type="search" className="form-control" placeholder="Search by Name" onKeyDown={onKeydownAdminSearch} onChange={onSearch} />
              </div>
              <div className="add-btn" onClick={handleAddShow}><i
                className="fa-solid fa-plus me-2"></i>Add New Subscription Plan</div>
            </div>

            {loading ? <Loading /> :
              <>
                <div className="rounded table-responsive">
                  <table className="table bg-white">
                    <thead>
                      <tr>
                        <th>Subscription Name</th>
                        <th>Record</th>
                        <th>Price</th>
                        <th>Discription</th>
                        <th>Created Date</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {subScriptionInfo.length > 0 ? subScriptionInfo.sort((a, b) => b.subscription_id - a.subscription_id).slice(currentPage == 1 ? 0 : (currentPage - 1) * pageLimit, (currentPage == 1 ? currentPage * pageLimit : currentPage * pageLimit))
                        .map((subScriptionInfo, index) =>
                          <tr key={index}>
                            <td>{subScriptionInfo.subs_name}</td>
                            <td>{subScriptionInfo.record_upto}</td>
                            <td>{subScriptionInfo.price}</td>
                            <td>{subScriptionInfo.description}</td>
                            <td>{subScriptionInfo.createdAt.substring(0, 10)}</td>
                            <td><a href="#" data-bs-toggle="modal" data-bs-target="#Edit"
                              className="edit" onClick={() => handleEditShow(subScriptionInfo)}>Edit</a></td>
                          </tr>
                        ) : <tr><td colSpan="8" className='text-center'><p className="nodata-found">No Data Found</p> </td></tr>}
                    </tbody>
                  </table>
                </div>
                <ul className="pagination">
                  <Pagination className=""
                    totalRecords={totalRecords}
                    pageLimit={pageLimit}
                    pageRangeDisplayed={1}
                    onChangePage={setCurrentPage}
                  />
                </ul>
              </>
            }
          </div>
        </div>
      </div>
      {/* ---------------------------Add Popup------------------------------ */}
      <Modal size={addSuccFlg ? "wrapper modal-dialog-centered modal-lg" : "wrapper modal-dialog-centered modal-md"} show={add} onHide={handleAddClose}>
        {addSuccFlg ? <Modal.Header>
          <Modal.Title>
            <h5 className="modal-title" id="exampleModalLabel">Add Subscription</h5>
          </Modal.Title>
        </Modal.Header> : ""}
        <Modal.Body>
          {addSuccFlg ?
            <form>
              <div className="row">
                <div className="col-lg-4 mb-3 add-language">
                  <label htmlFor="name" className="col-form-label">Name <span>*</span></label>
                  <input type="text" className="form-control" id="name" onChange={(e) => setAddName(e.target.value)} />
                  <p className="form-input-error">{addNameError}</p>
                </div>
                {/* <div className="col-lg-4 mb-3">
                <label htmlFor="TagLine" className="col-form-label">Tag Line</label>
                <input type="text" className="form-control" id="TagLine" onChange={(e) => setAddTagLine(e.target.value)} />
                <p className="form-input-error">{addTagLineError}</p>
              </div> */}
                <div className="col-lg-4 mb-3 add-language">
                  <label htmlFor="Recordupto" className="col-form-label">Record upto <span>*</span></label>
                  <select className="form-select" id="Recordupto" onChange={(e) => setAddRecordUpto(e.target.value)}
                  >
                    <option style={{ display: "none" }}>Select record</option>
                    <option value="1 Hour">1 Hour</option>
                    <option value="2 Hour">2 Hour</option>
                    <option value="3 Hour">3 Hour</option>
                    <option value="4 Hour">4 Hour</option>
                    <option value="5 Hour">5 Hour</option>
                    <option value="6 Hour">6 Hour</option>
                    <option value="7 Hour">7 Hour</option>
                    <option value="8 Hour">8 Hour</option>
                    <option value="9 Hour">9 Hour</option>
                    <option value="10 Hour">10 Hour</option>
                  </select>
                  <p className="form-input-error">{addRecordUptoError}</p>
                </div>
                <div className="col-lg-4 mb-3 add-language">
                  <label htmlFor="Price" className="col-form-label">Price <span>*</span></label>
                  <input type="text" className="form-control" id="Price" value={addPrice} onChange={addPrise} />
                  <p className="form-input-error">{addPriceError}</p>
                </div>
                <div className="col-lg-8 mb-3 add-language">
                  <label htmlFor="Description1" className="col-form-label">Description <span>*</span></label>
                  <textarea className="form-control" id="Description1" rows="2" onChange={(e) => setAddDescription(e.target.value)}></textarea>
                  <p className="form-input-error">{addDescriptionError}</p>
                </div>
                <div className="col-lg-12">
                  <table className="table custom-table">
                    <tbody>
                      <tr>
                        <td>
                          <div className="form-check ">
                            <input className="form-check-input" type="checkbox" id="Publishaudioanonymously" value={addPublishAudioAnonymously === "no" ? "yes" : "no"} onChange={(e) => setAddPublishaudioAnonymously(e.target.value)} />
                            <label className="form-check-label" htmlFor="Publishaudioanonymously">
                              Publish audio anonymously
                            </label>
                          </div>
                        </td>
                        <td>
                          <div className="form-check ">
                            <input className="form-check-input" type="checkbox" id="Enjoybackgroundaudioplay" value={addEnjoyBackgroundAudioAlay === "no" ? "yes" : "no"} onChange={(e) => setAddEnjoyBackgroundAudioPlay(e.target.value)} />
                            <label className="form-check-label" htmlFor="Enjoybackgroundaudioplay">
                              Enjoy background audio play
                            </label>
                          </div>
                        </td>
                        {/* <td>
                        <div className="form-check ">
                          <input className="form-check-input" type="checkbox" id="Uploadaudiofilefromlibrary" value={addUploadAudioFileFromLibrary === "no" ? "yes" : "no"} onChange={(e) => setAddUploadAudioFileFromLibrary(e.target.value)} />
                          <label className="form-check-label" htmlFor="Uploadaudiofilefromlibrary">
                            Upload audio file from library
                          </label>
                        </div>
                      </td> */}
                      </tr>
                      <tr>
                        <td>
                          <div className="form-check">
                            <input className="form-check-input" type="checkbox" id="Uninterruptedaudioorpopads" value={addUninterruptedAudioOrPopAds === "no" ? "yes" : "no"} onChange={(e) => setAddUninterruptedAudioOrPopAds(e.target.value)} />
                            <label className="form-check-label" htmlFor="Uninterruptedaudioorpopads">
                              Uninterrupted audio or pop ads
                            </label>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </form>
            :
            <div className="alert_box">
              <div className="icon">
                <i className="fa-solid fa-check"></i>
              </div>
              <header>{addSuccMsg}</header>
            </div>
          }
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn form-submit-secbtn" data-bs-dismiss="modal" onClick={handleAddClose}>Close</button>
          {addSuccFlg ? <button type="button" className="btn form-submit-btn" onClick={() => getAddSubscription()}>Submit</button> : ""}
        </Modal.Footer>
      </Modal>
      {/* ---------------------------Edit popup----------------------------- */}
      <Modal size={editSuccFlg ? "wrapper modal-dialog-centered modal-lg" : "wrapper modal-dialog-centered modal-md"} show={edit} onHide={handleEditClose}>
        {editSuccFlg ? <Modal.Header>
          <Modal.Title>
            <h5 className="modal-title" id="exampleModalLabel">Edit Subscription</h5>
          </Modal.Title>
        </Modal.Header> : ""}
        <Modal.Body>
          {editSuccFlg ?
            <>
              {loadingOne ? <Loading /> :
                <form>
                  <div className="row">
                    <div className="col-lg-4 mb-3 add-language">
                      <label htmlFor="name" className="col-form-label">Name <span>*</span></label>
                      <input type="text" className="form-control" id="name" defaultValue={subScription?.subs_name} onChange={(e) => setName(e.target.value)} />
                      <p className="form-input-error">{editNameError}</p>
                    </div>
                    {/* <div className="col-lg-4 mb-3">
                <label htmlFor="TagLine" className="col-form-label">Tag Line</label>
                <input type="text" className="form-control" id="TagLine" defaultValue={subScription?.tag_line} onChange={(e) => setTagLine(e.target.value)} />
                <p className="form-input-error">{editTagLineError}</p>
              </div> */}
                    <div className="col-lg-4 mb-3 add-language">
                      <label htmlFor="Recordupto" className="col-form-label">Record upto <span>*</span></label>
                      <select className="form-select" id="Recordupto" defaultValue={subScription?.record_upto} onChange={(e) => setRecordUpto(e.target.value)}>
                        <option style={{ display: "none" }}>Select record</option>
                        <option value="1 Hour">1 Hour</option>
                        <option value="2 Hour">2 Hour</option>
                        <option value="3 Hour">3 Hour</option>
                        <option value="4 Hour">4 Hour</option>
                        <option value="5 Hour">5 Hour</option>
                        <option value="6 Hour">6 Hour</option>
                        <option value="7 Hour">7 Hour</option>
                        <option value="8 Hour">8 Hour</option>
                        <option value="9 Hour">9 Hour</option>
                        <option value="10 Hour">10 Hour</option>
                      </select>
                      <p className="form-input-error">{editRecordUptoError}</p>
                    </div>
                    <div className="col-lg-4 mb-3 add-language">
                      <label htmlFor="Price" className="col-form-label">Price <span>*</span></label>
                      <input type="text" className="form-control" id="Price" Value={price} onChange={editPrise} />
                      <p className="form-input-error">{editPriceError}</p>
                    </div>
                    <div className="col-lg-8 mb-3 add-language">
                      <label htmlFor="Description1" className="col-form-label">Description <span>*</span></label>
                      <textarea className="form-control" id="Description1" rows="2" defaultValue={subScription?.description} onChange={(e) => setDescription(e.target.value)}></textarea>
                      <p className="form-input-error">{editDescriptionError}</p>
                    </div>
                    <div className="col-lg-12">
                      <table className="table custom-table">
                        <tbody>
                          <tr>
                            <td>
                              <div className="form-check ">
                                <input className="form-check-input" type="checkbox" id="Publishaudioanonymously1" value={publish === "no" ? "yes" : "no"} checked={publish == "yes" ? true : false}
                                  onChange={(e) => setPublish(e.target.value)} />
                                <label className="form-check-label" htmlFor="Publishaudioanonymously1">
                                  Publish audio anonymously
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className="form-check ">
                                <input className="form-check-input" type="checkbox" id="Enjoybackgroundaudioplay1" defaultValue={enjoy === "no" ? "yes" : "no"} checked={enjoy == "yes" ? true : false}
                                  onChange={(e) => setEnjoy(e.target.value)} />
                                <label className="form-check-label" htmlFor="Enjoybackgroundaudioplay1">
                                  Enjoy background audio play
                                </label>
                              </div>
                            </td>
                            {/* <td>
                        <div className="form-check ">
                          <input className="form-check-input" type="checkbox" id=" Uploadaudiofilefromlibrary1" defaultValue={upload === "no" ? "yes" : "no"} checked={upload == "yes" ? true : false}
                            onChange={(e) => setUpload(e.target.value)} />
                          <label className="form-check-label" htmlFor="Uploadaudiofilefromlibrary1">
                            Upload audio file from library
                          </label>
                        </div>
                      </td> */}
                          </tr>
                          <tr>
                            <td>
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="Uninterruptedaudioorpopads1" defaultValue={uninterrupted === "no" ? "yes" : "no"} checked={uninterrupted == "yes" ? true : false}
                                  onChange={(e) => setUninterrupted(e.target.value)} />
                                <label className="form-check-label" htmlFor="Uninterruptedaudioorpopads1">
                                  Uninterrupted audio or pop ads
                                </label>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </form>
              }
            </>
            :
            <div className="alert_box">
              <div className="icon">
                <i className="fa-solid fa-check"></i>
              </div>
              <header>{editSuccMsg}</header>
            </div>
          }
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn form-submit-secbtn" data-bs-dismiss="modal" onClick={handleEditClose}>Close</button>
          {editSuccFlg ?
            <>
              <button type="button" className="btn form-delete-btn" onClick={SubscriptionDelete}>Delete</button>
              <button type="button" className="btn form-submit-btn" onClick={getEditSubscription}>Submit</button>
            </>
            : ""}
        </Modal.Footer>
      </Modal>
    </div>

  );
}
export default Subscription;