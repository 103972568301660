import React, { useState, useEffect } from 'react';
import API from '../../Services/BaseService';
import { useSelector } from 'react-redux';
import Header from '../Header/Header';
import SideBar from '../SideBar/SideBar';
import Pagination from 'reactjs-hooks-pagination';
import Loading from '../../Component/Loading/Loading';
import { Modal } from 'react-bootstrap';

const pageLimit = 10;
const Language = () => {
    const userData = useSelector(state => state.LoginReducer.payload);
    // console.log("userData", userData);
    const [languageInfo, setLanguageInfo] = useState('');
    const [languageEditInfo, setLanguageEditInfo] = useState('');
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [addShow, setAddShow] = useState(false);
    const [editShow, setEditShow] = useState(false);
    const [createdDate, setCreatedDate] = useState("");
    const [editLanguageError, setEditLanguageError] = useState("");
    const [language, setLanguage] = useState("");
    const [addSuccFlg, setAddSuccFlg] = useState(true);
    const [addSuccMsg, setAddSuccMsg] = useState("");
    const [editSuccFlg, setEditSuccFlg] = useState(true);
    const [editSuccMsg, setEditSuccMsg] = useState("");
    const [languageError, setLanguageError] = useState("");
    const [langStatus, setLangStatus] = useState("");
    const [editLanguage, setEditLanguage] = useState("");

    // ----------View-----------
    const [showView, setShowView] = useState(false);
    const [languageView, setLanguageView] = useState("");
    const [viewCreatedDate, setViewCreatedDate] = useState("");
    const [languageViewStatus, setLanguageViewStatus] = useState("no");

    const [isOpen, setIsOpen] = useState(false);
    const recallfunction = (data) => {
        setIsOpen(data);
    };

    useEffect(() => {
        getLanguageDetails();
        // getLanguageEditDetails();
    }, []);
    const getLanguageDetails = () => {
        let request = {
            admin_id: userData.admin_id,
            token: userData.token,

        };
        API.post('langaugeList/condition', request).then(response => {
            // console.log('language res', response);
            // setCurrentPage(1)
            setLanguageInfo(response.data.data);
            setTotalRecords(response.data.data.length);
            setLoading(false);

        });
    };

    // ----------Add popup show-----------
    const handleAddClose = () => {
        setAddShow(false);
        setAddSuccFlg(true);
        setLanguageError("");
        setLanguage("");
    }
    const handleAddShow = () => {
        // setLoading(true);
        setAddShow(true);
    }
    const handleAddSubmit = () => {
        setLanguageError("");


        if (!language) {
            setLanguageError("Language is required")
            return;
        }

        let request = {
            admin_id: userData.admin_id,
            token: userData.token,
            language: language.replace(/^\s+|\s+$/gm, ''),
            active: 1
        };

        API.post("languageAdd/add", request).then((response) => {
            if (response.data.success) {
                getLanguageDetails();
                setAddSuccFlg(false);
                setAddSuccMsg(response.data.data.message);
                // console.log("language response=>", response)
            }
            else {
                setLanguageError("Language Already Exists")
            }

        });
    }

    // ----------View-----------
    const handleViewClose = () => {
        setShowView(false);
    }
    const handleViewShow = (data) => {
        setShowView(true);
        setLanguageView(data);
        setViewCreatedDate(data.createdAt.substring(0, 10));
        setLanguageViewStatus(data.active == 1 ? "yes" : "no");
    }

    // ----------Edit popup show-----------
    const handleEditClose = () => {
        setEditShow(false);
        setEditLanguageError("");
        setEditSuccFlg(true);
    }
    const handleEditShow = (data) => {
        setLanguageEditInfo(data);
        setEditLanguage(data.language);
        setLangStatus(data.active == 1 ? "yes" : "no");
        setCreatedDate(data.createdAt.substring(0, 10));
        setEditShow(true);
    }
    const handleEditSubmit = () => {
        setEditLanguageError("");
        if (!editLanguage) {
            setEditLanguageError("Language is required");
            return;
        }

        let request = {
            admin_id: userData.admin_id,
            token: userData.token,
            language_id: languageEditInfo.language_id,
            language: editLanguage.replace(/^\s+|\s+$/gm, ''),
            active: langStatus === "yes" ? 1 : 0
        };

        API.post("languageEdit/update", request).then((response) => {
            if (response.data.success) {
                getLanguageDetails();
                setEditSuccFlg(false);
                setEditSuccMsg(response.data.data.message);
                // console.log("status response=>", response)
            }
            else {
                setEditLanguageError("Language Already Exists")
            }

        });
    }

    const handleOnchange = (e) => {
        languageDropdown(e.target.value);
        setLoading(true);
    }

    const languageDropdown = (data) => {

        let request = {
            admin_id: userData.admin_id,
            token: userData.token,
            status: data
        }

        API.post("languagesFilter/condition", request).then(response => {
            // console.log("language List response", response.data.data)
            setCurrentPage(1)
            setLanguageInfo(response.data.data);
            setTotalRecords(response.data.data.length);
            setLoading(false);
        });
    }


    return (
        <div className="wrapper">
            <SideBar sideBarOpen={isOpen} />
            <div id="content" className={!isOpen ? '' : 'active'}>
                <Header recallfunction={recallfunction} />
                <div className="main-content">
                    <div className="row">
                        <div className="common-heading-sec col-lg-12">
                            <h5 className="me-auto">Languages</h5>
                            <div className="dropdown-sec me-2">
                                <select className="form-select" onChange={handleOnchange}>
                                    <option style={{ display: "none" }}>Select Status</option>
                                    <option value="">All</option>
                                    <option value="active">Active</option>
                                    <option value="inActive">InActive</option>
                                </select>
                            </div>
                            <div className="add-btn" onClick={() => handleAddShow()} ><i className="fa-solid fa-plus me-2"></i>Add Languages</div>
                        </div>
                        {loading ? <Loading /> :
                            <>
                                <div className="rounded table-responsive">
                                    <table className="table bg-white">
                                        <thead>
                                            <tr>
                                                <th>#Id</th>
                                                <th>Created Date</th>
                                                <th>Languages</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {languageInfo.length > 0 ? languageInfo.sort((a, b) => b.language_id - a.language_id).slice(currentPage == 1 ? 0 : (currentPage - 1) * pageLimit, (currentPage == 1 ? currentPage * pageLimit : currentPage * pageLimit))
                                                .map((languageInfo, index) =>
                                                    <tr key={index} >
                                                        <td> {languageInfo.language_id}</td>
                                                        <td>{languageInfo.createdAt.substring(0, 10)}</td>
                                                        <td> <a onClick={() => handleViewShow(languageInfo)}>{languageInfo.language}</a></td>
                                                        {languageInfo.active === 1 ?
                                                            <td><span className="active-color">Active</span></td> :
                                                            <td><span className="inactive-color">Inactive</span></td>
                                                        }
                                                        <td><a className="edit" onClick={() => handleEditShow(languageInfo)}>Edit</a></td>
                                                    </tr>
                                                ) : <tr><td colSpan="20" className='text-center'><p className="nodata-found">No Data Found</p> </td></tr>}
                                        </tbody>
                                    </table>
                                </div>
                                <ul className="pagination">
                                    <Pagination className=""
                                        totalRecords={totalRecords}
                                        pageLimit={pageLimit}
                                        pageRangeDisplayed={1}
                                        onChangePage={setCurrentPage}
                                    />
                                </ul>
                            </>
                        }


                    </div>
                </div>
            </div>
            {/* ----------------------------------Add popup------------------------------------- */}
            <Modal size="wrapper modal-dialog-centered modal-md" show={addShow} onHide={handleAddClose}>
                {addSuccFlg ? <Modal.Header>
                    <Modal.Title>
                        <h5 className="modal-title" id="exampleModalLabel">Add Language</h5>
                    </Modal.Title>
                </Modal.Header> : ""}
                <Modal.Body>
                    {addSuccFlg ?
                        <>
                            {loading ? <Loading /> :
                                <form>
                                    <div className="row">

                                        <div className="col-lg-12 mb-3 add-language">
                                            <label htmlFor="portal" className="col-form-label">Language <span>*</span></label>
                                            <input type="text" className="form-control" id="portal" onChange={(e) => setLanguage(e.target.value)} />
                                            <p className="form-input-error">{languageError}</p>
                                        </div>
                                    </div>
                                </form>
                            }
                        </>
                        :
                        <div className="alert_box">
                            <div className="icon">
                                <i className="fa-solid fa-check"></i>
                            </div>
                            <header>{addSuccMsg}</header>
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn form-submit-secbtn" onClick={handleAddClose} >Close</button>
                    {addSuccFlg ? <button type="button" className="btn form-submit-btn" onClick={handleAddSubmit}>Submit</button> : ""}
                </Modal.Footer>
            </Modal>

            {/* --------------------------------Edit popup----------------------------------- */}
            <Modal size="wrapper modal-dialog-centered modal-md" show={editShow} onHide={handleEditClose}>
                {editSuccFlg ? <Modal.Header>
                    <Modal.Title>
                        <h5 className="modal-title" id="exampleModalLabel">Edit Language</h5>
                    </Modal.Title>
                </Modal.Header> : ""}
                <Modal.Body>
                    {editSuccFlg ?
                        <form>
                            <div className="row">
                                <div className="col-lg-6 mb-3 ">
                                    <label htmlFor="id" className="col-form-label">ID</label>
                                    <input type="text" className="form-control" value={languageEditInfo.language_id} id="id" aria-label="Disabled input example" disabled />
                                </div>
                                <div className="col-lg-6 mb-3">
                                    <label htmlFor="created-date" className="col-form-label">Created Date</label>
                                    <input type="date" className="form-control" value={createdDate}
                                        id="created-date" disabled />
                                </div>
                                <div className="col-lg-6 mb-3 add-language">
                                    <label htmlFor="portal" className="col-form-label">Language <span>*</span></label>
                                    <input type="text" className="form-control" id="portal" defaultValue={languageEditInfo.language} onChange={(e) => setEditLanguage(e.target.value)} />
                                    <p className="form-input-error">{editLanguageError}</p>
                                </div>
                                <div className="col-lg-4 mb-3">
                                    <label htmlFor="email" className="col-form-label">Status</label>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input me-2" type="checkbox" id="flexSwitchCheckChecked" value={langStatus === "no" ? "yes" : "no"} checked={langStatus === "yes" ? true : false} onChange={(e) => setLangStatus(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                        </form>
                        :
                        <div className="alert_box">
                            <div className="icon">
                                <i className="fa-solid fa-check"></i>
                            </div>
                            <header>{editSuccMsg}</header>
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn form-submit-secbtn" data-bs-dismiss="modal" onClick={handleEditClose}>Close</button>
                    {editSuccFlg ? <button type="button" className="btn form-submit-btn" onClick={handleEditSubmit}>Submit</button> : ""}
                </Modal.Footer>
            </Modal>

            {/* ------------------View popup-------------------- */}

            <Modal size="wrapper modal-dialog-centered modal-md" show={showView} onHide={handleViewClose}>
                <Modal.Header>
                    <Modal.Title>
                        <h5 className="modal-title" id="exampleModalLabel">View Profile</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loading ? <Loading /> :
                        <form>
                            <div className="row">
                                <div className="col-lg-6 mb-3">
                                    <label htmlFor="id" className="col-form-label">ID</label>
                                    <input type="text" className="form-control" value={languageView?.language_id} id="id" disabled />
                                </div>
                                <div className="col-lg-6 mb-3">
                                    <label htmlFor="created-date" className="col-form-label">Created Date</label>
                                    <input type="date" className="form-control" value={viewCreatedDate}
                                        id="created-date" disabled />
                                </div>
                                <div className="col-lg-6 mb-3">
                                    <label htmlFor="portal" className="col-form-label">Language</label>
                                    <input type="text" className="form-control" id="portal" defaultValue={languageView?.language} disabled />
                                </div>
                                <div className="col-lg-4 mb-3">
                                    <label htmlFor="email" className="col-form-label">Status</label>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input me-2" type="checkbox" id="flexSwitchCheckChecked" value={languageViewStatus === "no" ? "yes" : "no"} checked={languageViewStatus === "yes" ? true : false}
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn form-submit-secbtn" onClick={handleViewClose} >Close</button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};
export default Language;