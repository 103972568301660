import React, { useState } from 'react';
import Header from '../Header/Header';
import SideBar from '../SideBar/SideBar';

const Payment = () => {
    const [isOpen, setIsOpen] = useState(false);
    const recallfunction = (data) => {
        setIsOpen(data);
    };
    return (
        <div className="wrapper">
            <SideBar sideBarOpen={isOpen} />
            <div id="content" className={!isOpen ? '' : 'active'}>
                <Header recallfunction={recallfunction} />
                <div className="main-content">


                    <div className="row">

                        <div className="common-heading-sec col-lg-12">
                            <h5 className="me-auto">Payment</h5>
                            <div className="search-bar">
                                <input type="search" className="form-control" placeholder="Search by name" />
                            </div>

                            <div className="dropdown-sec me-2">
                                <select className="form-select" aria-label="Default select example">
                                    <option selected>Select Type</option>
                                    <option value="1">Premium</option>
                                    <option value="2">Supriem</option>
                                </select>
                            </div>
                            <div className="dropdown-sec">
                                <select className="form-select" aria-label="Default select example">
                                    <option selected>Select Status</option>
                                    <option value="1">Success</option>
                                    <option value="2">Failed</option>
                                </select>
                            </div>
                            {/* <!-- <div className="add-btn" data-bs-toggle="modal" data-bs-toggle="modal" data-bs-target="#addnewcustomer"><i className="fa-solid fa-plus me-2"></i>Add New Users </div> --> */}
                        </div>


                        <div className="rounded table-responsive">
                            <table className="table bg-white">
                                <thead>
                                    <tr>
                                        <th>Username</th>
                                        <th>Subscription Type</th>
                                        <th>Payment Referece</th>
                                        <th>Date & Time</th>
                                        <th>Transaction Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><a href="#" data-bs-toggle="modal"
                                            data-bs-target="#usernamemodel">@marisraw123</a></td>
                                        <td>Premium</td>
                                        <td>555517621447</td>
                                        <td>06/05/2022 & 11:45AM</td>
                                        <td><span className="premium-color">Success</span></td>
                                    </tr>
                                    <tr>
                                        <td><a href="#" data-bs-toggle="modal"
                                            data-bs-target="#usernamemodel">@marisraw123</a></td>
                                        <td>Premium</td>
                                        <td>555517621447</td>
                                        <td>06/05/2022 & 11:45AM</td>
                                        <td><span className="none-color">Failed</span></td>

                                    </tr>
                                    <tr>
                                        <td><a href="#" data-bs-toggle="modal"
                                            data-bs-target="#usernamemodel">@marisraw123</a></td>
                                        <td>Supriem</td>
                                        <td>555517621447</td>
                                        <td>06/05/2022 & 11:45AM</td>
                                        <td><span className="none-color">Failed</span></td>

                                    </tr>
                                    <tr>
                                        <td><a href="#" data-bs-toggle="modal"
                                            data-bs-target="#usernamemodel">@marisraw123</a></td>
                                        <td>Premium</td>
                                        <td>555517621447</td>
                                        <td>06/05/2022 & 11:45AM</td>
                                        <td><span className="premium-color">Success</span></td>

                                    </tr>
                                    <tr>
                                        <td><a href="#" data-bs-toggle="modal"
                                            data-bs-target="#usernamemodel">@marisraw123</a></td>
                                        <td>Supriem</td>
                                        <td>555517621447</td>
                                        <td>06/05/2022 & 11:45AM</td>
                                        <td><span className="premium-color">Success</span></td>

                                    </tr>
                                    <tr>
                                        <td><a href="#" data-bs-toggle="modal"
                                            data-bs-target="#usernamemodel">@marisraw123</a></td>
                                        <td>Supriem</td>
                                        <td>555517621447</td>
                                        <td>06/05/2022 & 11:45AM</td>
                                        <td><span className="none-color">Failed</span></td>

                                    </tr>
                                    <tr>
                                        <td><a href="#" data-bs-toggle="modal"
                                            data-bs-target="#usernamemodel">@marisraw123</a></td>
                                        <td>Premium</td>
                                        <td>555517621447</td>
                                        <td>06/05/2022 & 11:45AM</td>
                                        <td><span className="premium-color">Success</span></td>

                                    </tr>
                                    <tr>
                                        <td><a href="#" data-bs-toggle="modal"
                                            data-bs-target="#usernamemodel">@marisraw123</a></td>
                                        <td>Premium</td>
                                        <td>555517621447</td>
                                        <td>06/05/2022 & 11:45AM</td>
                                        <td><span className="none-color">Failed</span></td>

                                    </tr>
                                    <tr>
                                        <td><a href="#" data-bs-toggle="modal"
                                            data-bs-target="#usernamemodel">@marisraw123</a></td>
                                        <td>Premium</td>
                                        <td>555517621447</td>
                                        <td>06/05/2022 & 11:45AM</td>
                                        <td><span className="premium-color">Success</span></td>

                                    </tr>
                                    <tr>
                                        <td><a href="#" data-bs-toggle="modal"
                                            data-bs-target="#usernamemodel">@marisraw123</a></td>
                                        <td>Premium</td>
                                        <td>555517621447</td>
                                        <td>06/05/2022 & 11:45AM</td>
                                        <td><span className="premium-color">Success</span></td>

                                    </tr>

                                </tbody>
                            </table>
                        </div>

                        <ul className="pagination">
                            <li className="page-item disabled">
                                <a className="page-link" href="#" tabIndex="-1" aria-disabled="true"><i className="fas fa-chevron-left"></i></a>
                            </li>
                            <li className="page-item active"><a className="page-link" href="#">1</a></li>
                            <li className="page-item" aria-current="page">
                                <a className="page-link" href="#">2</a>
                            </li>
                            <li className="page-item"><a className="page-link" href="#">3</a></li>
                            <li className="page-item"><a className="page-link" href="#">4</a></li>
                            <li className="page-item"><a className="page-link" href="#">5</a></li>
                            <li className="page-item"><a className="page-link" href="#">6</a></li>
                            <li className="page-item">
                                <a className="page-link" href="#"><i className="fas fa-chevron-right"></i></a>
                            </li>
                        </ul>

                    </div>
                </div>
            </div>
            <div className="modal fade" id="usernamemodel" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1"
                aria-labelledby="usernamemodelLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg ">
                    <div className="modal-content">
                        <div className="modal-header text-center">
                            <h5 className="modal-title" id="exampleModalLabel">View Profile</h5>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="row">
                                    <div className="col-lg-4 mb-2">
                                        <label htmlFor="id" className="col-form-label">ID</label>
                                        <input type="text" className="form-control" placeholder="ID" id="date" aria-label="Disabled input example"
                                            disabled />
                                    </div>
                                    <div className="col-lg-4 mb-2">
                                        <label htmlFor="created-date" className="col-form-label">Created Date</label>
                                        <input type="date" className="form-control" id="date" aria-label="Disabled input example" disabled />
                                    </div>
                                    <div className="col-lg-4 mb-2">
                                        <label htmlFor="phone-number" className="col-form-label">Phone Number</label>
                                        <input type="number" className="form-control" placeholder="9094422785" id="phone-number"
                                            aria-label="Disabled input example" disabled />
                                    </div>
                                    <div className="col-lg-4 mb-2">
                                        <label htmlFor="name" className="col-form-label">Name</label>
                                        <input type="text" className="form-control" placeholder="Marisraw" id="name"
                                            aria-label="Disabled input example" disabled />
                                    </div>
                                    <div className="col-lg-4 mb-2">
                                        <label htmlFor="user-name" className="col-form-label">User Name</label>
                                        <input type="text" className="form-control" placeholder="@marisraw123" id="user-name" disabled />
                                    </div>
                                    <div className="col-lg-4 mb-2">
                                        <label htmlFor="dob" className="col-form-label">Date of Birth</label>
                                        <input type="date" className="form-control" placeholder="03/04/1990" id="dob"
                                            aria-label="Disabled input example" disabled />
                                    </div>
                                    <div className="col-lg-4 mb-2">
                                        <label htmlFor="gender" className="col-form-label">Gender</label>
                                        <select className="form-select" id="Gender" aria-label="Default select example"
                                            disabled>
                                            <option selected>Male</option>
                                            <option value="male">Male</option>
                                            <option value="female">Female</option>
                                        </select>
                                    </div>
                                    <div className="col-lg-4 mb-2">
                                        <label htmlFor="email" className="col-form-label">Email</label>
                                        <input type="text" className="form-control" placeholder="Someone@example" id="email"
                                            aria-label="Disabled input example" disabled />
                                    </div>
                                    <div className="col-lg-4 mb-2">
                                        <label htmlFor="portal" className="col-form-label">Portal</label>
                                        <select className="form-select" id="portal" aria-label="Default select example"
                                            disabled>
                                            <option selected>Tamil</option>
                                            <option value="male">Tamil</option>
                                            <option value="female">English</option>
                                        </select>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn form-submit-secbtn" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    );
};
export default Payment;