import { BrowserRouter, Routes, Route, } from "react-router-dom";
import React from 'react'; 
import Login from '../Container/Login/Login';
import DashBoard from '../Container/DashBoard/DashBoard';
import Language from '../Container/Language/Language';
import Folkususers from '../Container/FolkusUsers/FolkusUsers';
import Stories from '../Container/Stories/Stories';
import Admin from '../Container/Admin/Admin';
import Pushnotification from '../Container/PushNotification/PushNotification';
import Categories from '../Container/Categories/Categories';
import Subscription from '../Container/Subscription/Subscription';
import Usersubscription from '../Container/UserSubscription/UserSubscription';
import Payment from '../Container/Payment/Payment';
import Usernameclaimrequest from '../Container/UserNameClaimRequest/UserNameClaimRequest';
import Deactivatedreason from '../Container/DeactivatedReason/DeactivatedReason';
import Deactivatedaccounts from '../Container/DeactivatedAccounts/DeactivatedAccounts';
import Feedback from '../Container/FeedBack/FeedBack';
import Suggesteduser from '../Container/SuggestedUser/SuggestedUser';
import ReportedProfile from "../Container/ReportedModule/ReportedProfile";
import ReportedComment from "../Container/ReportedModule/ReportedComment";

function AppRouter() {

    return (
        <div>
            <BrowserRouter>
                <Routes>
                    <Route exact path="/" element={<Login/>}  />

                    <Route path="/dashboard" element={<DashBoard/>} />
                    <Route path="/language" element={<Language/>} />
                    <Route path="/folkususers" element={<Folkususers/>} />
                    <Route path="/stories" element={<Stories/>} />
                    <Route path="/admin" element={<Admin/>} />
                    <Route path="/pushnotification" element={<Pushnotification/>} />
                    <Route path="/categories" element={<Categories/>} />
                    <Route path="/subscription" element={<Subscription/>} />
                    <Route path="/usersubscription" element={<Usersubscription/>} />
                    <Route path="/payment" element={<Payment/>} />
                    <Route path="/usernameclaimrequest" element={<Usernameclaimrequest/>} />
                    <Route path="/deactivatedreason" element={<Deactivatedreason/>} />
                    <Route path="/deactivatedaccounts" element={<Deactivatedaccounts/>} />
                    <Route path="/reportedprofile" element={<ReportedProfile/>} />
                    <Route path="/reportedcomment" element={<ReportedComment/>} />
                    <Route path="/reportedpost" element={<Deactivatedaccounts/>} />
                    <Route path="/feedback" element={<Feedback/>} />
                    <Route path="/suggesteduser" element={<Suggesteduser/>} />
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default AppRouter;