import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import API from '../../Services/BaseService';
import Header from '../Header/Header';
import SideBar from '../SideBar/SideBar';
import Pagination from 'reactjs-hooks-pagination';
import ls from 'local-storage';
import Loading from "../../Component/Loading/Loading";
import { Modal } from 'react-bootstrap';

const pageLimit = 10;
const SuggestedUser = () => {
  const userData = useSelector(state => state.LoginReducer.payload);
  const [isOpen, setIsOpen] = useState(false);
  const recallfunction = (data) => {
    setIsOpen(data);
  };
  const [suggestedUserInfo, setSuggestedUserInfo] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState();
  const [loading, setLoading] = useState(true);
  const [editSuccFlg, setEditSuccFlg] = useState(false);
  const [loadingOne, setLoadingOne] = useState(true);
  // console.log("editSuccFlg", editSuccFlg);
  const [editSuccMsg, setEditSuccMsg] = useState("");
  // ==================================search and filter========================

  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState("");

  // ============================view================================

  const [userView, setUserView] = useState("");
  const [viewProfile, setViewProfile] = useState(false);
  const [createdDate, setCreatedDate] = useState("");

  // ============================List================================

  useEffect(() => {
    getSuggestedUserDetails();
  }, []);
  const getSuggestedUserDetails = () => {
    let request = {
      admin_id: userData.admin_id,
      token: userData.token,
      language_id: ls.get("languageId") == "all" || ls.get("languageId") == "" ? "all" : ls.get("languageId").language_id

    }
    API.post("suggestedUserList/condition", request).then(response => {
      // console.log("FlokusUser res", response);
      // setCurrentPage(1)
      setSuggestedUserInfo(response.data.data);
      setTotalRecords(response.data.data.length);
      // setEditSuccFlg(true);
      setLoading(false);
    });
  }

  // ====================suggestedUserAdd===========================

  const handleTouggleClose = () => {
    setEditSuccFlg(false);
  }

  const suggestedUserAdd = (data, value) => {
    const status = document.getElementById(value)
    let request = {
      admin_id: userData.admin_id,
      token: userData.token,
      user_id: data.user_id,
      active: status.checked === true ? 1 : 0,
      createdBy: userData.admin_id,
      updatedBy: userData.admin_id
    }

    API.post("suggestedUserAdd/add", request).then(response => {
      // console.log("==========>", response)
      if (response.data.success) {
        setEditSuccMsg(response.data.data.message);
        getSuggestedUserDetails();
        setEditSuccFlg(true)
      }
    });
  }

  // ==================================search and filter========================

  const onSearch = (e) => {
    setSearch(e.target.value);
    setLoading(true);
  }

  const sugSearch = () => {
    let request = {
      admin_id: userData.admin_id,
      token: userData.token,
      data: search,
      status: filter,
      language_id: ls.get("languageId") == "all" || ls.get("languageId") == "" ? "all" : ls.get("languageId").language_id
    }

    API.post("suggestedUserFilter/condition", request).then(response => {
      // console.log("==========>", response)
      setCurrentPage(1)
      setSuggestedUserInfo(response.data.data);
      setTotalRecords(response.data.data.length);
      setLoading(false);
    });
  }
  useEffect(() => {
    if (search != "") {
      const timer = setTimeout(() => {
        sugSearch();
      }, 200);
      return () => {
        clearTimeout(timer);
      };
    } else {
      sugDropdown(filter);
    }
  }, [search]);

  const onKeydownAccountSearch = (event) => {
    if (event.key === "Enter") {
      sugSearch();
    }
  };

  const onDropdown = (e) => {
    setFilter(e.target.value);
    sugDropdown(e.target.value);
    setLoading(true);
  }

  const sugDropdown = (data) => {

    let request = {
      admin_id: userData.admin_id,
      token: userData.token,
      data: search,
      status: data,
      language_id: ls.get("languageId") == "all" || ls.get("languageId") == "" ? "all" : ls.get("languageId").language_id
    }

    API.post("suggestedUserFilter/condition", request).then(response => {
      // console.log("==========>", response)
      // setCurrentPage(1)
      setSuggestedUserInfo(response.data.data);
      setTotalRecords(response.data.data.length);
      setLoading(false);
    });
  }

  // ============================view================================

  const handleViewProfileClose = () => {
    setViewProfile(false);
  }
  const handleViewProfileShow = (data) => {
    setViewProfile(true);
    getDashBoardEditDetails(data);
    setCreatedDate(data.createdAt.substring(0, 10));
    setLoadingOne(true);
  }


  const getDashBoardEditDetails = (data) => {
    let request = {
      admin_id: userData.admin_id,
      token: userData.token,
      user_id: data.user_id

    };
    API.post("userbyId/condition", request).then(response => {
      // console.log('dashboard res', response);
      setUserView(response.data.data[0])
      setLoadingOne(false);
    });
  };

  return (
    <div className="wrapper">
      <SideBar sideBarOpen={isOpen} />
      <div id="content" className={!isOpen ? '' : 'active'}>
        <Header recallfunction={recallfunction} />
        <div className="main-content" >
          <div className="row">
            <div className="common-heading-sec col-lg-12">
              <h5 className="me-auto">Suggested User</h5>
              <div className="search-bar">
                <input type="search" className="form-control" placeholder="Search by Name or Id" onKeyDown={onKeydownAccountSearch} onChange={onSearch} />
              </div>
              <div className="dropdown-sec me-2">
                <select className="form-select" aria-label="Default select example" onChange={onDropdown}>
                  <option style={{ display: "none" }}>Select Status</option>
                  <option value="">All</option>
                  <option value="suggested">Suggested</option>
                  <option value="not_suggested">Not Suggested</option>
                </select>
              </div>
              {/* <div className="dropdown-sec me-2">
                <select className="form-select" aria-label="Default select example">
                  <option selected>Select Type</option>
                  <option value="1">Public</option>
                  <option value="2">Public limited</option>
                  <option value="3">Anonymous</option>
                </select>
              </div> */}
            </div>
            {/* {editSuccFlg ? */}
            <>
              {loading ? <Loading /> :
                <>
                  <div className="rounded table-responsive ">
                    <table className="table bg-white pointer">
                      <thead>
                        <tr>
                          <th>UserName</th>
                          <th>Storys</th>
                          <th>Play</th>
                          <th>Likes</th>
                          <th>Followers</th>
                          <th>Phone No</th>
                          <th>Email Id</th>
                          <th>Portal</th>
                          <th>Suggested User</th>
                        </tr>
                      </thead>
                      <tbody>
                        {suggestedUserInfo.length > 0 ? suggestedUserInfo.slice(currentPage == 1 ? 0 : (currentPage - 1) * pageLimit, (currentPage == 1 ? currentPage * pageLimit : currentPage * pageLimit))
                          .map((suggestedUserInfo, index) =>
                            <tr key={index}>
                              <td><a onClick={() => handleViewProfileShow(suggestedUserInfo)}>{suggestedUserInfo.user_name}</a></td>
                              <td>{suggestedUserInfo.total_story}</td>
                              <td>{suggestedUserInfo.total_play}</td>
                              <td>{suggestedUserInfo.total_like}</td>
                              <td>{suggestedUserInfo.total_follow}</td>
                              <td>{suggestedUserInfo.phone_no}</td>
                              <td>{suggestedUserInfo.email}</td>
                              <td>{suggestedUserInfo.language}</td>
                              <td><div className="form-check form-switch">

                                <input className="form-check-input" type="checkbox" value={suggestedUserInfo.status === 0 ? 1 : 0} checked={suggestedUserInfo.status === 1 ? true : false} id={`suggestedUserAdd${index}`} onChange={() => { suggestedUserAdd(suggestedUserInfo, `suggestedUserAdd${index}`) }} />
                              </div></td>

                            </tr>
                          ) : <tr><td colSpan="20" className='text-center'><p className="nodata-found">No Data Found</p> </td></tr>}
                      </tbody>

                    </table>
                  </div>

                  <ul className="pagination">
                    <Pagination className=""
                      totalRecords={totalRecords}
                      pageLimit={pageLimit}
                      pageRangeDisplayed={1}
                      onChangePage={setCurrentPage}
                    />
                  </ul>
                </>
              }
            </>


          </div>
        </div>
      </div>


      {editSuccFlg == true ?

        <div className="alert_box">
          <div className="icon">
            <i className="fa-solid fa-check"></i>
          </div>
          <header>{editSuccMsg}</header>
        </div> : ""
      }
      {/* // ============================view================================ */}

      <Modal size="wrapper modal-dialog-centered modal-lg" show={viewProfile} onHide={handleViewProfileClose}>
        <Modal.Header>
          <Modal.Title>
            <div className="modal-header text-center">
              <h5 className="modal-title" id="exampleModalLabel">View Profile</h5>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loadingOne ? <Loading /> :
            <div className="modal-body">
              <form>
                <div className="row">
                  <div className="col-lg-4 mb-2">
                    <label htmlFor="id" className="col-form-label">ID</label>
                    <input type="text" className="form-control" id="date" value={userView?.user_id}
                      disabled />
                  </div>
                  <div className="col-lg-4 mb-2">
                    <label htmlFor="created-date" className="col-form-label">Created Date</label>
                    <input type="date" className="form-control" id="date" value={createdDate} disabled />
                  </div>
                  <div className="col-lg-4 mb-2">
                    <label htmlFor="phone-number" className="col-form-label">Phone Number</label>
                    <input type="text" className="form-control" id="phone-number" value={userView?.phone_no}
                      disabled />
                  </div>
                  <div className="col-lg-4 mb-2">
                    <label htmlFor="name" className="col-form-label">Name</label>
                    <input type="text" className="form-control" id="name" value={userView?.name}
                      disabled />
                  </div>
                  <div className="col-lg-4 mb-2">
                    <label htmlFor="user-name" className="col-form-label">User Name</label>
                    <input type="text" className="form-control" id="user-name" value={userView?.user_name} disabled />
                  </div>
                  <div className="col-lg-4 mb-2">
                    <label htmlFor="dob" className="col-form-label">Date of Birth</label>
                    <input type="date" className="form-control" id="dob" value={userView?.dob}
                      disabled />
                  </div>
                  <div className="col-lg-4 mb-2">
                    <label htmlFor="gender" className="col-form-label">Gender</label>
                    <select className="form-select" id="Gender" value={userView?.gender}
                      disabled>
                      <option selected>Male</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>
                  </div>
                  <div className="col-lg-4 mb-2">
                    <label htmlFor="email" className="col-form-label">Email</label>
                    <input type="text" className="form-control" id="email"
                      value={userView?.email} disabled />
                  </div>
                  <div className="col-lg-4 mb-2">
                    <label htmlFor="portal" className="col-form-label">Portal</label>
                    <select className="form-select" id="portal" value={userView?.portal}
                      disabled>
                      <option selected>Tamil</option>
                      <option value="male">Tamil</option>
                      <option value="female">English</option>
                    </select>
                  </div>
                </div>
              </form>
            </div>
          }
        </Modal.Body>
        <Modal.Footer>
          <div className="modal-footer">
            <button type="button" className="btn form-submit-secbtn" data-bs-dismiss="modal" onClick={handleViewProfileClose}>Close</button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* ------------------Edit popup-------------------- */}
      <Modal size="wrapper modal-dialog-centered modal-md" show={editSuccFlg}>
        <Modal.Header>
          <Modal.Title>
            <h5 className="modal-title" id="exampleModalLabel">Suggested User</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="alert_box">
            <div className="icon">
              <i className="fa-solid fa-check"></i>
            </div>
            <header>{editSuccMsg}</header>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn form-submit-secbtn" onClick={handleTouggleClose} >Close</button>
        </Modal.Footer>
      </Modal>

    </div>
  );
};
export default SuggestedUser;