import ls from 'local-storage';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import 'react-multiple-select-dropdown-lite/dist/index.css';
import { useSelector } from 'react-redux';
import Pagination from 'reactjs-hooks-pagination';
import adduser from '../../assets/img/adduser.jpg';
import Loading from "../../Component/Loading/Loading";
import API from '../../Services/BaseService';
import Header from "../Header/Header";
import SideBar from "../SideBar/SideBar";

const pageLimit = 10;
const Stories = () => {
  const userData = useSelector(state => state.LoginReducer.payload);
  const [notifiInfo, setNotifiInfo] = useState("");
  const [notifyError, setNotifyError] = useState("");

  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [loadingOne, setLoadingOne] = useState(true);

  // ====================pushNotification===========================
  const [notificationShow, setNotificationShow] = useState(false);
  const [flagOpen, setFlagOpen] = useState(false);
  const [notfyStoryId, setNotfyStoryId] = useState("");

  const toggleOpen = () => {
    setFlagOpen(!flagOpen);
  }

  const [isOpen, setIsOpen] = useState(false);
  const recallfunction = (data) => {
    setIsOpen(data);
  }
  useEffect(() => {
    getNotifiDetails();
  }, []);
  const getNotifiDetails = () => {
    let request = {
      admin_id: userData.admin_id,
      token: userData.token,
      language_id: ls.get("languageId") == "all" || ls.get("languageId") == "" ? "all" : ls.get("languageId").language_id
    }
    API.post("pushNotificationList/condition", request).then(response => {
      // console.log("Notifi res", response);
      setNotifiInfo(response.data.data);
      // setNotification(response.data.data.notification);
      setTotalRecords(response.data.data.length);
      setLoading(false);
      setCurrentPage(1)
    });
  }

  //=====================search and filter==========================
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState("");


  const onSearch = (e) => {
    setSearch(e.target.value);
    setLoading(true);
  }

  const pushSearch = () => {
    let request = {
      admin_id: userData.admin_id,
      token: userData.token,
      data: search,
      status: filter,
      language_id: ls.get("languageId") == "all" || ls.get("languageId") == "" ? "all" : ls.get("languageId").language_id
    }

    API.post("pushTypeFilter/condition", request).then(response => {
      // console.log("==========>", response)
      setCurrentPage(1)
      setNotifiInfo(response.data.data);
      // setNotification(response.data.data.notification);
      setTotalRecords(response.data.data.length);
      setLoading(false);
    });
  }
  useEffect(() => {
    if (search != "") {
      const timer = setTimeout(() => {
        pushSearch();
      }, 200);
      return () => {
        clearTimeout(timer);
      };
    } else {
      pushDropdown(filter);
    }
  }, [search]);

  const onKeydownAccountSearch = (event) => {
    if (event.key === "Enter") {
      pushSearch();
    }
  };

  const onDropdown = (e) => {
    setFilter(e.target.value);
    pushDropdown(e.target.value);
    setLoading(true);
  }

  const pushDropdown = (data) => {

    let request = {
      admin_id: userData.admin_id,
      token: userData.token,
      data: search,
      status: data,
      language_id: ls.get("languageId") == "all" || ls.get("languageId") == "" ? "all" : ls.get("languageId").language_id
    }

    API.post("pushTypeFilter/condition", request).then(response => {
      // console.log("==========>", response)
      setCurrentPage(1)
      setNotifiInfo(response.data.data);
      // setNotification(response.data.data.notification);
      setTotalRecords(response.data.data.length);
      setLoading(false);
    });
  }


  // ----------Play popup show-----------
  const [editShow, setEditShow] = useState(false);
  // const [viewStory, setViewStory] = useState("");
  const [songs, setSongs] = useState("");
  const [images, setImages] = useState([]);
  const [captions, setCaptions] = useState([]);
  const [count, setCount] = useState(0);
  const [playFlag, setPlayFlag] = useState(false);

  useEffect(() => {
    if (playFlag == true) {
      const timerId = setInterval(() => {
        setCount((count) => count + 1);
      }, 30000);

      return () => clearInterval(timerId);
    }


  }, [playFlag]);

  const image = images[count % images.length];
  const caption = captions[count % captions.length];

  const handleEditClose = () => {
    setEditShow(false);
    setSeekValue(0);
    setShowPlayPause(false);
    audioPlayer.current = ""
    setCurrentTime(0);
    setCurrentRunMintTime(0);
    setCurrentRunSecTime(0);
  }
  const handleEditShow = (data) => {
    setEditShow(true);
    getViewStory(data);
    setLoadingOne(true);
    setSeekValue(0);
    setShowPlayPause(false);
    audioPlayer.current = ""
    setCurrentTime(0);
    setCurrentRunMintTime(0);
    setCurrentRunSecTime(0);
  }

  const getViewStory = (data) => {

    let request = {
      admin_id: userData.admin_id,
      token: userData.token,
      story_id: data.story_id
    }

    API.post("storybyId/condition", request).then(response => {
      setSongs(response.data.data[0].stories);
      // setViewStory(response.data.data[0]);
      setImages(response.data.data[0]?.listcaption.map((data) => data.image));
      setCaptions(response.data.data[0]?.listcaption.map((data) => data.captions));
      setLoadingOne(false);
    });
  }

  const audioPlayer = React.useRef();
  const [currentTime, setCurrentTime] = useState(0);
  const [currentRunMintTime, setCurrentRunMintTime] = useState(0);
  const [seekValue, setSeekValue] = useState(0);
  const [showPlayPause, setShowPlayPause] = useState(false);
  const [currentRunSecTime, setCurrentRunSecTime] = useState(0);

  const fwrd = () => {
    const seekto = audioPlayer.current.currentTime + 10;
    audioPlayer.current.currentTime = seekto;
  };
  const bwrd = () => {
    const seekto = audioPlayer.current.currentTime - 10;
    audioPlayer.current.currentTime = seekto;
  };
  const play = () => {
    audioPlayer.current.play();
    setPlayFlag(true);
  };

  const pause = () => {
    audioPlayer.current.pause();
    setPlayFlag(false);
  };

  const onPlaying = () => {
    setCurrentTime(audioPlayer.current.duration);
    const runTime = audioPlayer.current.currentTime;
    const mints = Math.floor(runTime % 3600 / 60);
    const sec = Math.floor(runTime % 3600 % 60);
    setCurrentRunMintTime(mints);
    setCurrentRunSecTime(sec);
    setSeekValue(
      (audioPlayer.current.currentTime / audioPlayer.current.duration) * 100 || 0
    );
  };

  const testingmints = Math.floor(currentTime % 3600 / 60);
  const testingSec = Math.floor(currentTime % 3600 % 60);

  // console.log("testingmints", testingmints);
  // console.log("testingmints currentTime", currentTime);
  // console.log("testingmints testingSec", testingSec);

  // ====================pushNotification===========================


  const pushNotification = () => {
    setNotifyError('')
    let request = {
      admin_id: userData.admin_id,
      token: userData.token,
      story_id: notfyStoryId,
      createdBy: userData.admin_id
    }

    API.post("notificationAdd/add", request).then(response => {
      // console.log("==========>", response)
      if (response.data.success) {
        setLoading(true);
        getNotifiDetails();
        setNotificationShow(false);
      } else {
        setLoading(true);
        getNotifiDetails();
        const {err,message} = response.data.error;
        setNotifyError(err|| message);
      }
    });
  }

  const handleNotificationClose = () => {
    setNotificationShow(false);
    setNotifyError('');
  }
  const handleNotificationShow = (data) => {
    setNotificationShow(true);
    setNotfyStoryId(data.story_id);
  }
  return (
    <div className="wrapper">
      <SideBar sideBarOpen={isOpen} />
      <div id="content" className={!isOpen ? "" : 'active'}>
        <Header recallfunction={recallfunction} />
        <div className="main-content" onClick={flagOpen === false ? () => { } : toggleOpen}>
          <div className="row">
            <div className="common-heading-sec col-lg-12">
              <h5 className="me-auto">Push Notification</h5>
              <div className="search-bar">
                <input type="search" className="form-control wid-th" placeholder="Search by title & approved by" onKeyDown={onKeydownAccountSearch} onChange={onSearch} />
              </div>
              <div className="dropdown-sec me-2">
                <select className="form-select" aria-label="Default select example" onChange={onDropdown}>
                  <option style={{ display: "none" }}>Select Quality</option>
                  <option value="">All</option>
                  <option value="good">Good</option>
                  <option value="average">Average</option>
                </select>
              </div>
            </div>
            {loading ? <Loading /> :
              <>
                <div className="rounded table-responsive">
                  <table className="table bg-white">
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Content quality</th>
                        <th>Type</th>
                        <th>Main categories</th>
                        <th>Sub categories</th>
                        <th>Play</th>
                        <th>Approved by</th>
                        <th>Push notification</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {notifiInfo.length > 0 ? notifiInfo.sort((a, b) => b.active === 1 ? 1 : -1).slice(currentPage == 1 ? 0 : (currentPage - 1) * pageLimit, (currentPage == 1 ? currentPage * pageLimit : currentPage * pageLimit))
                        .map((notifiInfo, index) =>
                          <tr key={index}>
                            <td className="story-title">{notifiInfo.caption}</td>
                            <td>{notifiInfo.quality}</td>
                            <td>{notifiInfo.type}</td>
                            <td>{notifiInfo.tag}</td>
                            <td>{notifiInfo.sub_tag}</td>
                            <td>{notifiInfo.total_plays}</td>
                            <td>{notifiInfo.approvedBy}</td>
                            <td>
                              <button type="button" onClick={() => { handleNotificationShow(notifiInfo) }} className="btn btn-sm btn-outline-danger position-relative">
                                Notify
                                <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-primary">
                                  {notifiInfo.notify_count}
                                </span>
                              </button>

                            </td>
                            <td onClick={() => handleEditShow(notifiInfo)} ><a className="edit">Play</a></td>
                          </tr>
                        ) : <tr><td colSpan="20" className='text-center'><p className="nodata-found">No Data Found</p> </td></tr>}
                    </tbody>
                  </table>
                </div>
                <ul className="pagination">
                  <Pagination className=""
                    totalRecords={totalRecords}
                    pageLimit={pageLimit}
                    pageRangeDisplayed={1}
                    onChangePage={setCurrentPage}
                  />
                </ul>
              </>
            }

          </div>
        </div>
      </div>

      <Modal size="wrapper modal-dialog-centered modal-md" show={notificationShow} onHide={handleNotificationClose}>
        <Modal.Header>
          <Modal.Title>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="alert_box">
            <div className="icon">
              <i className="fa-solid fa-bell"></i>
            </div>
            <header>Notification Confirmation</header>
            <p>Are you sure you want to send push notifications?</p>
            <p className="form-input-error">{notifyError}</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="modal-footer">
            <button type="button" className="btn form-submit-secbtn" data-bs-dismiss="modal" onClick={handleNotificationClose}>Cancel</button>
            <button type="button" className="btn form-submit-btn" onClick={() => { pushNotification(); }} >Yes</button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* --------------------------------Play popup----------------------------------- */}
      <Modal size="wrapper modal-dialog-centered modal-lg" show={editShow} onHide={handleEditClose}>
        <Modal.Header>
          <Modal.Title>
            <h5 className="modal-title" id="exampleModalLabel">Story</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loadingOne ? <Loading /> :
            <>
              <div className="user-upload-btn-wrapper">
                <img alt="" src={image ? image : adduser} />
              </div>
              <div className="modal-body">
                <p className="caption-center">{caption}</p>
                {/* <audio controls id="audioval" onClick={getAudio}>
                      <source src={storyViewInfo.stories} type="audio/mp3" />
                    </audio> */}

                <div className="story-edit">
                  <audio
                    src={songs}
                    ref={audioPlayer}
                    onTimeUpdate={onPlaying}
                  >
                  </audio>
                  <div className="audio-time">
                    <div className="audio-str-time">
                      {currentRunMintTime}:{currentRunSecTime}
                    </div>
                    <div className="audio-end-time">
                      {testingmints}:{testingSec}
                    </div>
                  </div>
                  <div className="range-slider">
                    <div className="slider-container">
                      <span className="bar"><span className="fill"></span></span>
                      <input
                        className="slider"
                        type="range"
                        min="0"
                        max="100"
                        step="0.5"
                        value={seekValue}
                        onChange={(e) => {
                          const seekto = audioPlayer.current.duration * (+e.target.value / 100);
                          // console.log("seekto==",(+seekValue / 100));
                          audioPlayer.current.currentTime = seekto;
                          setSeekValue(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div >
                    <div className="controlls_btns">
                      <button id="backward_btn" onClick={bwrd} ><i className="fa-solid fa-backward" ></i></button>
                      {showPlayPause === false ?
                        <button id="play_pause_btn" onClick={() => { play(); setShowPlayPause(true) }} ><i className="fa-solid fa-play" ></i></button> :
                        <button id="play_pause_btn" onClick={() => { pause(); setShowPlayPause(false) }} ><i className="fa-solid fa-pause" ></i></button>
                      }
                      <button id="forward_btn" onClick={fwrd} ><i className="fa-solid fa-forward" ></i></button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          }
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn form-submit-secbtn" data-bs-dismiss="modal" onClick={handleEditClose}>Close</button>
        </Modal.Footer>
      </Modal>

      <div className="modal fade" id="notification" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1"
        aria-labelledby="notificationLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-md ">
          <div className="modal-content">
            <div className="modal-body">
              <div className="alert_box">
                <div className="icon">
                  <i className="fa-solid fa-bell"></i>
                </div>
                <header>Notification Confirmation</header>
                <p>Are you sure you want to send push notifications?</p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn form-submit-secbtn" data-bs-dismiss="modal">Cancel</button>
                <button type="button" className="btn form-submit-btn">Yes</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Stories;