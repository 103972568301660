import React, { useState, useEffect } from 'react';
import API from '../../Services/BaseService';
import Header from "../Header/Header";
import SideBar from "../SideBar/SideBar";
import Pagination from 'reactjs-hooks-pagination';
import Loading from "../../Component/Loading/Loading";
import { useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';

const pageLimit = 10;
const Deactivatedreason = () => {
  const userData = useSelector(state => state.LoginReducer.payload);
  const [reasonInfo, setReasonInfo] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [loadingOne, setLoadingOne] = useState(true);
  const [addSuccFlg, setAddSuccFlg] = useState(true);
  const [addSuccMsg, setAddSuccMsg] = useState("");
  const [editSuccFlg, setEditSuccFlg] = useState(true);
  const [editSuccMsg, setEditSuccMsg] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const recallfunction = (data) => {
    setIsOpen(data);
  }
  useEffect(() => {
    getReasonDetails();
    // getReasonViewDetails();
  }, []);

  /* ------------- List---------- */

  const getReasonDetails = () => {
    let request = {
      admin_id: userData.admin_id,
      token: userData.token
    }
    API.post("reasonList/condition", request).then(response => {
      // console.log("Reason res", response);
      // setCurrentPage(1)
      setReasonInfo(response.data.data);
      setTotalRecords(response.data.data.length);
      setLoading(false);
    });
  }

  /* -------------Add ---------- */

  const [reasonAdd, setReasonAdd] = useState("");
  const [reason, setReason] = useState("");

  const [reasonError, setReasonError] = useState("");

  /* -------------View ---------- */

  const [reasonView, setReasonView] = useState("");
  const [createdDate, setCreatedDate] = useState("");
  /* -------------Edit ---------- */

  const [reasonEdit, setReasonEdit] = useState("");
  const [editReason, setEditReason] = useState("");

  const [editReasonError, setEditReasonError] = useState("");

  /* -------------Search ---------- */

  const [subReason, setSubReason] = useState("");


  /* -------------Add popup---------- */

  const reasonAddClose = () => {
    setReasonAdd(false);
    setAddSuccFlg(true);
    setReasonError("");
    setReason("");
  }

  const reasonAddShow = () => {
    setReasonAdd(true);
  }

  const addReasonSubmit = () => {
    setReasonError("");

    if (!reason) {
      setReasonError("Reason is required")
      return;
    }

    let request = {
      admin_id: userData.admin_id,
      token: userData.token,
      reason: reason.replace(/^\s+|\s+$/gm, ''),
      createdBy: userData.admin_id,
      updatedBy: userData.admin_id
    };

    API.post("reasonAdd/add", request).then((response) => {
      if (response.data.success) {
        getReasonDetails();
        setAddSuccFlg(false);
        setAddSuccMsg(response.data.data.message);
      }

    });
  }

  /* -------------View popup---------- */

  const getReasonViewDetails = (data) => {
    let request = {
      admin_id: userData.admin_id,
      token: userData.token,
      reason_id: data.reason_id

    };
    API.post("reasonView/condition", request).then(response => {
      // console.log('language res', response);
      setReasonView(response.data.data[0]);
      setEditReason(response.data.data[0].reason);
      setLoadingOne(false);

    });
  };

  /* -------------Edit popup---------- */
  const reasonEditClose = () => {
    setReasonEdit(false);
    setEditReasonError("");
    setEditSuccFlg(true);
  }

  const reasonEditShow = (data) => {
    setReasonEdit(true);
    getReasonViewDetails(data);
    setCreatedDate(data.createdAt.substring(0, 10));
    setLoadingOne(true);
  }

  const editReasonSubmit = () => {
    setEditReasonError("");

    if (!editReason) {
      setEditReasonError("Reason is required")
      return;
    }

    let request = {
      admin_id: userData.admin_id,
      token: userData.token,
      reason: editReason.replace(/^\s+|\s+$/gm, ''),
      reason_id: reasonView.reason_id,

    };
    API.post("reasonEdit/update", request).then((response) => {
      if (response.data.success) {
        getReasonDetails();
        setEditSuccFlg(false);
        setEditSuccMsg(response.data.data.message);
      }
    });
  }

  /* -------------Delete popup---------- */

  const reasonDelete = () => {

    let request = {
      admin_id: userData.admin_id,
      token: userData.token,
      reason_id: reasonView.reason_id
    }

    API.post("reasonDelete/update", request).then(response => {
      if (response.data.success) {
        setReasonView(response.data.data[0]);
        getReasonDetails();
        setEditSuccFlg(false);
        setEditSuccMsg(response.data.data.message);
      }
    });
  }

  /* -------------Search ---------- */

  const onSearch = (e) => {
    setSubReason(e.target.value);
    setLoading(true);
  }

  const ReasonSearch = () => {
    let request = {
      admin_id: userData.admin_id,
      token: userData.token,
      data: subReason
    }

    API.post("reasonSearch/condition", request).then(response => {
      setCurrentPage(1)
      setReasonInfo(response.data.data);
      setTotalRecords(response.data.data.length);
      setLoading(false);
    });
  }
  useEffect(() => {
    if (subReason != "") {
      const timer = setTimeout(() => {
        ReasonSearch();
      }, );
      return () => {
        clearTimeout(timer);
      };
    } else {
      getReasonDetails();
    }
  }, [subReason]);

  const onKeydownReasonSearch = (event) => {
    if (event.key === "Enter") {
      ReasonSearch();
    }
  };

  return (
    <div className="wrapper">
      <SideBar sideBarOpen={isOpen} />
      <div id="content" className={!isOpen ? "" : 'active'}>
        <Header recallfunction={recallfunction} />
        <div className="main-content">
          <div className="row">
            <div className="common-heading-sec col-lg-12">
              <h5 className="me-auto">deactivated Reason</h5>
              <div className="search-bar">
                <input type="search" className="form-control" placeholder="Search by Reason" onKeyDown={onKeydownReasonSearch} onChange={onSearch} />
              </div>
              <div className="add-btn" data-bs-toggle="modal" data-bs-target="#addnewlanguage" onClick={reasonAddShow}><i
                className="fa-solid fa-plus me-2"></i>Add New Reason</div>
            </div>
            {loading ? <Loading /> :
              <>
                <div className="rounded table-responsive">
                  <table className="table bg-white">
                    <thead>
                      <tr>
                        <th>{"Id#"}</th>
                        <th>Created Date</th>
                        <th>Reasons</th>
                        <th>Action</th>

                      </tr>
                    </thead>
                    <tbody>
                      {reasonInfo.length > 0 ? reasonInfo.sort((a, b) => b.reason_id - a.reason_id).slice(currentPage == 1 ? 0 : (currentPage - 1) * pageLimit, (currentPage == 1 ? currentPage * pageLimit : currentPage * pageLimit))
                        .map((reasonInfo, index) =>
                          <tr key={index}>
                            <td>{reasonInfo.reason_id}</td>
                            <td>{reasonInfo.createdAt.substring(0, 10)}</td>
                            <td>{reasonInfo.reason}</td>
                            <td><a className="edit" onClick={() => reasonEditShow(reasonInfo)}>Edit</a></td>
                          </tr>
                        ) : <tr><td colSpan="8" className='text-center'><p className="nodata-found">No Data Found</p> </td></tr>}
                    </tbody>
                  </table>
                </div>
                <ul className="pagination">
                  <Pagination className=""
                    totalRecords={totalRecords}
                    pageLimit={pageLimit}
                    pageRangeDisplayed={1}
                    onChangePage={setCurrentPage}
                  />
                </ul>
              </>
            }
          </div>
        </div>
      </div>

      {/* -------------Add popup---------- */}
      <Modal size="wrapper modal-dialog-centered modal-md" show={reasonAdd} onHide={reasonAddClose}>
        {addSuccFlg ? <Modal.Header>
          <Modal.Title>
            <h5 className="modal-title" id="exampleModalLabel">Add New Reason</h5>
          </Modal.Title>
        </Modal.Header> : ""}
        <Modal.Body>
          {addSuccFlg ?
            <form>
              <div className="row">
                <div className="col-lg-12 mb-3 add-language">
                  <label htmlFor="portal" className="col-form-label">Reason <span>*</span></label>
                  <textarea type="text" className="form-control" id="portal" rows="2" onChange={(e) => setReason(e.target.value)}></textarea>
                  <p className="form-input-error">{reasonError}</p>
                </div>
              </div>
            </form>
            :
            <div className="alert_box">
              <div className="icon">
                <i className="fa-solid fa-check"></i>
              </div>
              <header>{addSuccMsg}</header>
            </div>
          }
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn form-submit-secbtn" data-bs-dismiss="modal" onClick={reasonAddClose}>Close</button>
          {addSuccFlg ? <button type="button" className="btn form-submit-btn" onClick={addReasonSubmit}>Submit</button> : ""}
        </Modal.Footer>
      </Modal>

      {/* -------------Edit popup---------- */}

      <Modal size="wrapper modal-dialog-centered modal-md" show={reasonEdit} onHide={reasonEditClose}>
        {editSuccFlg ? <Modal.Header>
          <Modal.Title>
            <h5 className="modal-title" id="exampleModalLabel">Edit New Reason</h5>
          </Modal.Title>
        </Modal.Header> : ""}
        <Modal.Body>
          {editSuccFlg ?
            <>
              {loadingOne ? <Loading /> :
                <form>
                  <div className="row">
                    <div className="col-lg-6 mb-2">
                      <label htmlFor="id" className="col-form-label">ID</label>
                      <input type="text" className="form-control" defaultValue={reasonView?.reason_id} id="id" disabled />
                    </div>
                    <div className="col-lg-6 mb-2">
                      <label htmlFor="created-date" className="col-form-label">Created Date</label>
                      <input type="date" className="form-control" value={createdDate}
                        id="created-date" disabled />
                    </div>
                    <div className="col-lg-12 mb-2 add-language">
                      <label htmlFor="portal" className="col-form-label">Reason <span>*</span></label>
                      <textarea type="text" defaultValue={editReason} className="form-control" id="portal" rows="2"
                        onChange={(e) => setEditReason(e.target.value)}></textarea>
                      <p className="form-input-error">{editReasonError}</p>
                    </div>
                  </div>
                </form>
              }
            </>
            :
            <div className="alert_box">
              <div className="icon">
                <i className="fa-solid fa-check"></i>
              </div>
              <header>{editSuccMsg}</header>
            </div>
          }
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn form-submit-secbtn" data-bs-dismiss="modal" onClick={reasonEditClose}>Close</button>
          {editSuccFlg ?
            <>
              <button type="button" className="btn form-delete-btn" onClick={reasonDelete}>Delete</button>
              <button type="button" className="btn form-submit-btn" onClick={editReasonSubmit}>Submit</button>
            </>
            : ""}
        </Modal.Footer>
      </Modal>
    </div>




  );
}
export default Deactivatedreason;