import React, { useState, useEffect } from 'react';
import Header from '../Header/Header';
import SideBar from '../SideBar/SideBar';
import Pagination from 'reactjs-hooks-pagination';
import API from '../../Services/BaseService';
import { useSelector } from 'react-redux';
import Loading from '../../Component/Loading/Loading';
import { Modal } from 'react-bootstrap';

const pageLimit = 10;
const FeedBack = () => {
    const userData = useSelector(state => state.LoginReducer.payload);
    const [feedBackInfo, setFeedBackInfo] = useState("");
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchUserName, setSearchUserName] = useState("");
    const [viewProfile, setViewProfile] = useState(false);
    const [userView, setUserView] = useState("");
    const [createdDate, setCreatedDate] = useState("");
    const [loading, setLoading] = useState(true);
    const [loadingOne, setLoadingOne] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const recallfunction = (data) => {
        setIsOpen(data);
    };

    useEffect(() => {
        getFeedBackDetails();
    }, []);
    const getFeedBackDetails = () => {
        let request = {
            admin_id: userData.admin_id,
            token: userData.token
        }
        API.post("feddbackList/condition", request).then(response => {
            // console.log("FeedBack res", response);
            setCurrentPage(1)
            setFeedBackInfo(response.data.data);
            setTotalRecords(response.data.data.length);
            setLoading(false);
        });
    }

    // -------------------------Search----------------------------

    const onSearch = (e) => {
        setSearchUserName(e.target.value)
        setLoading(true);
    }

    const FeedbackSearch = () => {
        let request = {
            admin_id: userData.admin_id,
            token: userData.token,
            data: searchUserName,
        }

        API.post("feedbackSearch/condition", request).then(response => {
            setCurrentPage(1)
            setFeedBackInfo(response.data.data);
            setTotalRecords(response.data.data.length);
            setLoading(false);
        });
    }
    useEffect(() => {
        if (searchUserName != "") {
            const timer = setTimeout(() => {
                FeedbackSearch();
            }, 200);
            return () => {
                clearTimeout(timer);
            };
        } else {
            getFeedBackDetails();
        }
    }, [searchUserName]);

    const onKeydownFeedbackSearch = (event) => {
        if (event.key === "Enter") {
            FeedbackSearch();
        }
    };

    {/* ---------------------------View Popup----------------------------- */ }

    const handleViewProfileClose = () => {
        setViewProfile(false);
    }
    const handleViewProfileShow = (data) => {
        setViewProfile(true);
        getDashBoardEditDetails(data);
        setCreatedDate(data.createdAt.substring(0, 10));
        setLoadingOne(true);
    }


    const getDashBoardEditDetails = (data) => {
        let request = {
            admin_id: userData.admin_id,
            token: userData.token,
            user_id: data.user_id

        };
        API.post("userbyId/condition", request).then(response => {
            // console.log('dashboard res', response);
            setUserView(response.data.data[0])
            setLoadingOne(false);
        });
    };


    return (
        <div className="wrapper">
            <SideBar sideBarOpen={isOpen} />
            <div id="content" className={!isOpen ? "" : 'active'}>
                <Header recallfunction={recallfunction} />
                <div className="main-content">
                    <div className="row">
                        <div className="common-heading-sec col-lg-12">
                            <h5 className="me-auto">Feedback</h5>
                            <div className="search-bar">
                                <input type="search" className="form-control" placeholder="Search by name" onKeyDown={onKeydownFeedbackSearch} onChange={onSearch} />
                            </div>
                        </div>
                        {loading ? <Loading /> :
                            <>
                                <div className="rounded table-responsive">
                                    <table className="table bg-white">
                                        <thead>
                                            <tr>
                                                <th>Id</th>
                                                <th>Date & Time</th>
                                                <th>Username</th>
                                                <th>Feedback</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {feedBackInfo.length > 0 ? feedBackInfo.sort((a, b) => b.user_id - a.user_id).slice(currentPage == 1 ? 0 : (currentPage - 1) * pageLimit, (currentPage == 1 ? currentPage * pageLimit : currentPage * pageLimit))
                                                .map((FeedBackInfo, index) =>
                                                    <tr key={index}>
                                                        <td>{FeedBackInfo.user_id}</td>
                                                        <td>{FeedBackInfo.createdAt.substring(0, 10)} & {FeedBackInfo.createdAt.substring(12, 19)}</td>
                                                        <td><a onClick={() => handleViewProfileShow(FeedBackInfo)}>{FeedBackInfo.user_name}</a></td>
                                                        <td>{FeedBackInfo.feedback}</td>
                                                    </tr>
                                                ) : <tr><td colSpan="8" className='text-center'><p className="nodata-found">No Data Found</p> </td></tr>}
                                        </tbody>
                                    </table>
                                </div>
                                <ul className="pagination">
                                    <Pagination className=""
                                        totalRecords={totalRecords}
                                        pageLimit={pageLimit}
                                        pageRangeDisplayed={1}
                                        onChangePage={setCurrentPage}
                                    />
                                </ul>
                            </>
                        }

                    </div>
                </div>
            </div>

            {/* ---------------------------View Popup----------------------------- */}

            <Modal size="wrapper modal-dialog-centered modal-lg" show={viewProfile} onHide={handleViewProfileClose}>
                <Modal.Header>
                    <Modal.Title>
                        <div className="modal-header text-center">
                            <h5 className="modal-title" id="exampleModalLabel">View Profile</h5>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loadingOne ? <Loading /> :
                        <div className="modal-body">
                            <form>
                                <div className="row">
                                    <div className="col-lg-4 mb-2">
                                        <label htmlFor="id" className="col-form-label">ID</label>
                                        <input type="text" className="form-control" id="date" value={userView?.user_id}
                                            disabled />
                                    </div>
                                    <div className="col-lg-4 mb-2">
                                        <label htmlFor="created-date" className="col-form-label">Created Date</label>
                                        <input type="date" className="form-control" id="date" value={createdDate} disabled />
                                    </div>
                                    <div className="col-lg-4 mb-2">
                                        <label htmlFor="phone-number" className="col-form-label">Phone Number</label>
                                        <input type="text" className="form-control" id="phone-number" value={userView?.phone_no}
                                            disabled />
                                    </div>
                                    <div className="col-lg-4 mb-2">
                                        <label htmlFor="name" className="col-form-label">Name</label>
                                        <input type="text" className="form-control" id="name" value={userView?.name}
                                            disabled />
                                    </div>
                                    <div className="col-lg-4 mb-2">
                                        <label htmlFor="user-name" className="col-form-label">User Name</label>
                                        <input type="text" className="form-control" id="user-name" value={userView?.user_name} disabled />
                                    </div>
                                    <div className="col-lg-4 mb-2">
                                        <label htmlFor="dob" className="col-form-label">Date of Birth</label>
                                        <input type="date" className="form-control" id="dob" value={userView?.dob}
                                            disabled />
                                    </div>
                                    <div className="col-lg-4 mb-2">
                                        <label htmlFor="gender" className="col-form-label">Gender</label>
                                        <select className="form-select" id="Gender" value={userView?.gender}
                                            disabled>
                                            <option selected>Male</option>
                                            <option value="male">Male</option>
                                            <option value="female">Female</option>
                                        </select>
                                    </div>
                                    <div className="col-lg-4 mb-2">
                                        <label htmlFor="email" className="col-form-label">Email</label>
                                        <input type="text" className="form-control" id="email"
                                            value={userView?.email} disabled />
                                    </div>
                                    <div className="col-lg-4 mb-2">
                                        <label htmlFor="portal" className="col-form-label">Portal</label>
                                        <select className="form-select" id="portal" value={userView?.portal}
                                            disabled>
                                            <option selected>Tamil</option>
                                            <option value="male">Tamil</option>
                                            <option value="female">English</option>
                                        </select>
                                    </div>
                                </div>
                            </form>
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <div className="modal-footer">
                        <button type="button" className="btn form-submit-secbtn" data-bs-dismiss="modal" onClick={handleViewProfileClose}>Close</button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>




    );
};
export default FeedBack;