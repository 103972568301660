import React, { useState, useEffect } from 'react';
import user from '../../assets/img/user.png';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import API from '../../Services/BaseService';
import ls from 'local-storage';

const Header = (props) => {
    const navigate = useNavigate();
    const userData = useSelector(state => state.LoginReducer.payload);
    // console.log("userData",userData);
    // ls.set("languageId", "all");
    const [defaultLangId] = useState(ls.get("languageId") == "all" || ls.get("languageId") == null ? "All" : ls.get("languageId").language);
    // console.log("setDefaultLangId",setDefaultLangId);
    const [langAdminInfo, setLangAdminInfo] = useState("");
    const [isOpen, setIsOpen] = useState(true);
    const toggleOpen = () => {
        setIsOpen(!isOpen);
        props.recallfunction(isOpen);
        // ls.set("languageId", selectedLangDetails);
    };
    useEffect(() => {
        langAdminDetails();
    }, []);
    const langAdminDetails = () => {
        let request = {
            admin_id: userData.admin_id,
            token: userData.token
        }
        API.post("langaugeAdmin/condition", request).then(response => {
            setLangAdminInfo(response.data.data);
        });
    }
    const handleLangId = (e) => {
        // console.log("e.target.value", e.target.value);
        const getLangValue = e.target.value

        if (getLangValue !== "all") {
            const selectedLangDetails = langAdminInfo.filter(data => data.language_id == e.target.value)[0]
            // console.log("languageId", langAdminInfo.filter(data => data.language_id == e.target.value)[0]);
            ls.set("languageId", selectedLangDetails);
            // props.getlanguageId(selectedLangDetails.language_id);
            window.location.reload()
        }
        else {
            ls.set("languageId", getLangValue);
            // props.getlanguageId(getLangValue);
            window.location.reload()
        }
    }
    return (
        <>
            <div className="top-navbar">
                <nav className="navbar navbar-expand-lg">
                    <div className="container-fluid">

                        <button onClick={toggleOpen} type="button" id="sidebarCollapse" className="d-xl-block d-lg-block d-md-block d-none">
                            <span> <i className="fa-solid fa-bars"></i></span>
                        </button>

                        <div className="collapse navbar-collapse d-lg-block d-xl-block d-sm-none d-md-none d-none header-icon"
                            id="navbarSupportedContent ">
                            <ul className="nav navbar-nav ml-auto">
                                <li className="nav-item">
                                    <i className="fa-solid fa-globe"></i>
                                    <select data-placeholder="Choose a Language..." onChange={handleLangId}>
                                        <option style={{ display: "none" }}>{defaultLangId}</option>
                                        {langAdminInfo.length > 0 &&
                                            <>
                                                <option value="all">All</option>
                                                {langAdminInfo.map((data) => <option key={data.language_id} value={data.language_id} >{data.language}</option>)}
                                            </>
                                        }
                                    </select>
                                </li>

                                <li className="nav-item">
                                    <a className="nav-link" >Welcome {userData.admin_name}</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#"><img src={user} /></a>
                                </li>
                                <li>
                                    <a className="mainbutton" onClick={() => { navigate('/'); ls.set("languageId", ""); ls.set("userDetails", ""); }}
                                    >Logout </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        </>
    );
};
export default Header;